import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  base_url,
  cancerReportType,
  parse_date,
  setSessionStorage,
  useSessionStorage,
} from "../../../Services/patients";
import axios from "axios";
import swal from "sweetalert";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  AddCircleOutline,
  ArrowBackIos,
  ArrowForwardIos,
  CheckCircleOutline,
  DownloadOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import { muiStyles } from "../muiStyles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ViewHealthFile from "../../Modals/ViewHealthFile";
import "./styles.css";
import SeekOpinionModal from "../../Modals/SeekOpinionModal";
import AddReportsModal from "../../Modals/AddReportsModal";
import MobileView from "./MobileView";
import documentUploadGIF from "../../../Assets/Images/document_upload_GIF.gif";
import loadingGIF from "../../../Assets/loader.gif";

const MyHealthFiles = ({ language, entity }) => {
  const Navigate = useNavigate();
  // const [view, setView] = useState(false);
  // const [sharingHistory, setSharingHistory] = useState(false);
  const [availableFilters, setAvailableFilters] = useState([]);
  const [openShareReportsModal, setOpenShareReportsModal] = useState(false);
  const [openAddReportsModal, setOpenAddReportsModal] = useState(false);
  // const [sharedReportHistory, setSharedReportHistory] = useState([]);
  const [openViewReports, setOpenViewReports] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  //   const [alertAnchorEl, setAlertAnchorEl] = useState(null);
  const [loadingData, setloadingData] = useState({
    applyFilter: false,
    clearFilter: false,
    downloadZIP: false,
    downloadPDF: false,
  });
  // const [doctorList, setDoctorList] = useState([]);

  /**
   * Opens the Filter Panel
   */
  const [openFilters, setOpenFilters] = useState(false);
  const [isChangeDuration, setIsChangeDuration] = useState(false);
  /**
   * States for filters
   */
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [filterDates, setFilterDates] = useState({
    startMonth: null,
    endMonth: null,
    startYear: null,
    endYear: null,
  });

  /**
   * loader is an object of loaders(loader1, loader2, ...)
   */
  const [loader, setLoader] = useState({
    loader1: false,
  });
  /**
   * changeLoader changes the value of loader
   */
  const changeLoader = (name, value) => {
    setLoader({ ...loader, [name]: value });
  };

  /**
   * Filter Values
   */
  const [rawInvestigationImageFilters, setRawInvestigationImageFilters] =
    useState([]);
  const [rawInvestigationReportFilters, setRawInvestigationReportFilters] =
    useState([]);
  const [rawTreatmentFilters, setRawTreatmentFilters] = useState([]);
  const [tempReportList, setTempReportList] = useState([]);
  const [originalReportList, setOriginalReportList] = useState([]);
  const [reportList, setReportList] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [reportDetails, setReportDetails] = useState({
    index: "",
    patientID: "",
    RawReports: "",
    reportKey: "",
  });

  /**
   * Function to get my health files
   * @param {*} filter
   */
  const loadReports = async (filter = []) => {
    changeLoader("loader1", true);
    try {
      const token = await JSON.parse(sessionStorage.getItem("userData")).token;
      const userId = await JSON.parse(sessionStorage.getItem("userData"))._id;
      const resReports = await axios.post(
        `${base_url}/patient_reports/list/${userId}/PATIENT`,
        filter,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let tempReports = await resReports?.data?.data?.reverse();
      // setReports(tempReports);
      setTempReportList(tempReports);
      setOriginalReportList(resReports?.data?.data);
      setReportList(tempReports);
      changeLoader("loader1", false);
      setSessionStorage("all_reports", tempReports);
    } catch (err) {
      // console.log(err);
      swal({ text: err.response.data.message, icon: "error", timer: 1500 });
      Navigate("/");
      window.scrollTo(0, 0);
      changeLoader("loader1", false);
    }
  };

  // const getSharedReportsList = async () => {
  //   try {
  //     const token = await JSON.parse(sessionStorage.getItem("userData")).token;
  //     const resData = await sharedReportList(token);
  //     let temp = await resData.data.data;
  //     let temp2 = [];
  //     for (let i in temp) {
  //       let shared_on = temp[i].shared_on;
  //       let assignedDoctorID = temp[i].assignedDoctorID;
  //       for (let j in temp[i].shared_reports) {
  //         temp[i].shared_reports[j]["shared_on"] = shared_on;
  //         temp[i].shared_reports[j]["assignedDoctorID"] = assignedDoctorID;
  //         temp2.push(temp[i].shared_reports[j]);
  //       }
  //     }
  //     setSharedReportHistory(temp2.reverse());
  //   } catch (err) {
  //     // console.log(err);
  //     swal({ text: err.response.data.message, icon: "error", timer: 1500 });
  //     Navigate("/");
  //     window.scrollTo(0, 0);
  //   }
  // };

  const loadReportTypes = async () => {
    try {
      const resData = await cancerReportType();
      let tempReportTypes = await resData.data.data;
      let temp2 = tempReportTypes.map((i) => i.children.map((j) => j.label));
      setAvailableFilters(
        [...new Set(temp2.flat()), "HLA typing", "Blood Test Reports"].sort()
      );
      // Manually adding the last 2, just for demo purpose
    } catch (err) {
      // console.log(err);
      swal({ text: err.response.data.message, icon: "error", timer: 1500 });
      window.scrollTo(0, 0);
    }
  };

  const userDetails = useSessionStorage("userData");

  useEffect(() => {
    loadReports();
    if (userDetails?.cancer_type) {
      loadReportTypes();
    }
    // getSharedReportsList();

    /**
     * Function to get filter options
     */
    const getReportTypes = async () => {
      try {
        const res = await cancerReportType();
        setRawInvestigationImageFilters(res.data.data[0].children[0].children);
        setRawInvestigationReportFilters([
          ...res.data.data[0].children[1].children,
          ...res.data.data[0].children[2].children,
        ]);
        setRawTreatmentFilters(res.data.data[1].children);
      } catch (error) {
        // console.log(error);
      }
    };
    getReportTypes();
  }, []);

  useEffect(() => {
    changeAppliedFilters();
  }, [selectedFilters, filterDates]);

  // const primaryDoctor = doctorList.find((option)=> userDetails.primary_doctor === option._id);

  /**
   * Converting the objects in raw data sets to array
   */
  const investigationImageFilters = [];
  const investigationReportFilters = [];
  const treatmentFilters = [];
  rawInvestigationImageFilters.map((item) =>
    investigationImageFilters.push(item.value)
  );
  rawInvestigationReportFilters.map((item) =>
    investigationReportFilters.push(item.value)
  );
  rawTreatmentFilters.map((item) => treatmentFilters.push(item.value));

  /**
   * To open or close Popover
   */
  const openDownloadButtons = Boolean(anchorEl);

  /**
   * Function to select or deselect the checked or unchecked items
   * @param {*} e
   * @param {*} item - item selected or deselected
   */
  const handleCheckbox = (e, item) => {
    if (e.target.checked === true) {
      setCheckedItems([...checkedItems, item]);
    } else {
      setCheckedItems(checkedItems.filter((i) => i !== item));
    }
  };

  /**
   * Function to select or deselect all reports
   * @param {*} e
   */
  const handleSelectAllReports = (e) => {
    // const temp = tempReportList.filter((i) => { return !appliedFilters.length ? true : appliedFilters.includes(i.type); });
    const temp = [...reportList];
    if (e.target.checked === true) setCheckedItems([...temp]);
    else setCheckedItems([]);
  };

  /**
   * Function to sort items in acsending or decsending order
   * @param {*} sortedField - name of field or column to be sorted
   * @param {*} dir - direction
   */
  const handleSort = (sortedField, dir) => {
    let sortedProducts = [...reportList];
    if (sortedField !== null) {
      sortedProducts.sort((a, b) => {
        if (a[sortedField] < b[sortedField]) {
          return dir === "asc" ? -1 : 1;
        }
        if (a[sortedField] > b[sortedField]) {
          return dir === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    setReportList(sortedProducts);
  };

  /**
   * Function to open share reports modal
   * @returns - alert if no reports are selected
   */
  const handleShareReport = () => {
    // if (!checkedItems.length) {
    //   return swal({
    //     text:
    //       language === "Hindi"
    //         ? "कृपया कम से कम एक रिपोर्ट चुनें डाउनलोड करने के लिए"
    //         : "Please select one or more reports",
    //     icon: "error",
    //     timer: 1500,
    //   });
    // }
    // setOpenShareReportsModal(true);

    // Until doctor v2 is in development
    return swal({
      text:
        language === "Hindi"
          ? "चयनित सुविधा सक्रिय विकास में है और जल्द ही उपलब्ध होगी। हम आपके धैर्य की सराहना करते हैं।"
          : "The selected feature is in active development and will be available soon. We appreciate your patience.",
      icon: "warning",
      timer: 10000,
    });
  };

  /**
   * Function to open view report modal
   * @param {*} item - item to be viewed
   * @param {*} index - index of item
   */
  const handleViewReport = (item, index) => {
    setSessionStorage("all_reports", reportList);
    const [patientID, RawReports, reportKey] = item.key.split("/");

    setReportDetails({
      index: index,
      patientID: patientID,
      RawReports: RawReports,
      reportKey: reportKey,
    });

    setOpenViewReports(true);
  };

  /**
   * Function to select and delect filters
   * @param {*} item - item selected
   */
  const handleFilterCheckbox = (item) => {
    // if (e.target.checked === true) {
    if (!selectedFilters.includes(item)) {
      setSelectedFilters([...selectedFilters, item]);
      setPage(0);
    } else {
      setSelectedFilters(selectedFilters.filter((i) => i !== item));
    }
    // changeAppliedFilters();
    // console.log(reportList, "reportList");
  };

  /**
   * Function to select all filters under a category
   * @param {*} e
   * @param {*} filterArray
   */
  const selectAllSpecificFilters = (e, filterArray) => {
    if (e.target.checked) {
      setSelectedFilters([...new Set([...selectedFilters, ...filterArray])]);
      setPage(0);
    } else {
      let temp = selectedFilters.filter((item) => !filterArray.includes(item));
      setSelectedFilters(temp);
    }
    // changeAppliedFilters();
    // console.log(reportList, "reportList");
  };

  /**
   * Function to apply filters
   */
  const changeAppliedFilters = () => {
    setAppliedFilters([...selectedFilters]);
    let temp = tempReportList.filter((i) => {
      return !selectedFilters.length
        ? true
        : selectedFilters.includes(i.report_type);
    });

    setReportList(handleMonthFilter(temp));
    setPage(0);
    setOpenFilters(false);
  };

  const handleRemoveFilter = (e, item) => {
    let tempAppliedFilters = appliedFilters.filter((i) => {
      return i !== item;
    });
    setAppliedFilters(tempAppliedFilters);
    setSelectedFilters(tempAppliedFilters);

    let temp = tempReportList.filter((i) => {
      return !tempAppliedFilters.length
        ? true
        : tempAppliedFilters.includes(i.report_type);
    });
    setReportList(handleMonthFilter(temp));
  };

  const getLastDateOfMonth = (date) => {
    let year = parseInt(date.slice(0, 4));
    let month = parseInt(date.slice(5));
    const lastDate = new Date(year, month, 0);
    return lastDate;
  };

  /**
   * Function to apply duration filter
   * @param {*} reports - all reports
   * @returns - returns applied filters on report list
   */
  const handleMonthFilter = (reports = reportList) => {
    let temp = [];
    if (!isChangeDuration) {
      temp = reports.filter(
        (item) =>
          (!filterDates.startMonth ||
            new Date(item.reportDate) >=
              new Date(
                filterDates.startMonth.year(),
                filterDates.startMonth.month()
              )) &&
          (!filterDates.endMonth ||
            new Date(item.reportDate) <=
              new Date(
                filterDates.endMonth.year(),
                filterDates.endMonth.month()
              ))
      );
    } else {
      temp = reports.filter(
        (item) =>
          (filterDates.startYear < 1000 ||
            new Date(item.reportDate).getFullYear() >=
              filterDates.startYear.year()) &&
          (filterDates.endYear < 1000 ||
            new Date(item.reportDate).getFullYear() <=
              filterDates.endYear.year())
      );
    }
    return temp;
  };

  /**
   * Function to download reports
   * @param {*} singleReport
   * @returns
   */
  const onDownloadReport = async (singleReport) => {
    try {
      if (checkedItems?.length === 0 && !singleReport) {
        swal({
          text:
            language === "Hindi"
              ? "कृपया एक या एक से अधिक रिपोर्टें चुनें"
              : "Please select at least one report to download",
          icon: "error",
          timer: 1500,
        });
        window.scrollTo(0, 0);
        return;
      }
      swal({
        text:
          language === "Hindi"
            ? "कृपया प्रतीक्षा करें जब तक आपकी फ़ाइलें डाउनलोड हो रही हैं"
            : "Please wait while your file(s) are downloaded",
        type: "danger",
      });
      let data;
      if (singleReport && checkedItems?.length === 0)
        data = { reportsToDownload: [singleReport] };
      else if (!singleReport && checkedItems?.length)
        data = { reportsToDownload: checkedItems };
      const token = await JSON.parse(sessionStorage.getItem("userData")).token;
      setloadingData({ ...loadingData, download: true });
      const res = await fetch(
        `${base_url}/patient_reports/downloadMultipleReportsPatient`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );
      setloadingData({ ...loadingData, download: false });
      const dataRes = await res.json();
      if (dataRes) {
        window.open(dataRes?.data, "_blank");
        setAnchorEl(null);
        swal({
          text:
            language === "Hindi"
              ? "चयनित फ़ाइलें सफलतापूर्वक डाउनलोड हो गईं"
              : "Selected Files successfully downloaded",
          icon: "success",
          timer: 1500,
        });
      } else
        swal({
          text:
            language === "Hindi"
              ? "चयनित फ़ाइलें डाउनलोड नहीं हुईं"
              : "Selected Files not downloaded",
          icon: "error",
          timer: 1500,
        });
    } catch (err) {}
  };

  /**
   * Function to download single pdf of all reports
   * @returns
   */
  const onSinglePDFDownload = async () => {
    try {
      if (checkedItems?.length === 0) {
        swal({
          text:
            language === "Hindi"
              ? "कृपया कम से कम एक रिपोर्ट चुनें डाउनलोड करने के लिए"
              : "Please select at least one report to download",
          icon: "error",
          timer: 1500,
        });
        window.scrollTo(0, 0);
        return;
      }
      const token = await JSON.parse(sessionStorage.getItem("userData")).token;
      setloadingData({ ...loadingData, downloadPDF: true });
      let data = { reportsToDownload: checkedItems };
      const res = await fetch(
        `${base_url}/patient_reports/downloadMultipleReportsPDFPatient`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );
      setloadingData({ ...loadingData, downloadPDF: false });
      const dataRes = await res.json();
      if (dataRes) {
        window.open(dataRes?.data);
        setAnchorEl(null);
        swal({
          text:
            language === "Hindi"
              ? "चयनित फ़ाइलें सफलतापूर्वक डाउनलोड हो गईं"
              : "Selected Files successfully downloaded",
          icon: "success",
          timer: 1500,
        });
      } else
        swal({
          text:
            language === "Hindi"
              ? "चयनित फ़ाइलें डाउनलोड नहीं हुईं"
              : "Selected Files not downloaded",
          icon: "error",
          timer: 1500,
        });
    } catch (err) {}
  };

  const [page, setPage] = useState(0);
  const rowsPerPage = 5;

  // to calculate the total pages that the table would take up
  const totalPages = Math.ceil(reportList?.flat().length / rowsPerPage);
  return !loader.loader1 ? (
    <div>
      {originalReportList.length > 0 ? (
        <>
          <div>
            <div className="filter-table-section">
              {/* Images */}

              <div className="filter-column2">
                {/* <div className="filter-title"> */}
                <FormControlLabel
                  label="Images"
                  className="filter-title"
                  control={
                    <Checkbox
                      size="small"
                      checked={investigationImageFilters.every((v) =>
                        selectedFilters.includes(v)
                      )}
                      icon={<RadioButtonUnchecked />}
                      checkedIcon={<CheckCircleOutline color="secondary" />}
                      sx={{
                        "&:hover": { background: "none" },
                        "&:focus": { background: "none" },
                      }}
                    />
                  }
                  sx={{
                    margin: 0,
                  }}
                  onClick={(e) => {
                    selectAllSpecificFilters(e, investigationImageFilters);
                  }}
                />
                <div className="filter-column">
                  {investigationImageFilters.map((filter, index) => (
                    <div
                      className={
                        selectedFilters.includes(filter)
                          ? "filter-item filter-item-selected"
                          : "filter-item"
                      }
                      onClick={() => {
                        handleFilterCheckbox(filter);
                      }}
                    >
                      {filter}
                    </div>
                  ))}
                </div>
              </div>

              {/* Investigation */}
              <div className="filter-column2">
                <FormControlLabel
                  label="Investigation"
                  className="filter-title"
                  control={
                    <Checkbox
                      size="small"
                      checked={investigationReportFilters.every((v) =>
                        selectedFilters.includes(v)
                      )}
                      icon={<RadioButtonUnchecked />}
                      checkedIcon={<CheckCircleOutline color="secondary" />}
                      sx={{
                        "&:hover": { background: "none" },
                        "&:focus": { background: "none" },
                      }}
                    />
                  }
                  sx={{
                    margin: 0,
                  }}
                  onClick={(e) => {
                    selectAllSpecificFilters(e, investigationReportFilters);
                  }}
                />
                <div className="filter-column">
                  {investigationReportFilters.map((filter, index) => (
                    <div
                      className={
                        selectedFilters.includes(filter)
                          ? "filter-item filter-item-selected"
                          : "filter-item"
                      }
                      onClick={() => {
                        handleFilterCheckbox(filter);
                      }}
                    >
                      {filter}
                    </div>
                  ))}
                </div>
              </div>

              {/* Treatment */}
              <div className="filter-column2">
                <FormControlLabel
                  label="Treatment"
                  className="filter-title"
                  control={
                    <Checkbox
                      size="small"
                      checked={treatmentFilters.every((v) =>
                        selectedFilters.includes(v)
                      )}
                      icon={<RadioButtonUnchecked />}
                      checkedIcon={<CheckCircleOutline color="secondary" />}
                      sx={{
                        "&:hover": { background: "none" },
                        "&:focus": { background: "none" },
                      }}
                    />
                  }
                  sx={{
                    margin: 0,
                  }}
                  onClick={(e) => selectAllSpecificFilters(e, treatmentFilters)}
                />
                <div className="filter-column">
                  {treatmentFilters.map((filter, index) => (
                    // <FormControlLabel
                    //   label={filter}
                    //   key={index}
                    //   control={
                    //     <Checkbox
                    //       checked={selectedFilters.includes(filter)}
                    //       size="small"
                    //     />
                    //   }
                    //   onClick={(e) => handleFilterCheckbox(e, filter)}
                    // />
                    <div
                      className={
                        selectedFilters.includes(filter)
                          ? "filter-item filter-item-selected"
                          : "filter-item"
                      }
                      onClick={() => {
                        handleFilterCheckbox(filter);
                      }}
                    >
                      {filter}
                    </div>
                  ))}
                </div>
              </div>

              {/* Select Duration Filter */}
              {/* <div className="filter-section3"> */}
              <div className="filter-column-duration">
                <Typography className="filter-title2">
                  {language === "Hindi"
                    ? "अवधि का चयन करें"
                    : "Select Duration"}
                </Typography>

                <div
                  className="filters"
                  style={{
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                >
                  <div className="filter-input-container-duration">
                    {/* <label className="filter-input-label">From</label> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="filter-input"
                        value={filterDates.startMonth}
                        onChange={(value) => {
                          // if (value.year() >= 0 && value.year() < 10000)
                          setFilterDates({
                            ...filterDates,
                            ["startMonth"]: value,
                          });
                          // console.log(filterDates["endMonth"]);
                        }}
                        label="From"
                        sx={{ width: "12rem" }}
                        views={["month", "year"]}
                        slotProps={{ textField: { size: "small" } }}
                      />
                    </LocalizationProvider>
                    {/* </div>
            <div className="filter-input-container"> */}
                    {/* <label className="filter-input-label">To</label> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="filter-input"
                        value={filterDates.endMonth}
                        onChange={(value) => {
                          // if (value.year() >= 0 && value.year() < 10000)
                          setFilterDates({
                            ...filterDates,
                            ["endMonth"]: value,
                          });
                          // console.log(filterDates["endMonth"]);
                        }}
                        label="To"
                        sx={{ width: "12rem" }}
                        views={["month", "year"]}
                        slotProps={{ textField: { size: "small" } }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <button
                  id="clear-button"
                  onClick={() => {
                    setFilterDates({
                      startMonth: "",
                      endMonth: "",
                      startYear: "",
                      endYear: "",
                    });
                  }}
                >
                  {language === "Hindi" ? "साफ़ करें" : "Clear"}
                </button>
                {/* )} */}
              </div>
            </div>
            {/* )} */}
          </div>
          <TableContainer
            sx={{ maxHeight: "60vh", marginTop: "20px" }}
            className="tableContainer"
          >
            {/* {loader.loader1 && <LinearProgress color="success" />} */}
            <Table>
              <TableHead>
                <TableRow sx={muiStyles.tableHeader}>
                  <TableCell
                    sx={muiStyles.tableHeaderCell}
                    style={{ padding: "10px 10px 10px 15px" }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              color: "#FFFFFF",
                              ml: "14px",
                              padding: "0",
                              "&.Mui-checked": {
                                color: "#FFFFFF",
                              },
                            }}
                          />
                        }
                        checked={
                          reportList.length &&
                          reportList.every((v) => checkedItems.includes(v))
                        }
                        onClick={(e) => {
                          handleSelectAllReports(e);
                        }}
                      />
                    </FormGroup>
                  </TableCell>
                  <TableCell
                    sx={muiStyles.tableHeaderCell}
                    style={{ padding: "10px" }}
                  >
                    <div className="table-heading">
                      <b>
                        {language === "Hindi"
                          ? "जांच तिथि"
                          : "Investigation Date"}
                      </b>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <IconButton
                          onClick={() => handleSort("reportDate", "asc")}
                          sx={{ padding: 0 }}
                        >
                          <KeyboardArrowUp sx={muiStyles.tableSortArrow} />
                        </IconButton>
                        <IconButton
                          onClick={() => handleSort("reportDate", "desc")}
                          sx={{ padding: 0 }}
                        >
                          <KeyboardArrowDown sx={muiStyles.tableSortArrow} />
                        </IconButton>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    sx={muiStyles.tableHeaderCell}
                    style={{ padding: "10px" }}
                  >
                    <b>
                      {language === "Hindi" ? "रिपोर्ट का नाम" : "Report Name"}
                    </b>
                  </TableCell>
                  <TableCell
                    sx={muiStyles.tableHeaderCell}
                    style={{ padding: "10px" }}
                  >
                    <div className="table-heading">
                      <b>
                        {language === "Hindi"
                          ? "रिपोर्ट का प्रकार"
                          : "Report Type"}
                      </b>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <IconButton
                          onClick={() => handleSort("report_type", "asc")}
                          sx={{ padding: 0 }}
                        >
                          <KeyboardArrowUp sx={muiStyles.tableSortArrow} />
                        </IconButton>
                        <IconButton
                          onClick={() => handleSort("report_type", "desc")}
                          sx={{ padding: 0 }}
                        >
                          <KeyboardArrowDown sx={muiStyles.tableSortArrow} />
                        </IconButton>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    sx={muiStyles.tableHeaderCell}
                    style={{ padding: "10px" }}
                  ></TableCell>
                  <TableCell
                    sx={muiStyles.tableHeaderCell}
                    style={{ padding: "10px" }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportList
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell
                        sx={
                          index % 2 === 0
                            ? muiStyles.tableCell
                            : muiStyles.tableCellGrey
                        }
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  "&.Mui-checked": {
                                    color: "#6e326e",
                                  },
                                }}
                              />
                            }
                            checked={checkedItems?.includes(item)}
                            onClick={(e) => handleCheckbox(e, item)}
                          />
                        </FormGroup>
                      </TableCell>
                      <TableCell
                        sx={
                          index % 2 === 0
                            ? muiStyles.tableCell
                            : muiStyles.tableCellGrey
                        }
                      >
                        {parse_date(item?.reportDate)}
                      </TableCell>
                      <TableCell
                        sx={
                          index % 2 === 0
                            ? { ...muiStyles.tableCell, minWidth: "170px" }
                            : { ...muiStyles.tableCellGrey, minWidth: "170px" }
                        }
                      >
                        {item?.saved_filename}
                      </TableCell>
                      <TableCell
                        sx={
                          index % 2 === 0
                            ? muiStyles.tableCell
                            : muiStyles.tableCellGrey
                        }
                      >
                        {item?.report_type}
                      </TableCell>
                      <TableCell
                        sx={
                          index % 2 === 0
                            ? { ...muiStyles.tableCell, minWidth: "150px" }
                            : { ...muiStyles.tableCellGrey, minWidth: "150px" }
                        }
                      >
                        <Button
                          size="small"
                          className={"button-left button-white button-outline"}
                          onClick={() => handleViewReport(item, index + 1)}
                          sx={muiStyles.tableButton}
                        >
                          {language === "Hindi"
                            ? "रिपोर्ट देखें"
                            : "View Report"}
                        </Button>
                      </TableCell>
                      <TableCell
                        sx={
                          index % 2 === 0
                            ? muiStyles.tableCell
                            : muiStyles.tableCellGrey
                        }
                      >
                        <IconButton>
                          <DownloadOutlined
                            sx={{ color: "#000000" }}
                            onClick={() => onDownloadReport(item)}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="mobile-view">
            <MobileView
              language={language}
              reportList={reportList}
              setReportList={setReportList}
              page={page}
              rowsPerPage={rowsPerPage}
              handleCheckbox={handleCheckbox}
              handleViewReport={handleViewReport}
              setOpenAddReportsModal={setOpenAddReportsModal}
              changeAppliedFilters={changeAppliedFilters}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              setAppliedFilters={setAppliedFilters}
              tempReportList={tempReportList}
              handleMonthFilter={handleMonthFilter}
              selectAllSpecificFilters={selectAllSpecificFilters}
              filterDates={filterDates}
              setFilterDates={setFilterDates}
            />
          </div>

          {/* Common to All Screens */}

          {/* Pagination */}
          {reportList.length > 0 ? (
            <Typography
              sx={{ textAlign: "right", fontSize: "16px", mt: "10px" }}
            >
              {/* Button to move to the previous page of the table */}
              <IconButton
                sx={{
                  color: "#6E326E",
                }}
                disabled={page + 1 === 1}
                onClick={() => {
                  page && setPage(page - 1);
                }}
              >
                <ArrowBackIos
                  sx={{
                    fontSize: {
                      xs: "18px",
                      sm: "20px",
                    },
                  }}
                />
              </IconButton>
              {language === "Hindi"
                ? `पृष्ठ ${page + 1} में से `
                : `Page ${page + 1} of `}
              {totalPages}
              {/* IconButton to move to the next page of the table */}
              <IconButton
                sx={{
                  color: "#6E326E",
                }}
                disabled={page + 1 === totalPages}
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                <ArrowForwardIos
                  sx={{
                    fontSize: {
                      xs: "18px",
                      sm: "20px",
                    },
                  }}
                />
              </IconButton>
            </Typography>
          ) : (
            <Typography
              variant="body1"
              sx={{ textAlign: "center", mt: "20px" }}
            >
              {language === "Hindi"
                ? "कोई निर्माणित चिकित्सा दस्तावेज़ उपलब्ध नहीं है"
                : "No Curated Medical Documents Available"}
            </Typography>
          )}

          {reportList.length !== 0 && (
            <div className="bottom-button-box">
              <button
                className="bottom-buttons"
                onClick={() => setOpenAddReportsModal(true)}
              >
                <AddCircleOutline sx={{ mr: "5px" }} />
                {language === "Hindi" ? "रिपोर्ट जोड़ें" : "Add Reports"}
              </button>
              <button
                className="bottom-buttons"
                onClick={(event) => {
                  if (checkedItems?.length === 0) {
                    swal({
                      text:
                        language === "Hindi"
                          ? "कृपया कम से कम एक रिपोर्ट चुनें डाउनलोड करने के लिए"
                          : "Please select one or more reports",
                      icon: "error",
                      timer: 1500,
                    });
                  } else {
                    setAnchorEl(event.currentTarget);
                  }
                }}
              >
                {language === "Hindi"
                  ? "फ़ाइलें डाउनलोड करें"
                  : "Download Files"}
              </button>
              {checkedItems?.length !== 0 && (
                // To show download Options
                <Popover
                  className="button-popover"
                  open={openDownloadButtons}
                  onClose={() => {
                    setAnchorEl(null);
                  }}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  sx={{
                    "& .MuiPopover-paper": {
                      backgroundColor: "#FFFFFF",
                      boxShadow: "none",
                    },
                  }}
                >
                  <div className="download-buttons">
                    <Button
                      variant="outlined"
                      sx={{ my: "10px", ...muiStyles.outlinedButton }}
                      onClick={() => onDownloadReport()}
                    >
                      {loadingData.downloadZIP ? (
                        <div>
                          {language === "Hindi"
                            ? "डाउनलोड हो रहा है"
                            : "Downloading"}
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{
                              marginRight: "5px",
                              marginLeft: "5px",
                            }}
                          />
                        </div>
                      ) : language === "Hindi" ? (
                        "ZIP फ़ाइल के रूप में डाउनलोड करें"
                      ) : (
                        "Download as a ZIP File"
                      )}
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{ ...muiStyles.outlinedButton }}
                      onClick={() => onSinglePDFDownload()}
                    >
                      {loadingData.downloadPDF ? (
                        <div>
                          {language === "Hindi"
                            ? "डाउनलोड हो रहा है"
                            : "Downloading"}
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{
                              marginRight: "5px",
                              marginLeft: "5px",
                            }}
                          />
                        </div>
                      ) : language === "Hindi" ? (
                        "एकल PDF के रूप में डाउनलोड करें"
                      ) : (
                        "Download as Single Pdf"
                      )}
                    </Button>
                  </div>
                </Popover>
              )}
              <button className="bottom-buttons" onClick={handleShareReport}>
                {language === "Hindi" ? "राय लें" : "Seek Opinion"}
              </button>
            </div>
          )}

          {/* For Mobile View */}

          {/* Bottom Buttons */}
          {reportList?.length > 0 && (
            <div className="mobile-bottom-button-box">
              <button
                className="bottom-buttons"
                onClick={(event) => {
                  if (checkedItems?.length === 0) {
                    swal({
                      text:
                        language === "Hindi"
                          ? "कृपया कम से कम एक रिपोर्ट चुनें डाउनलोड करने के लिए"
                          : "Please select one or more reports",
                      icon: "error",
                      timer: 1500,
                    });
                  } else {
                    setAnchorEl(event.currentTarget);
                  }
                }}
              >
                {language === "Hindi"
                  ? "फ़ाइलें डाउनलोड करें"
                  : "Download Files"}
              </button>
              {checkedItems?.length !== 0 && (
                // To show download Options
                <Popover
                  className="mobile-popover"
                  open={openDownloadButtons}
                  onClose={() => {
                    setAnchorEl(null);
                  }}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  sx={{
                    "& .MuiPopover-paper": {
                      backgroundColor: "#FFFFFF",
                      boxShadow: "none",
                    },
                  }}
                >
                  <div className="download-buttons">
                    <Button
                      variant="outlined"
                      sx={{ my: "10px", ...muiStyles.outlinedButton }}
                      onClick={() => onDownloadReport()}
                    >
                      {loadingData.downloadZIP ? (
                        <div>
                          {language === "Hindi"
                            ? "डाउनलोड हो रहा है"
                            : "Downloading"}
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{
                              marginRight: "5px",
                              marginLeft: "5px",
                            }}
                          />
                        </div>
                      ) : language === "Hindi" ? (
                        "ZIP फ़ाइल के रूप में डाउनलोड करें"
                      ) : (
                        "Download as a ZIP File"
                      )}
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{ ...muiStyles.outlinedButton }}
                      onClick={() => onSinglePDFDownload()}
                    >
                      {loadingData.downloadPDF ? (
                        <div>
                          {language === "Hindi"
                            ? "डाउनलोड हो रहा है"
                            : "Downloading"}
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{
                              marginRight: "5px",
                              marginLeft: "5px",
                            }}
                          />
                        </div>
                      ) : language === "Hindi" ? (
                        "एकल PDF के रूप में डाउनलोड करें"
                      ) : (
                        "Download as Single Pdf"
                      )}
                    </Button>
                  </div>
                </Popover>
              )}
              <button className="bottom-buttons" onClick={handleShareReport}>
                {language === "Hindi" ? "राय लें" : "Seek Opinion"}
              </button>
            </div>
          )}

          {/* Modals */}

          {/* Add Reports Modal */}
          {openAddReportsModal && (
            <AddReportsModal
              openAddReportsModal={openAddReportsModal}
              setOpenAddReportsModal={setOpenAddReportsModal}
              language={language}
            />
          )}

          {/* View My Health File Modal */}
          {openViewReports && (
            <ViewHealthFile
              reportDetails={reportDetails}
              openViewReports={openViewReports}
              setOpenViewReports={setOpenViewReports}
              language={language}
            />
          )}

          {/* Seek opinion Modal */}
          {openShareReportsModal && (
            <SeekOpinionModal
              openShareReportsModal={openShareReportsModal}
              setOpenShareReportsModal={setOpenShareReportsModal}
              selectedDocuments={checkedItems}
              setSelectedDocuments={setCheckedItems}
              entity={entity}
              primaryDoctor={userDetails.primary_doctor}
              language={language}
            />
          )}
        </>
      ) : (
        // Image and text that shows when no list is available
        <div className="GIF-container">
          <img
            src={documentUploadGIF}
            alt="my-health-files"
            className="health-file-GIF"
          />
          <p>
            {language === "Hindi"
              ? "कृपया रिपोर्ट तैयार होने तक प्रतीक्षा करें। जैसे ही आपकी स्वास्थ्य फ़ाइलें उपलब्ध होंगी हम आपको सूचित करेंगे।"
              : "Please wait for reports to get curated. We will notify you as soon as your health files are available."}
          </p>
          <p>
            {language === "Hindi"
              ? "आपकी स्वास्थ्य फ़ाइलें स्वचालित रूप से आपके डॉक्टर के साथ समन्वयित हो जाती हैं। आप और रिपोर्ट जोड़ सकते हैं।"
              : "Your health files are automatically synced with your doctor. You can add more reports."}
          </p>

          <button
            className="bottom-buttons"
            onClick={() => setOpenAddReportsModal(true)}
            style={{ marginTop: "20px" }}
          >
            <AddCircleOutline sx={{ mr: "5px" }} />
            {language === "Hindi" ? "रिपोर्ट जोड़ें" : "Add Reports"}
          </button>

          {/* Add Reports Modal */}
          {openAddReportsModal && (
            <AddReportsModal
              openAddReportsModal={openAddReportsModal}
              setOpenAddReportsModal={setOpenAddReportsModal}
              language={language}
            />
          )}
        </div>
      )}
    </div>
  ) : (
    //Loading GIF shows while the screen is loading- until we get our API responses
    <div className="loading-GIF">
      <img src={loadingGIF} alt="loading"></img>
    </div>
  );
};

export default MyHealthFiles;
