import React, { useState } from "react";
import { sendQuery, shareReports } from "../../Services/patients";
import swal from "sweetalert";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { Close, HighlightOff } from "@mui/icons-material";
import "./styles.css";

const SeekOpinionModal = (props) => {
  const {
    openShareReportsModal,
    setOpenShareReportsModal,
    selectedDocuments,
    setSelectedDocuments,
    entity,
    language,
  } = props;

  const [commonQuery, setCommonQuery] = useState("");
  const [specificQueries, setSpecificQueries] = useState({});

  const [loader, setLoader] = useState({ loader1: false });
  const changeLoader = (name, value) => {
    setLoader({ ...loader, [name]: value });
  };

  const handleClose = () => setOpenShareReportsModal(false);

  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const handleRemoveSelectedDocuments = (e, item) => {
    setSelectedDocuments(selectedDocuments.filter((i) => i !== item));
  };

  const handleSpecificQuery = (e, reportID, i) => {
    setSpecificQueries({
      ...specificQueries,
      [i]: { reportID, specificQuery: e.target.value },
    });
  };

  const shareReportsPatient = async () => {
    changeLoader("loader1", true);
    if (commonQuery === "") {
      swal({
        text:
          language === "Hindi"
            ? "कृपया भेजने से पहले एक प्रश्न लिखें!"
            : "Please write a query before sending!",
        icon: "error",
        timer: 1500,
      });
    } else {
      try {
        if (commonQuery !== "") {
          if (specificQueries !== {}) {
            const resQuery = await sendQuery({
              doctorID: props.primaryDoctor,
              patientQuery: commonQuery,
              reportID: selectedDocuments,
              specificQueries,
            });
            if (resQuery?.data?.data?.query) {
              const resShare = await shareReports(
                {
                  associated_query: resQuery?.data?.data?.query?._id,
                  assignedDoctorID: props.primaryDoctor,
                  patientID: userData?._id,
                  shared_reports: selectedDocuments,
                },
                entity
              );
              setCommonQuery("");
              setSpecificQueries({});
            }
          } else {
            const resQuery = await sendQuery({
              doctorID: props.primaryDoctor,
              patientQuery: commonQuery,
              reportID: selectedDocuments,
            });
            if (resQuery?.data?.data?.query) {
              const resShare = await shareReports(
                {
                  associated_query: resQuery?.data?.data?.query?._id,
                  assignedDoctorID: props.primaryDoctor,
                  patientID: userData?._id,
                  shared_reports: selectedDocuments,
                },
                entity
              );
            }
          }
        } else if (specificQueries !== {}) {
          const resQuery = await sendQuery({
            doctorID: props.primaryDoctor,
            reportID: selectedDocuments,
            specificQueries,
          });
          if (resQuery?.data?.data?.query) {
            const resShare = await shareReports(
              {
                associated_query: resQuery?.data?.data?.query?._id,
                assignedDoctorID: props.primaryDoctor,
                patientID: userData?._id,
                shared_reports: selectedDocuments,
              },
              entity
            );
          }
        } else {
          const resShare = await shareReports(
            {
              assignedDoctorID: props.primaryDoctor,
              patientID: userData?._id,
              shared_reports: selectedDocuments,
            },
            entity
          );
          if (resShare?.error === false)
            swal({ text: resShare?.message, icon: "success", timer: 1500 });
        }
        // }
        swal({
          text:
            language === "Hindi"
              ? "रिपोर्ट सफलतापूर्वक साझा की गई|"
              : "Reports shared successfully",
          icon: "success",
          timer: 1500,
        });
        changeLoader("loader1", false);
      } catch (error) {
        swal({ text: error.response.data.message, icon: "error", timer: 1500 });
        changeLoader("loader1", false);
      }
      changeLoader("loader1", false);
      handleClose();
    }
  };
  return (
    <Modal open={openShareReportsModal} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: "10px",
          p: "15px 20px",
          width: {
            xs: "95vw",
            sm: "80vw",
            lg: "70vw",
          },
        }}
        // className="share-reports"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mb: "10px",
          }}
        >
          <Typography sx={{ fontSize: "22px", color: "#6E326E" }}>
            <b>
              {language === "Hindi"
                ? "डॉक्टर के साथ रिपोर्ट साझा करें"
                : "Share Reports with Doctor"}
            </b>
          </Typography>
          <HighlightOff
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setOpenShareReportsModal(false);
            }}
          />
        </Box>
        <h5 style={{ marginBottom: "10px" }}>
          {language === "Hindi" ? "चयनित दस्तावेज़ी" : "Selected Documents"}
        </h5>
        <Box sx={{ maxHeight: "40vh", overflow: "auto" }}>
          {selectedDocuments?.map((item, index) => (
            <div className="sub-query-box" key={index}>
              <Box
                sx={{
                  background: "#f5f5f5 0% 0% no-repeat padding-box",
                  p: "5px 8px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "10px",
                  width: {
                    xs: "100%",
                    sm: "60%",
                    md: "30%",
                  },
                }}
              >
                <p>{item?.saved_filename}</p>
                <Close
                  sx={{
                    cursor: "pointer",
                  }}
                  fontSize="small"
                  onClick={(e) => handleRemoveSelectedDocuments(e, item)}
                />
              </Box>
              {selectedDocuments?.length > 1 && (
                // <div className="text-field-section">

                // </div>
                <TextField
                  InputProps={{ disableUnderline: true }}
                  className="text_field_3"
                  placeholder={
                    language === "Hindi"
                      ? "संबंधित रिपोर्ट के लिए अपना प्रश्न टाइप करें"
                      : "Type your query for associated report"
                  }
                  // sx={{width: "70%"}}
                  variant="standard"
                  key={item?._id}
                  value={specificQueries[index]?.specificQuery}
                  onChange={(e) => handleSpecificQuery(e, item?._id, index)}
                />
              )}
            </div>
          ))}
        </Box>
        <h5 style={{ marginBottom: "10px" }}>
          {language === "Hindi" ? "प्रश्न" : "Query"}
        </h5>
        <TextField
          InputProps={{ disableUnderline: true }}
          className={"text_field_multiline_2"}
          placeholder={
            language === "Hindi"
              ? "यहाँ अपना प्रश्न दर्ज करें"
              : "Enter your query here"
          }
          variant="standard"
          multiline
          maxRows={4}
          value={commonQuery}
          onChange={(e) => setCommonQuery(e.target.value)}
        />

        <div className="button-center-container">
          <Button
            variant="contained"
            className={"button-center button-bottom button-small"}
            onClick={() => shareReportsPatient()}
          >
            <div className="text1">
              {language === "Hindi" ? "भेजें " : "Send"}
              {loader.loader1 && (
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ marginRight: "5px", marginLeft: "5px" }}
                />
              )}
            </div>
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default SeekOpinionModal;
