import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { Button, TextField, IconButton, Typography } from "@mui/material";
import {
  parse_date,
  useSessionStorage,
  listDoctors,
  getSessionStorage,
} from "../Services/patients";
import { Row, Col } from "react-bootstrap";
import { Container as Container2 } from "./layouts/Container";
// import Navbar from "../element/Navbar";
import "../Styles/styles.css";
// import { saveAs } from "file-saver";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CircularProgress from "@mui/material/CircularProgress";
import { sendQuery, shareReports } from "../Services/patients";
import PatientNavbar from "./Navbar";
import swal from "sweetalert";
import { muiStyles } from "../Styles/muiStyles";
import { Share } from "@mui/icons-material";

const progress_style = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  width: "100%",
  zIndex: "2",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
};

const ViewReport = () => {
  const params = useParams();
  const Navigate = useNavigate();
  const { index, patientID, RawReports, reportKey } = params;
  const all_reports = useSessionStorage("all_reports");

  //   const [draftReport, setDraftReport] = useState({});
  const [draftReportLink, setDraftReportLink] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [showShareReport, setShowShareReport] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  // const [currentPrimaryDoctor, setCurrentPrimaryDoctor] = useState({
  //   first_name: "",
  //   last_name: "",
  //   _id: "",
  // });

  const [primaryDoctor, setPrimaryDoctor] = useState({});
  const [queryText, setQueryText] = useState("");
  const userData = useSessionStorage("userData");
  const language = getSessionStorage("language");

  const sendTabValue = (index) => {
    setActiveTab(index);
  };
  const [loader, setLoader] = useState({
    loader1: false,
    loader2: false,
  });
  const changeLoader = (name, value) => {
    setLoader({ ...loader, [name]: value });
  };

  //   const ref1 = useRef(null);

  // let report_details = useSessionStorage("report_details");

  const getDoctorsList = async () => {
    try {
      const doctorList = await listDoctors();
      setDoctorList(doctorList?.data?.data);
    } catch {}
  };

  const handleAskQuery = async () => {
    changeLoader("loader2", true);
    try {
      const resQuery = await sendQuery({
        doctorID: userData.primary_doctor,
        patientQuery: queryText,
        reportID: [all_reports[index - 1]],
        specificQueries: [],
      });
      if (resQuery?.data?.error) throw resQuery;
      if (resQuery?.data?.data?.query) {
        const resShare = await shareReports(
          {
            assignedDoctorID: userData.primary_doctor,
            patientID: userData?._id,
            shared_reports: [all_reports[index - 1]],
          },
          "PATIENT"
        );
        if (resShare?.data?.error) throw resShare;
        else {
          swal({
            icon: "success",
            // text: `Shared ${all_reports[index - 1].saved_filename} to Dr. ${
            //   primaryDoctor.first_name + " " + primaryDoctor.last_name
            // }`,
            text:
              language === "Hindi"
                ? `प्राथमिक डॉक्टर के साथ सफलतापूर्वक साझा कर दी गई|`
                : `Shared ${
                    all_reports[index - 1].saved_filename
                  } to your Primary Doctor Successfully.`,
            timer: 1500,
          });
        }
      }
    } catch (error) {
      console.log(error);
      swal({
        icon: "error",
        text:
          language === "Hindi"
            ? "रिपोर्ट्स साझा नहीं हुई|"
            : "Could not share reports.",
        timer: 1500,
      });
      changeLoader("loader2", false);
    }
    changeLoader("loader2", false);
    setQueryText("");
    setShowShareReport(false);
  };

  //   useEffect(() => {
  //     if (userData && doctorList.length !== 0) {
  //       let temp_item = doctorList?.find(
  //         (item) => item._id === userData?.primary_doctor
  //       );
  //       setCurrentPrimaryDoctor({ ...temp_item });
  //     }
  //   }, [userData, doctorList]);

  useEffect(() => {
    // sendTabValue(1);
    getDraftReport();
    getDoctorsList();
  }, [reportKey]);

  //   const tab = sessionStorage.getItem("tab");

  const getDraftReport = async () => {
    changeLoader("loader1", true);
    try {
      const getLink = await fetch(
        "https://0t38n05kd3.execute-api.ap-south-1.amazonaws.com/default/presigned_url",
        {
          method: "POST",
          body: JSON.stringify({
            bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            report_key_array: [`${patientID}/${RawReports}/${reportKey}`],
          }),
        }
      );
      const link = await getLink.json();
      setDraftReportLink(link?.presigned_url_array[0]);
    } catch {}
    changeLoader("loader1", false);
  };
  // if (draftReportLink.length) draftReport.map((x, i) => x.preSignedURL = draftReportLink[i]);
  const handleCloseWindow = () => {
    return Navigate("/patient/dashboard/1");
  };

  //   const onDownloadReport = async () => {
  //     // const userData = await JSON.parse(sessionStorage.getItem("userData"));
  //     const res = await fetch(`${draftReportLink}`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     })
  //       .then((res) => {
  //         return res.blob();
  //       })
  //       .then(function (blob) {
  //         // console.log(blob);
  //         saveAs(blob, `${all_reports[index - 1].saved_filename}`);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   };

  const getNextReport = () => {
    let nextIndex = index % all_reports.length;
    const nextReport = all_reports[nextIndex];
    return Navigate(`/patient/viewReport/${nextIndex + 1}/${nextReport.key}`);
  };

  const getPrevReport = () => {
    const total_reports = all_reports.length;
    let prevIndex = (index - 2 + total_reports) % total_reports;
    const prevReport = all_reports[prevIndex];
    return Navigate(`/patient/viewReport/${prevIndex + 1}/${prevReport.key}`);
  };

  return (
    <>
      <PatientNavbar sendTabValue={sendTabValue} entity={"PATIENT"} />
      <Container2>
        <Row>
          <Col sm={5} md={5} lg={5}>
            {/* <ArrowCircleLeftOutlinedIcon fontSize='large' style={{ cursor: 'pointer' }} onClick={handleCloseWindow} /> */}
            <h5 style={{ fontWeight: "bold", display: "inline-block" }}>
              <ArrowCircleLeftOutlinedIcon
                fontSize="large"
                style={{ margin: "0 15px 0 0", cursor: "pointer" }}
                onClick={() => {
                  handleCloseWindow();
                }}
              />
              {language === "Hindi" ? "रिपोर्ट देखें" : "View Reports"}
            </h5>
            <p
              style={{
                display: "inline-block",
                marginLeft: "50px",
                color: "#6E326E",
              }}
            >
              Report {index} of {all_reports.length}
            </p>
            <IconButton
              aria-label="delete"
              style={{ float: "right" }}
              onClick={getNextReport}
            >
              <ArrowForwardIosIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              style={{ float: "right" }}
              onClick={getPrevReport}
            >
              <ArrowBackIosIcon />
            </IconButton>

            {/* {loader.loader1 && <LinearProgress color='success' />} */}
            <div
              style={{
                height: "80vh",
                marginTop: "30px",
                position: "relative",
              }}
            >
              {loader.loader1 && (
                <div style={progress_style}>
                  <CircularProgress styles={{ opacity: "1" }} />
                </div>
              )}
              <iframe
                style={{ borderRadius: "10px 10px 0 0", overflow: "hidden" }}
                src={draftReportLink}
                height="100%"
                width="100%"
              />
            </div>
          </Col>

          <Col md={6} lg={7}>
            <div className="grid-container">
              <div className="upper">
                <h5 style={{ color: "#6E326E", display: "inline-block" }}>
                  {language === "Hindi" ? "तारीख: " : "Date: "}{" "}
                  {parse_date(all_reports[index - 1]?.reportDate)}
                </h5>
                <h5
                  style={{
                    color: "#6E326E",
                    display: "inline-block",
                    marginLeft: "50px",
                  }}
                >
                  {language === "Hindi" ? "फ़ाइल का नाम: " : "File Name: "}{" "}
                  {all_reports[index - 1]?.saved_filename}
                </h5>

                <h5 style={{ marginTop: "30px", fontWeight: "bold" }}>
                  {language === "Hindi" ? "इम्प्रेशन" : "Impressions"}
                </h5>

                <div
                  className="filter-selector-box"
                  style={{
                    minHeight: "200px",
                    padding: "20px",
                    marginBottom: "30px",
                  }}
                >
                  {all_reports[index - 1]?.impression}
                </div>

                {/* <h5 style={{ color: "#6E326E", cursor: "pointer" }}>Show sharing history</h5> */}
              </div>
              <Row>
                <Col md={10} xs={8}>
                  {showShareReport && (
                    <>
                      <Typography
                        variant="h6"
                        textAlign="left"
                        sx={{ ml: "10px" }}
                      >
                        {language === "Hindi"
                          ? "अपने डॉक्टर को प्रश्न भेजें"
                          : "Send Query to your Doctor"}
                      </Typography>
                      <TextField
                        InputProps={{ disableUnderline: true }}
                        className={"text_field_multiline_2"}
                        placeholder={
                          language === "Hindi"
                            ? "अपना प्रश्न यहाँ दर्ज करें"
                            : "Enter your query here"
                        }
                        variant="standard"
                        multiline
                        maxRows={4}
                        style={{ position: "static" }}
                        value={queryText}
                        onChange={(e) => {
                          setQueryText(e.target.value);
                        }}
                      />
                      <Button
                        className="search-options"
                        variant="contained"
                        sx={{
                          top: "10px",
                          right: "0",
                          height: "34px",
                          width: "100px",
                          borderRadius: "15px",
                          backgroundColor: "#6E326E",
                          "&:hover": {
                            backgroundColor: "#6E326E",
                          },
                        }}
                        disabled={queryText === ""}
                        onClick={handleAskQuery}
                      >
                        {loader?.loader2 && (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px", marginLeft: "5px" }}
                          />
                        )}
                        {language === "Hindi" ? "भेजें" : "Send"}
                      </Button>
                    </>
                  )}
                </Col>

                <Col
                  md={2}
                  xs={4}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  <div>
                    <Button
                      variant="contained"
                      sx={muiStyles.primaryContainedButton}
                      onClick={() => setShowShareReport(!showShareReport)}
                    >
                      <Share />
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container2>
    </>
  );
};

export default ViewReport;
