import React, { useState } from "react";
import welcomeImage from "../../../Assets/welcome.png";
import "./styles.css";
import AddReportsModal from "../../Modals/AddReportsModal";
import { AddCircleOutline } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Welcome = ({ language }) => {
  const navigate = useNavigate();
  const [openAddReportsModal, setOpenAddReportsModal] = useState(false);
  return (
    <div>
      <div className="welcome-container">
        <img src={welcomeImage} alt="welcome" id="welcome-image" />
        <p>
          {language === "Hindi"
            ? "आइए बेहतर स्वास्थ्य रिकॉर्ड प्रबंधन की ओर अपनी यात्रा शुरू करें!"
            : "Let's begin your journey to better health record management!"}{" "}
          <button id="start-button" onClick={() => navigate("/patient/dashboard/user-guide")}>
            {language === "Hindi" ? "शुरू करें" : "Get Started"}
          </button>
        </p>

        <div className="bottom-box">
          <p>
            {language === "Hindi"
              ? "अपनी स्वास्थ्य रिपोर्ट अपलोड करें. यदि पहले ही जोड़ा गया है तो कृपया प्रतीक्षा करें। आपकी स्वास्थ्य फ़ाइलें शीघ्र ही उपलब्ध होंगी."
              : "Upload your Health Reports. Kindly wait, if already added. Your Health Files will be available soon."}
          </p>
        </div>
        {/* Add Reports Button */}
        <button onClick={() => setOpenAddReportsModal(true)}>
          <AddCircleOutline sx={{ mr: "5px", fontSize: "22px" }} />
          {language === "Hindi" ? "रिपोर्ट जोड़ें" : "Add Reports"}
        </button>
      </div>

      {/* Add Reports Modal */}
      {openAddReportsModal && (
        <AddReportsModal
          openAddReportsModal={openAddReportsModal}
          setOpenAddReportsModal={setOpenAddReportsModal}
          language={language}
        />
      )}
    </div>
  );
};

export default Welcome;
