// styles

//Header
const outlinedIcon = {
  border: "1px solid #FFFFFF", //purple
  color: "#FFFFFF",
  borderRadius: "10px",
  "&:hover": {
    borderColor: "#6E326E", //purple
  },
  fontSize: "14px",
  py: {
    sm: "7px",
    lg: "9px",
  },
  px: {
    sm: "2px",
    md: "6px",
  },
};

// Table Styles
const tableContainer = {
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  boxShadow: "0px 1px 15px #00000029",
  borderRadius: "10px",
  mt: "10px",
  overflowX: "auto",
};

const tableHeader = {
  backgroundColor: "#6e326e",
  fontSize: "16px",
  fontWeight: "600",
  borderBottom: "none",
  whiteSpace: "nowrap",
};

const tableRow = {
  borderBottom: "none",
  py: "5px",
  px: "18px",
  whiteSpace: "nowrap",
};

const tableCell = {
  textAlign: "left",
  fontSize: "14px",
  borderBottom: "none",
  padding: "5px 0 5px 20px"
}

const tableHeaderCell={
  color: "#FFFFFF"
}

// Button Styles
const outlinedButton = {
  borderColor: "#6E326E", //purple
  color: "#000000",
  borderRadius: "10px",
  "&:hover": {
    borderColor: "#6E326E", //purple
  },
  fontSize: "13px",
  textTransform: "capitalize",
};

const secondaryOutlinedButton = {
  color: "#000000",
  border: "1px solid #6e326e",
  textTransform: "capitalize",
  "&:hover": {
    borderColor: "#6E326E", //purple
  },
  backgroundColor: "#FFFFFF"
}

const primaryContainedButton = {
  backgroundColor: "#6E326E", //purple
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#6E326E", //purple
  },
  fontSize: "13px",
  textTransform: "capitalize",
};

const secondaryContainedButton = {
  backgroundColor: "#FFFFFF",
  borderColor: "#6E326E",
  color: "#000000",
  "&:hover": {
    backgroundColor: "#FFFFFF",
    borderColor: "#6E326E",
  },
  fontSize: "13px",
  textTransform: "capitalize",
};

const textButton = {
  color: "#000000",
  textTransform: "capitalize",
};

const SelectedButton = {
  color: "#000000",
  textTransform: "capitalize",
  border: "1px solid #6E326E", //purple
  borderRadius: "10px",
  p: "5px",
};

// Modal Styles
const biopsyNote = {
  borderRadius: "10px",
  p: "15px 20px",
  width: "700px",
};

const shareReportModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: "10px",
  px: "25px",
  py: "10px",
  width: {
    xs: "360px",
    sm: "500px",
    md: "700px",
    lg: "820px",
  },
};

const signUpModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: "10px",
  px: {
    xs: "10px",
    sm: "30px",
  },
  py: "10px",
  width: {
    xs: "350px",
    sm: "500px",
    md: "650px",
    lg: "700px",
  },
};

const primaryText = {
  color: "#6E326E",
  fontFamily: "Proza Display",
};

const primaryText1 = {
  color: "#6E326E",
  fontFamily: "Open Sans",
  fontWeight: "bold",
};

const secondaryText = {
  color: "#000000",
  fontFamily: "Proza Display",
};

const secondaryText1 = {
  color: "#000000",
  fontFamily: "Open Sans",
};

const secondaryText2 = {
  color: "#ffffff",
  fontFamily: "Open Sans",
};

const secondaryText3 = {
  color: "#E6E6E6",
  fontFamily: "Open Sans",
};

export const muiStyles = {
  outlinedIcon,
  tableContainer,
  tableHeader,
  tableHeaderCell,
  tableRow,
  tableCell,
  outlinedButton,
  secondaryOutlinedButton,
  primaryContainedButton,
  secondaryContainedButton,
  textButton,
  SelectedButton,
  biopsyNote,
  shareReportModal,
  signUpModal,
  primaryText,
  primaryText1,
  secondaryText,
  secondaryText1,
  secondaryText2,
  secondaryText3,
};
