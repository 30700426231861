import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../../Styles/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TableHead,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import axios from "axios";
import { parse_date, parse_time } from "../../../Services/patients";
import LinearProgress from "@mui/material/LinearProgress";
import {
  ArrowBackIos,
  ArrowForwardIos,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { muiStyles } from "../muiStyles";
import { muiStyles as muiStyles1 } from "../../../Styles/muiStyles";
import swal from "sweetalert";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import filterIcon from "../../../Assets/filter-icon.png";
import Filters from "./Filters";
import onlineUploadGIF from "../../../Assets/Images/online_document.gif";
import loadingGIF from "../../../Assets/loader.gif";

const closeIconStyle = {
  float: "right",
  cursor: "pointer",
  marginLeft: "25px",
  transform: "translate(0, 10%)",
};

const Opinions = ({ language }) => {
  const [tempOpinions, setTempOpinions] = useState([]);
  const [opinions, setOpinions] = useState([]);
  const [originalOpinionsList, setOriginalOpinionsList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(0);
  let base_url = process.env.REACT_APP_BACKEND_URL;
  const userData = JSON.parse(sessionStorage.getItem("userData"));

  //loaders
  const [loader, setLoader] = useState({ loader1: false });
  const changeLoader = (name, value) => {
    setLoader({ ...loader, [name]: value });
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Opens the Filter Panel
   */
  const [openFilters, setOpenFilters] = useState(false);
  const [isDurationMonth, setIsDurationMonth] = useState(false);
  /**
   * States for filters
   */
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [filterDates, setFilterDates] = useState({
    startMonth: null,
    endMonth: null,
    startYear: null,
    endYear: null,
  });

  //filter functions

  const handleFilterCheckbox = (e, item) => {
    if (e.target.checked === true) {
      setSelectedFilters([...selectedFilters, item]);
    } else {
      setSelectedFilters(selectedFilters.filter((i) => i != item));
    }
  };

  const changeAppliedFilters = () => {
    setAppliedFilters([...selectedFilters]);
    let temp = tempOpinions.filter((i) => {
      return !selectedFilters.length
        ? true
        : selectedFilters.includes(i.status);
    });

    setOpinions(handleMonthFilter(temp));
    setOpenFilters(false);
    setPage(0);
  };

  // const handleRemoveFilter = (e, item) => {
  //   let tempAppliedFilters = appliedFilters.filter((i) => {
  //     return i != item;
  //   });
  //   setAppliedFilters(tempAppliedFilters);
  //   setSelectedFilters(tempAppliedFilters);

  //   let temp = tempReportList.filter((i) => { return !tempAppliedFilters.length ? true : tempAppliedFilters.includes(i.report_type); });
  //   setReportList(handleMonthFilter(temp));
  // };

  const getLastDateOfMonth = (date) => {
    let year = parseInt(date.slice(0, 4));
    let month = parseInt(date.slice(5));
    const lastDate = new Date(year, month, 0);
    return lastDate;
  };

  const handleMonthFilter = (reports) => {
    let temp = [];

    if (isDurationMonth) {
      temp = reports.filter(
        (item) =>
          (!filterDates.startMonth ||
            new Date(item.dateOfQuery) >=
              new Date(
                filterDates.startMonth.year(),
                filterDates.startMonth.month()
              )) &&
          (!filterDates.endMonth ||
            new Date(item.dateOfQuery) <=
              new Date(
                filterDates.endMonth.year(),
                filterDates.endMonth.month()
              ))
      );
    } else {
      temp = reports.filter(
        (item) =>
          (filterDates.startYear < 1000 ||
            new Date(item.dateOfQuery).getFullYear() >=
              filterDates.startYear.year()) &&
          (filterDates.endYear < 1000 ||
            new Date(item.dateOfQuery).getFullYear() <=
              filterDates.endYear.year())
      );
    }
    return temp;
  };

  const handleRemoveFilter = (e, item) => {
    let tempAppliedFilters = appliedFilters.filter((i) => {
      return i !== item;
    });
    setAppliedFilters(tempAppliedFilters);
    setSelectedFilters(tempAppliedFilters);

    let temp = tempOpinions.filter((i) => {
      return !tempAppliedFilters.length
        ? true
        : tempAppliedFilters.includes(i.status);
    });
    setOpinions(handleMonthFilter(temp));
  };

  // table pagination
  const rowsPerPage = 5;
  // to calculate the total pages that the table would take up
  const totalPages = Math.ceil(opinions.length / rowsPerPage);

  let navigate = useNavigate();

  const truncateQuery = (query) => {
    if (query?.length <= 50) return query;
    return query?.slice(0, 45) + "...";
  };

  const loadOpinions = async () => {
    changeLoader("loader1", true);
    try {
      const opns = await axios.get(
        `${base_url}/patients/getPatientQueries?askedBy=Patient&patientID=${userData._id}`,
        { headers: { Authorization: `Bearer ${userData.token}` } }
      );
      setOriginalOpinionsList(await opns.data.data.reverse());
      setOpinions(await opns.data.data.reverse());
      setTempOpinions(await opns.data.data.reverse());
    } catch (error) {
      swal({ text: error.response.data.message, icon: "error", timer: 1500 });
      navigate("/");
      window.scrollTo(0, 0);
      changeLoader("loader1", false);
    }
    changeLoader("loader1", false);
  };

  // sort opinions/queries
  const handleSort = (sortedField, dir) => {
    let sortedProducts = [...opinions];
    if (sortedField !== null) {
      sortedProducts.sort((a, b) => {
        if (a[sortedField] < b[sortedField]) {
          return dir === "asc" ? -1 : 1;
        }
        if (a[sortedField] > b[sortedField]) {
          return dir === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    setOpinions(sortedProducts);
  };

  const handleFilterBox = () => {
    const filterClass =
      document.getElementsByClassName("mobile-filters")[0].classList;
    if (filterClass.contains("active")) {
      filterClass.remove("active");
    } else {
      filterClass.add("active");
    }
  };

  useEffect(() => {
    loadOpinions();
    if (!userData) {
      return navigate("/");
    }
  }, []);

  return loader.loader1 ? (
    //Loading GIF shows while the screen is loading- until we get our API responses
    <div className="loading-GIF">
      <img src={loadingGIF} alt="loading"></img>
    </div>
  ) : originalOpinionsList.length > 0 ? (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          mt: "10px",
          position: "relative",
        }}
      >
        <button
          className="filter-button-laptop"
          onClick={() => setOpenFilters(!openFilters)}
        >
          {language === "Hindi" ? "फ़िल्टर" : "Filters"}
          <img
            className="filter-icon"
            src={filterIcon}
            alt="filter-icon"
            style={{ marginLeft: "5px" }}
          />
        </button>
      </Box>

      {/* Applied Filters Section */}
      {/* only shows when some filters are applied */}
      {!openFilters && appliedFilters.length !== 0 && (
        <div
          elevation={3}
          style={{
            borderRadius: "20px",
            padding: "10px",
          }}
        >
          {appliedFilters.map((item, index) => (
            <div
              className={"gray-div inline-block"}
              style={{
                margin: "5px 10px 5px 0",
                transform: "translate(0, 10%)",
                display: "inline-block",
              }}
              key={index}
            >
              {item}
              <CloseIcon
                style={closeIconStyle}
                fontSize="small"
                onClick={(e) => handleRemoveFilter(e, item)}
              />
            </div>
          ))}
          <div
            className={"gray-div inline-block"}
            style={{
              margin: "5px 10px",
              transform: "translate(0, 10%)",
              display: "inline-block",
            }}
          >
            <Button
              variant="text"
              style={{ padding: "0", color: "#6E326E" }}
              onClick={() => {
                setAppliedFilters([]);
                setSelectedFilters([]);
                setOpinions([...tempOpinions]);
                setPage(0);
              }}
            >
              {language === "Hindi" ? "सभी को हटाएँ" : "Clear All"}
            </Button>
          </div>
        </div>
      )}

      {/* Filter Box */}
      {openFilters && (
        <div className="filters-container">
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ fontWeight: "600", mb: "10px" }}>
              {language === "Hindi" ? "स्थिति चुनें" : "Choose Status"}
            </Typography>
            <FormControlLabel
              label={language === "Hindi" ? "अनुत्तरित" : "Unanswered"}
              control={
                <Checkbox
                  size="small"
                  checked={selectedFilters.includes("Opinion Requested")}
                  onClick={(e) => handleFilterCheckbox(e, "Opinion Requested")}
                />
              }
              sx={{ color: "#707070" }}
            />
            <FormControlLabel
              label={language === "Hindi" ? "उत्तरित" : "Answered"}
              control={
                <Checkbox
                  size="small"
                  checked={selectedFilters.includes("Resolved")}
                  onClick={(e) => handleFilterCheckbox(e, "Resolved")}
                />
              }
              sx={{ color: "#707070" }}
            />
          </Box>
          <Box>
            <Typography sx={{ fontWeight: "600", mb: "4px" }}>
              {language === "Hindi" ? "अवधि चुनें" : "Select Duration"}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              {/* Selected Button is shown with a border based on the condition */}
              {!isDurationMonth ? (
                <p
                  style={{
                    cursor: "pointer",
                    // paddingTop: "13px",
                    marginRight: "10px",
                  }}
                  onClick={() => setIsDurationMonth(true)}
                >
                  {language === "Hindi" ? "महीना" : "Month"}
                </p>
              ) : (
                <Button
                  variant="text"
                  sx={{
                    ...muiStyles1.SelectedButton,
                    mr: "10px",
                  }}
                  onClick={() => setIsDurationMonth(true)}
                >
                  {language === "Hindi" ? "महीना" : "Month"}
                </Button>
              )}

              {/* Selected Button is shown with a border based on the condition */}
              {!isDurationMonth ? (
                <Button
                  variant="text"
                  sx={{
                    ...muiStyles1.SelectedButton,
                  }}
                  onClick={() => setIsDurationMonth(false)}
                >
                  {language === "Hindi" ? "साल" : "Year"}
                </Button>
              ) : (
                <p
                  style={{
                    cursor: "pointer",
                    // paddingTop: "13px"
                  }}
                  onClick={() => setIsDurationMonth(false)}
                >
                  {language === "Hindi" ? "साल" : "Year"}
                </p>
              )}
              <div
                className={"gray-div inline-block"}
                style={{
                  margin: "0 0 0 10px",
                  padding: "0",
                  display: "inline-block",
                }}
              >
                <Button
                  variant="text"
                  sx={{ textTransform: "capitalize", color: "#6E326E" }}
                  onClick={() => {
                    setFilterDates({
                      startMonth: "",
                      endMonth: "",
                      startYear: "",
                      endYear: "",
                    });
                  }}
                >
                  {language === "Hindi" ? "साफ़ करें" : "Clear"}
                </Button>
              </div>
            </Box>

            {!isDurationMonth ? (
              <div
                className="filters"
                style={{
                  margin: "5px 0",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                <div className="filter-input-container">
                  {/* <label className="filter-input-label">From</label> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="filter-input"
                      label="From"
                      value={filterDates.startYear}
                      onChange={(value) => {
                        // if (value.year() >= 0 && value.year() < 10000)
                        setFilterDates({
                          ...filterDates,
                          ["startYear"]: value,
                        });
                      }}
                      sx={{ width: "8rem" }}
                      views={["year"]}
                      slotProps={{
                        textField: { size: "small" },
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <div className="filter-input-container">
                  {/* <label className="filter-input-label">To</label> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="filter-input"
                      label="To"
                      value={filterDates.endYear}
                      onChange={(value) => {
                        // if (value.year() >= 0 && value.year() < 10000)
                        setFilterDates({
                          ...filterDates,
                          ["endYear"]: value,
                        });
                      }}
                      sx={{ width: "8rem" }}
                      views={["year"]}
                      slotProps={{
                        textField: { size: "small" },
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            ) : (
              <div
                className="filters"
                style={{
                  margin: "5px 0",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                <div className="filter-input-container">
                  {/* <label className="filter-input-label">From</label> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="filter-input"
                      label="From"
                      value={filterDates.startMonth}
                      onChange={(value) => {
                        // if (value.year() >= 0 && value.year() < 10000)
                        setFilterDates({
                          ...filterDates,
                          ["startMonth"]: value,
                        });
                      }}
                      sx={{ width: "8rem" }}
                      views={["month", "year"]}
                      slotProps={{
                        textField: { size: "small" },
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <div className="filter-input-container">
                  {/* <label className="filter-input-label">To</label> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="filter-input"
                      label="To"
                      value={filterDates.endMonth}
                      onChange={(value) => {
                        // if (value.year() >= 0 && value.year() < 10000)
                        setFilterDates({
                          ...filterDates,
                          ["endMonth"]: value,
                        });
                      }}
                      sx={{ width: "8rem" }}
                      views={["month", "year"]}
                      slotProps={{
                        textField: { size: "small" },
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              // position: {
              //   sm: "none",
              //   md: "absolute",
              // },
              bottom: {
                md: "12px",
              },
              right: {
                md: "15px",
              },
              alignItems: "flex-end",
            }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{
                mr: "10px",
                ...muiStyles1.primaryContainedButton,
                px: "20px",
              }}
              onClick={() => changeAppliedFilters()}
            >
              {language === "Hindi" ? "लागू करें" : "Apply Filter"}
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ ...muiStyles1.secondaryContainedButton, px: "20px" }}
              onClick={() => {
                setOpenFilters(false);
                setSelectedFilters([...appliedFilters]);
              }}
            >
              {language === "Hindi" ? "रद्द करें" : "Cancel"}
            </Button>
          </Box>
        </div>
      )}

      {/* Opinions Table */}
      <TableContainer className="tableContainer" sx={muiStyles.tableContainer}>
        {loader.loader1 && <LinearProgress />}
        <Table>
          <TableHead>
            <TableRow sx={muiStyles.tableHeader}>
              <TableCell sx={muiStyles.tableHeaderCell}></TableCell>
              <TableCell sx={muiStyles.tableHeaderCell}>
                <div className="table-heading">
                  <b>
                    {language === "Hindi" ? "पूछताछ की तिथि" : "Query Date"}
                  </b>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <IconButton
                      onClick={() => handleSort("dateOfQuery", "desc")}
                      style={{ padding: 0 }}
                    >
                      <KeyboardArrowUp sx={muiStyles.tableSortArrow} />
                    </IconButton>
                    <IconButton
                      onClick={() => handleSort("dateOfQuery", "asc")}
                      style={{ padding: 0 }}
                    >
                      <KeyboardArrowDown sx={muiStyles.tableSortArrow} />
                    </IconButton>
                  </div>
                </div>
              </TableCell>
              <TableCell sx={muiStyles.tableHeaderCell}>
                <div className="table-heading">
                  <b>{language === "Hindi" ? "राय की तिथि" : "Opinion Date"}</b>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <IconButton
                      onClick={() => handleSort("dateOfOpinion", "desc")}
                      style={{ padding: 0 }}
                    >
                      <KeyboardArrowUp sx={muiStyles.tableSortArrow} />
                    </IconButton>
                    <IconButton
                      onClick={() => handleSort("dateOfOpinion", "asc")}
                      style={{ padding: 0 }}
                    >
                      <KeyboardArrowDown sx={muiStyles.tableSortArrow} />
                    </IconButton>
                  </div>
                </div>
              </TableCell>
              <TableCell sx={muiStyles.tableHeaderCell}>
                <b>{language === "Hindi" ? "डॉक्टर का नाम" : "Doctor Name"}</b>
              </TableCell>
              <TableCell sx={muiStyles.tableHeaderCell}>
                <div className="table-heading">
                  <b>{language === "Hindi" ? "स्थिति" : "Status"}</b>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <IconButton
                      onClick={() => handleSort("status", "desc")}
                      style={{ padding: 0 }}
                    >
                      <KeyboardArrowUp sx={muiStyles.tableSortArrow} />
                    </IconButton>
                    <IconButton
                      onClick={() => handleSort("status", "asc")}
                      style={{ padding: 0 }}
                    >
                      <KeyboardArrowDown sx={muiStyles.tableSortArrow} />
                    </IconButton>
                  </div>
                </div>
              </TableCell>
              <TableCell sx={muiStyles.tableHeaderCell}>
                <b>{language === "Hindi" ? "प्रश्न" : "Query"}</b>
              </TableCell>
              <TableCell sx={muiStyles.tableHeaderCell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="TableBody">
            {opinions
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((opn, index) => (
                <TableRow
                  sx={
                    index % 2 === 0
                      ? muiStyles.tableCell
                      : muiStyles.tableCellGrey
                  }
                  key={opinions.indexOf(opn)}
                >
                  <TableCell
                    sx={
                      index % 2 === 0
                        ? muiStyles.tableCell
                        : muiStyles.tableCellGrey
                    }
                  >
                    {opn?.status === "Opinion Requested" ? (
                      <span className="active-status">
                        {language === "Hindi" ? "चालू" : "Active"}
                      </span>
                    ) : (
                      <span className="past-status">
                        {language === "Hindi" ? "पिछला" : "Past"}
                      </span>
                    )}
                  </TableCell>
                  <TableCell
                    sx={
                      index % 2 === 0
                        ? muiStyles.tableCell
                        : muiStyles.tableCellGrey
                    }
                  >
                    {parse_date(opn?.dateOfQuery) +
                      " " +
                      parse_time(opn?.dateOfQuery)}
                  </TableCell>
                  <TableCell
                    sx={
                      index % 2 === 0
                        ? muiStyles.tableCell
                        : muiStyles.tableCellGrey
                    }
                  >
                    {opn.dateOfOpinion
                      ? parse_date(opn?.dateOfOpinion) +
                        " " +
                        parse_time(opn?.dateOfOpinion)
                      : "--"}
                  </TableCell>
                  <TableCell
                    sx={
                      index % 2 === 0
                        ? muiStyles.tableCell
                        : muiStyles.tableCellGrey
                    }
                  >
                    {opn?.assignedDoctorID?.first_name +
                      " " +
                      opn?.assignedDoctorID?.last_name}
                  </TableCell>
                  <TableCell
                    sx={
                      index % 2 === 0
                        ? muiStyles.tableCell
                        : muiStyles.tableCellGrey
                    }
                  >
                    {opn?.status}
                  </TableCell>
                  <TableCell
                    sx={
                      index % 2 === 0
                        ? { ...muiStyles.tableCell, minWidth: "250px" }
                        : { ...muiStyles.tableCellGrey, minWidth: "250px" }
                    }
                  >
                    {truncateQuery(opn?.patientQuery)}
                  </TableCell>
                  <TableCell
                    sx={
                      index % 2 === 0
                        ? {
                            ...muiStyles.tableCell,
                            paddingRight: "15px",
                            minWidth: "160px",
                          }
                        : {
                            ...muiStyles.tableCellGrey,
                            paddingRight: "15px",
                            minWidth: "160px",
                          }
                    }
                  >
                    <Button
                      size="small"
                      variant="outlined"
                      disabled={opn?.status !== "Resolved"}
                      sx={{
                        // fontSize: "15px",
                        // color: "#000000",
                        ...muiStyles.tableButton,
                      }}
                      onClick={() =>
                        navigate(`/patient/dashboard/opinions/view/${opn?._id}`)
                      }
                      className={`button-left button-white ${
                        opn.status !== "Resolved"
                          ? "disabled"
                          : "button-outline"
                      }`}
                    >
                      {language === "Hindi" ? "राय देखें" : "View Opinion"}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Mobile View of Opinions Screen */}
      <h6 className="table-title">
        {language === "Hindi" ? "पिछली राय" : "Past Opinions"}
      </h6>

      {/* Filters Section */}
      <div className="filter-button-box">
        <button id="filter-button" onClick={handleFilterBox}>
          <img className="filter-icon" src={filterIcon} alt="filter-icon" />
          {language === "Hindi" ? "फ़िल्टर" : "Filters"}
        </button>
        <div className="mobile-filters">
          <Filters
            language={language}
            selectedFilters={selectedFilters}
            handleFilterCheckbox={handleFilterCheckbox}
            isDurationMonth={isDurationMonth}
            setIsDurationMonth={setIsDurationMonth}
            filterDates={filterDates}
            setFilterDates={setFilterDates}
            changeAppliedFilters={changeAppliedFilters}
            handleFilterBox={handleFilterBox}
          />
        </div>
      </div>

      {/* Opinion List in Mobile View */}
      {opinions.length > 0 && (
        <div className="mobile-table-opinion">
          {opinions
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((opn, index) => (
              <div
                className={
                  index % 2 === 0
                    ? "opinion-container white-bg"
                    : "opinion-container grey-bg"
                }
                key={index}
              >
                <div className="opinion-title">
                  <h6>
                    {opn.dateOfOpinion ? parse_date(opn?.dateOfOpinion) : "--"}
                  </h6>
                  {opn?.status === "Opinion Requested" ? (
                    <span className="active-status">
                      {language === "Hindi" ? "चालू" : "Active"}
                    </span>
                  ) : (
                    <span className="past-status">
                      {language === "Hindi" ? "पिछला" : "Past"}
                    </span>
                  )}
                </div>
                <p>{opn?.patientQuery}</p>
                {opn?.status === "Resolved" && (
                  <button
                    onClick={() =>
                      navigate(`/patient/dashboard/opinions/view/${opn?._id}`)
                    }
                  >
                    {language === "Hindi" ? "रिपोर्ट देखें" : "View"}
                  </button>
                )}
              </div>
            ))}
        </div>
      )}

      {opinions.length === 0 && (
        <Typography variant="body1" sx={{ textAlign: "center", mt: "20px" }}>
          {language === "Hindi"
            ? "कोई राय उपलब्ध नहीं है"
            : "No opinions Available"}
        </Typography>
      )}

      {/* Pagination */}
      {/* Display the page number */}
      {opinions.length !== 0 && (
        <Typography sx={{ textAlign: "right", fontSize: "16px", mt: "10px" }}>
          {/* Button to move to the previous page of the table */}
          <IconButton
            sx={{
              color: "#6E326E",
            }}
            disabled={page + 1 === 1}
            onClick={() => {
              page && setPage(page - 1);
            }}
          >
            <ArrowBackIos
              sx={{
                fontSize: {
                  xs: "18px",
                  sm: "20px",
                },
              }}
            />
          </IconButton>
          {language === "Hindi"
            ? `पृष्ठ ${page + 1} में से `
            : `Page ${page + 1} of `}
          {totalPages}
          {/* IconButton to move to the next page of the table */}
          <IconButton
            sx={{
              color: "#6E326E",
            }}
            disabled={page + 1 === totalPages}
            onClick={() => {
              setPage(page + 1);
            }}
          >
            <ArrowForwardIos
              sx={{
                fontSize: {
                  xs: "18px",
                  sm: "20px",
                },
              }}
            />
          </IconButton>
        </Typography>
      )}
    </>
  ) : (
    // Image and text that shows when no list is available
    <div className="GIF-container">
      <img
        src={onlineUploadGIF}
        alt="my-health-files"
        className="health-file-GIF"
      />
      <p>
        {language === "Hindi"
          ? "चयनित सुविधा सक्रिय विकास में है और जल्द ही उपलब्ध होगी। हम आपके धैर्य की सराहना करते हैं।"
          : "The selected feature is in active development and will be available soon. We appreciate your patience."}
      </p>
      {/* <p>
        Wait for the response: Your query will be sent to your primary doctor.
      </p>
      <p>
        They will review your query and the associated reports and get back to
        you with an opinion.
      </p> */}
    </div>
  );
};

export default Opinions;
