import React, { useState, useLayoutEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import {
  verifyEmail,
  resendVerificationLink,
  getSessionStorage,
  setSessionStorage,
} from "../Services/patients";
import Flash from "./Flash/index";
import { Typography, Button } from "@mui/material";
import "../Styles/styles.css";
import "../Styles/styles2.css";
import { muiStyles } from "../Styles/muiStyles";

const Verify = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [objflashMessage, setobjflashMessage] = useState(null);
  const [notVerify, setNotVerify] = useState(false);
  const [loader, setLoader] = useState(false);
  const [reEmailSent, setReEmailSent] = useState(false);
  useLayoutEffect(() => {
    document.body.classList.add("pre_login");
  }, []);

  const language = getSessionStorage("language");

  const onVerifyEmail = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      let reverifyEmail = await verifyEmail(id);
      navigate("/patient/login");
      swal({
        text:
          language === "Hindi"
            ? "आपका खाता सफलतापूर्वक सत्यापित किया गया है|"
            : "Your account has been verified successfully.",
        icon: "success",
        timer: 1500,
      });
      // setobjflashMessage({ message: "Your account has been verified successfully", type: 'success' });
      setLoader(false);
    } catch (error) {
      console.log(error);
      // setobjflashMessage({ message: error.response.data.message, type: 'danger' });
      if (error?.response?.data?.message === "Verification link has expired!") {
        setNotVerify(true);
      }
      // swal({ text: error.response.data.message, icon: "error", timer: 1500 });
      window.scrollTo(0, 0);
      setLoader(false);
    }
  };
  const onResendLink = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      let resendLink = await resendVerificationLink(id);
      // navigate("/patient/login");
      // swal({
      //   text: "New verification link has been sent to your email!",
      //   icon: "success",
      //   timer: 1500,
      // });
      // setobjflashMessage({ message: "Your account has been verified successfully", type: 'success' });
      setLoader(false);
      setReEmailSent(true);
    } catch (error) {
      console.log(error);
      // setobjflashMessage({ message: error.response.data.message, type: 'danger' });
      swal({
        text: error?.response?.data?.message,
        icon: "error",
        timer: 1500,
      });
      window.scrollTo(0, 0);
      setLoader(false);
    }
  };
  const callBackfromFalsh = (objFlash) => {
    setobjflashMessage(objFlash);
  };

  return (
    <div
      className="consent-sec"
      style={{ minHeight: "100vh", margin: "0", padding: "5vw" }}
    >
      {objflashMessage !== null && (
        <Flash
          flash_message={objflashMessage.message}
          flass_type={objflashMessage.type}
          parentCallback={callBackfromFalsh}
        />
      )}
      <div className="align-self-center consent-para">
        <h4
          style={{ color: "black", fontWeight: "600px", textAlign: "center" }}
        >
          {language === "Hindi" ? "ईमेल सत्यापित करें" : "Verify Email"}
        </h4>

        {/* If the resent email has not been sent yet, verify and resend verification email options will be shown */}
        {!reEmailSent ? (
          // If the verification email expires, below resend button will be shown
          notVerify ? (
            <div className="row">
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <Typography textAlign="center">
                  {language === "Hindi"
                    ? "आपके ईमेल सत्यापन लिंक की समय सीमा समाप्त हो गई है, कृपया नीचे दिए गए लिंक पर क्लिक करके लिंक को फिर से भेजें।"
                    : "Your email verification link has been expired, please click below to resend link"}
                </Typography>
                {/* To resend verification email */}
                <button
                  disabled={loader}
                  type="button"
                  className="doc-proceed"
                  onClick={(e) => onResendLink(e)}
                >
                  {loader && (
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: "5px" }}
                    />
                  )}
                  {!loader ? "Resend Link" : "Loading.."}
                </button>
              </div>
            </div>
          ) : (
            <div className="row">
              <div
                className="col-sm-12 text-center"
                style={{
                  textAlign: "center",
                  display: "block",
                  marginTop: "30px",
                }}
              >
                <Link to={"/"}>
                  <Button
                    className="doc-proceed "
                    variant="contained"
                    sx={{
                      marginRight: "10px",
                      ...muiStyles.secondaryContainedButton,
                    }}
                  >
                    {language === "Hindi" ? "वापस जाएं" : "Back"}
                  </Button>
                </Link>
                {/* To verify user */}
                <Button
                  disabled={loader}
                  variant="contained"
                  className="doc-proceed"
                  onClick={(e) => onVerifyEmail(e)}
                  sx={muiStyles.primaryContainedButton}
                >
                  {loader && (
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: "5px" }}
                    />
                  )}
                  {!loader
                    ? language === "Hindi"
                      ? "सत्यापित करें"
                      : "Verify"
                    : "Loading.."}
                </Button>
              </div>
              <div
                className="col-sm-12 text-center"
                style={{
                  textAlign: "center",
                  display: "block",
                  marginTop: "30px",
                }}
              >
                <Button
                  variant="text"
                  sx={muiStyles.textButton}
                  onClick={() => {
                    setSessionStorage("language", "Hindi");
                    window.location.reload();
                  }}
                >
                  हिंदी
                </Button>
                <Button
                  variant="text"
                  sx={muiStyles.textButton}
                  onClick={() => {
                    setSessionStorage("language", "English");
                    window.location.reload();
                  }}
                >
                  English
                </Button>
              </div>
            </div>
          )
        ) : (
          <div className="row">
            <Typography textAlign="center">
              {language === "Hindi"
                ? "नया सत्यापन लिंक आपके ईमेल पर भेज दिया गया है!"
                : "New verification link has been sent to your email!"}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};
export default Verify;
