import { Box, Typography, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Image } from "react-bootstrap";
import { muiStyles } from "../../../Styles/muiStyles";
import halfArrow from "../../../Assets/half arrow.png";
import { customDateFormat } from "../../../Services/doctors";
import { getSessionStorage, viewOpinion } from "../../../Services/patients";
import ViewReportModal from "./../../Modals/ViewReportModal";
import "../../../Styles/styles.css";
import "./styles.css";

const ViewOpinion = () => {
  const [opinion, setOpinion] = useState({});

  const navigate = useNavigate();
  const params = useParams();
  const [viewReportItem, setViewReportItem] = useState("");

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const language = getSessionStorage("language");

  const { queryID } = params;

  /**
   * Function to set item to view Report state
   * @param {*} item 
   * @param {*} index 
   */
  const handleViewReport = (item, index) => {
    setViewReportItem(item);
  };

  /**
   * Function to shorten the report name
   * @param {*} report_name 
   * @returns 
   */
  const truncateOpinionReportName = (report_name) => {
    if (report_name.length <= 20) return report_name;
    return report_name.slice(0, 16) + "...";
  };

  useEffect(() => {
    /**
     * Function to get opinion of doctor
     */
    const getOpinion = async () => {
      if (queryID) {
        const res = await viewOpinion(queryID, userData.token);
        setOpinion(res?.data?.data[0]);
      }
    };
    getOpinion();
  }, [queryID, userData.token]);

  return (
    <Container fluid style={{ padding: "0" }}>
      <ViewReportModal
        viewReportItem={viewReportItem}
        setViewReportItem={setViewReportItem}
      />
      <div className="opinion-screen">

        {/* Top fixed box to navigate back and show report list */}
        <div
          className="flex-row2"
          style={{ gap: "5px", padding: "10px 0", cursor: "pointer" }}
          onClick={() => navigate("/patient/dashboard/opinions")}
        >
          <Image className="arrow" src={halfArrow} />
          <p style={{ fontSize: "18px", fontWeight: "600" }}>
            {" "}
            {language === "Hindi" ? "पीछे जाएं" : "Back"}
          </p>
        </div>

        {/* Opinion Box */}
        <div className="flex-col" style={{ marginTop: "15px" }}>
          <Typography
            sx={{ fontSize: "18px", color: "#6E326E", fontWeight: "600" }}
          >
            {customDateFormat(opinion?.dateOfQuery, "dd/mm/yyyy")}
          </Typography>
          <div className="query-details">
            {opinion?.reportID?.map((report, index) => (
              <Button
                variant="outlined"
                sx={{
                  py: "0px",
                  backgroundColor: "#ffffff",
                  borderColor: "#6E326E",
                  color: "#000000",
                  borderRadius: "6px",
                  "&:hover": {
                    borderColor: "#6E326E",
                  },
                  fontSize: "13px",
                  textTransform: "capitalize",
                  minWidth: "150px",
                  marginRight: "5px",
                }}
                onClick={() => handleViewReport(report, index)}
              >
                {truncateOpinionReportName(report?.saved_filename)}
              </Button>
            ))}
          </div>
        </div>
      </div>

      {/* Patient Query */}
      {opinion && (
        <>
          <Box
            sx={{
              backgroundColor: "#F5F5F5",
              borderRadius: "20px",
              p: "15px",
              my: "10px",
            }}
          >
            <div className="query-header">
              <Typography
                sx={{ fontSize: "16px", color: "#6E326E", fontWeight: "600" }}
              >
                {language === "Hindi" ? "प्रश्न" : "Query"}
              </Typography>
            </div>
            <Typography sx={{ textAlign: "left", fontSize: "15px" }}>
              {opinion?.patientQuery}
            </Typography>

            {/* Doctor's Opinion */}
            <div className="opinion-section">
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#6E326E",
                  textAlign: "left",
                  paddingBottom: "10px",
                }}
              >
                {language === "Hindi" ? "डॉक्टर की राय " : "Doctor's Opinion "}{" "}
                {`(${
                  opinion?.assignedDoctorID?.first_name +
                  " " +
                  opinion?.assignedDoctorID?.last_name
                })`}
              </Typography>
              <Typography sx={{ textAlign: "left", fontSize: "15px" }}>
                {opinion?.DoctorOpinion}
              </Typography>
            </div>
          </Box>
          {opinion?.specificQueries?.length > 0 &&
            opinion?.specificQueries?.reverse()?.map((specificQuery, index) => (
              <Box
                sx={{
                  backgroundColor: "#F5F5F5",
                  borderRadius: "20px",
                  p: "15px",
                  my: "15px",
                  ml: {
                    xs: "50px",
                    sm: "60px",
                    md: "100px",
                  },
                }}
              >
                <div className="query-header">
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#6E326E",
                      textAlign: "left",
                      fontWeight: "600",
                      textTransform: "capitalize",
                    }}
                  >
                    {specificQuery?.reportID?.saved_filename}
                  </Typography>
                </div>
                <Typography sx={{ textAlign: "left", fontSize: "15px" }}>
                  {specificQuery?.specificQuery}
                </Typography>
                <div className="opinion-section">
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#6E326E",
                      textAlign: "left",
                      paddingBottom: "10px",
                    }}
                  >
                    {language === "Hindi"
                      ? "डॉक्टर की राय "
                      : "Doctor's Opinion "}
                  </Typography>
                  <Typography sx={{ textAlign: "left", paddingBottom: "10px" }}>
                    {specificQuery?.specificOpinion}
                  </Typography>
                </div>
                <div
                  className="query-details"
                  style={{ justifyContent: "flex-end" }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      py: "3px",
                      ...muiStyles.primaryContainedButton,
                    }}
                    onClick={() =>
                      handleViewReport(specificQuery?.reportID, index)
                    }
                    style={{ marginRight: "5px" }}
                  >
                    {language === "Hindi" ? "रिपोर्ट देखें" : "View Report"}
                  </Button>
                </div>
              </Box>
            ))}
        </>
      )}
    </Container>
  );
};

export default ViewOpinion;
