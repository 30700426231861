import React from "react";
import "./styles.css";
import welcomePage from "../../../Assets/User Guide Images/welcome-page.png";
import uploadScreen from "../../../Assets/User Guide Images/upload-screen.png";
import uploadedReportsPage from "../../../Assets/User Guide Images/uploaded-reports-page.png";
import healthFilesPage from "../../../Assets/User Guide Images/health-files-page.png";
import seekOpinionScreen from "../../../Assets/User Guide Images/seek-opinion-screen.png";
import opinionPage from "../../../Assets/User Guide Images/opinions-page.png";
import profilePage from "../../../Assets/User Guide Images/profile-page.png";

const UserGuide = ({ language }) => {
  return (
    <div className="user-guide-container">
      <div className="guide-step-container">
        <h5>{language === "Hindi" ? "शुरू करें" : "Getting Started"}</h5>
        <p>
          {language === "Hindi"
            ? "OncoPHR पर साइन अप करने के लिए धन्यवाद, जो विशेष रूप से आपके ऑन्कोलॉजी मेडिकल रिपोर्ट प्रबंधन को सरल बनाने के लिए डिज़ाइन किया गया है। अब आप किसी भी समय और किसी भी डिवाइस से अपने सभी ऑन्कोलॉजी रिकॉर्ड को एक ही स्थान पर सुरक्षित रूप से संग्रहीत, एक्सेस और प्रबंधित कर सकते हैं। भौतिक कागजी कार्रवाई की परेशानी को अलविदा कहें और डिजिटलीकृत, व्यवस्थित स्वास्थ्य रिकॉर्ड को नमस्कार कहें जो आपकी स्वास्थ्य सेवा टीम के साथ साझा करना आसान है। OncoPHR आपकी कैंसर उपचार यात्रा में आपका विश्वसनीय साथी है, जो आपको मानसिक शांति और सुविधा प्रदान करता है जब आपको इसकी सबसे अधिक आवश्यकता होती है।"
            : "Thank you for signing up on OncoPHR which is exclusively designed to simplify your Oncology medical reports management. Now you can securely store, access, and manage all your Oncology records in one place, anytime and from any device. Say goodbye to the hassle of physical paperwork and hello to digitised, organised health records that are easy to share with your healthcare team. OncoPHR is your trusted companion on your cancer treatment journey, offering peace of mind and convenience when you need it most."}
        </p>
      </div>
      <div className="guide-step-container">
        <h5>
          {language === "Hindi"
            ? "यह काम किस प्रकार करता है?"
            : "How It Works?"}
        </h5>
        <h6>
          {language === "Hindi"
            ? "जब आप अपने डॉक्टर के पास जाते हैं तो क्या आप अपने साथ ढेर सारे कागजी रिकॉर्ड लेकर जाते हैं? क्या आपकी लैब रिपोर्टें विभिन्न उपकरणों पर सहेजी गई हैं?"
            : "Do you carry a bunch of paper records when you visit your doctor? Do you have your lab reports saved on different devices?"}
        </h6>
        <p>
          {language === "Hindi"
            ? "OncoPHR jpeg, jpg और pdf प्रारूप में फ़ाइलें स्वीकार करता है। बस अपने पेपर मेडिकल रिपोर्ट की तस्वीरें क्लिक करें या अपने डिवाइस पर पहले से सेव की गई लैब रिपोर्ट ढूंढें, और उन्हें OncoPHR पर अपलोड करें।"
            : "OncoPHR accepts files in jpeg, jpg and pdf format. Just click pictures of your paper medical reports or locate the lab reports already saved on your device, and upload them on OncoPHR."}
        </p>
      </div>
      <div className="guide-step-container">
        <h5>
          {language === "Hindi"
            ? "अपनी स्वास्थ्य रिपोर्ट अपलोड करें"
            : "Upload Your Health Reports"}
        </h5>
        <img
          src={welcomePage}
          alt="welcome page"
          className="user-guide-image"
        />
        <img
          src={uploadScreen}
          alt="upload screen"
          className="user-guide-image"
        />
        <p>
          {language === "Hindi"
            ? "हमारे विशेषज्ञों को आपके मेडिकल रिकॉर्ड प्राप्त हो गए हैं। कृपया हमारे विशेषज्ञों द्वारा अपनी स्वास्थ्य फ़ाइलों को व्यवस्थित करने के लिए 48 घंटे तक प्रतीक्षा करें ताकि आपके लिए अपने डॉक्टर तक पहुंचना और साझा करना आसान हो। जैसे ही आपकी स्वास्थ्य फ़ाइलें उपलब्ध होंगी हम आपको सूचित करेंगे। आपकी स्वास्थ्य फ़ाइलें स्वचालित रूप से आपके डॉक्टर के साथ समन्वयित हो जाती हैं।"
            : "Our experts have received your medical records. Please wait for 48 hrs for your health files to be organised by our experts so that it is easy for you to access and share with your doctor. We will notify you as soon as your health files are available. Your health files are automatically synced with your doctor."}
        </p>
      </div>
      <div className="guide-step-container">
        <h5>
          {" "}
          {language === "Hindi"
            ? "अपलोड करी गयी रिपोर्ट्स"
            : "Uploaded Health Reports"}
        </h5>
        <p>
          {language === "Hindi"
            ? "आप यहां कभी भी अपनी अपलोड की गई रिपोर्ट देख सकते हैं।"
            : "You can always check your uploaded reports here."}
        </p>
        <img
          src={uploadedReportsPage}
          alt="uploaded reports page"
          className="user-guide-image"
        />
      </div>
      <div className="guide-step-container">
        <h5>
          {" "}
          {language === "Hindi"
            ? "अपनी स्वास्थ्य फ़ाइलों तक पहुँचना"
            : "Accessing Your Health Files"}
        </h5>
        <p>
          {language === "Hindi"
            ? "हर बार जब आप लॉगिन करते हैं, तो आप अपनी स्वास्थ्य फ़ाइलें यहां देख सकते हैं। अपनी स्वास्थ्य फ़ाइल में एक रिपोर्ट चुनें और फ़ाइल खोलने के लिए `रिपोर्ट देखें` पर क्लिक करें।"
            : "Everytime you login, you can see your health files here. Select a report in your health file and click on view to open the file."}
        </p>
        <img
          src={healthFilesPage}
          alt="health-files-page"
          className="user-guide-image"
        />
      </div>
      <div className="guide-step-container">
        <h5>
          {language === "Hindi"
            ? "क्या आप अपने डॉक्टर से अपनी रिपोर्ट पर चर्चा करना चाहते हैं?"
            : "Do you want to discuss your reports with your doctor?"}
        </h5>
        <p>
          {language === "Hindi"
            ? "प्रतिक्रिया की प्रतीक्षा करें: आपकी क्वेरी आपके प्राथमिक चिकित्सक को भेजी जाएगी। वे आपके प्रश्न और संबंधित रिपोर्ट की समीक्षा करेंगे और एक राय के साथ आपसे संपर्क करेंगे।"
            : "Wait for the response: Your query will be sent to your primary doctor. They will review your query and the associated reports and get back to you with an opinion."}
        </p>
        <img
          src={seekOpinionScreen}
          alt="seek-opinion-screen"
          className="user-guide-image"
        />
      </div>
      <div className="guide-step-container">
        <h5>
          {" "}
          {language === "Hindi"
            ? "क्या आप अपने डॉक्टर की राय खोज रहे हैं?"
            : "Looking for your doctor’s responses?"}
        </h5>
        <p>
          {language === "Hindi"
            ? "इस अनुभाग में, आप अपने द्वारा उठाए गए प्रश्नों के संबंध में अपने डॉक्टर से राय प्राप्त करेंगे।"
            : "In this section, you'll find the responses from your doctor regarding the queries you raised."}
        </p>
        <img
          src={opinionPage}
          alt="opinion-page"
          className="user-guide-image"
        />
      </div>
      <div className="guide-step-container">
        <h5>{language === "Hindi" ? "प्रोफ़ाइल" : "Profile"}</h5>
        <p>
          {language === "Hindi"
            ? "इस अनुभाग में, आप अपनी प्रोफ़ाइल देख सकते हैं, अपना विवरण संपादित कर सकते हैं और पासवर्ड बदल सकते हैं"
            : "In this section, you can view your profile, edit your details and change password"}
        </p>
        <img
          src={profilePage}
          alt="profile-page"
          className="user-guide-image"
        />
      </div>
    </div>
  );
};

export default UserGuide;
