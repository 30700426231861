import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getPreviouslySubmittedReports,
  parse_date,
  parse_time,
} from "../../../Services/patients";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { muiStyles } from "../muiStyles";
import ViewReportModal from "../../Modals/ViewReportModal";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import "./styles.css";
import namasteImage from "../../../Assets/Images/namaste_image.png";
import loadingGIF from "../../../Assets/loader.gif";

const UploadedReports = ({ language }) => {
  let navigate = useNavigate();
  const [prevReports, setPrevReports] = useState([]);
  const [page, setPage] = useState(0);
  const [viewReportItem, setViewReportItem] = useState({});

  /**
   * loader is an object of loaders(loader1, loader2, ...)
   */
  const [loader, setLoader] = useState({
    loader1: false,
  });
  /**
   * changeLoader changes the value of loader
   */
  const changeLoader = (name, value) => {
    setLoader({ ...loader, [name]: value });
  };

  /**
   * Function to get previously uploaded reports
   */
  const loadPreviouslySubmitedReports = async () => {
    changeLoader("loader1", true);
    try {
      let userData = JSON.parse(sessionStorage.getItem("userData"));
      let resDraftReports = await getPreviouslySubmittedReports(
        userData?.token
      );
      let temp = await resDraftReports?.data?.data;
      let temp2 = [];
      for (let i in temp) {
        let date_created = temp[i]?.date_created;
        for (let j in temp[i]?.patient_raw_reports) {
          temp[i].patient_raw_reports[j]["date_created"] = date_created;
          temp2.push(temp[i]?.patient_raw_reports[j]);
        }
      }
      setPrevReports(temp2);
      changeLoader("loader1", false);
    } catch (error) {
      // console.log(error);
      swal({ text: error.response.data.message, icon: "error", timer: 1500 });
      navigate("/");
      window.scrollTo(0, 0);
    }
  };

  const rowsPerPage = 8;
  const totalPages = Math.ceil(prevReports.length / rowsPerPage);

  useEffect(() => {
    loadPreviouslySubmitedReports();
  }, []);

  return loader.loader1 ? (
    //Loading GIF shows while the screen is loading- until we get our API responses
    <div className="loading-GIF">
      <img src={loadingGIF} alt="loading"></img>
    </div>
  ) : prevReports.length > 0 ? (
    <div>
      {/* Large Screen Table View */}
      <TableContainer className="tableContainer" sx={muiStyles.tableContainer}>
        <Table>
          <TableHead>
            <TableRow sx={muiStyles.tableHeader}>
              <TableCell sx={muiStyles.tableHeaderCell}>
                <b>{language === "Hindi" ? "क्र. सं." : "Sr. No."}</b>
              </TableCell>
              <TableCell sx={muiStyles.tableHeaderCell}>
                <b>
                  {language === "Hindi"
                    ? "अपलोड की गई रिपोर्टें"
                    : "Uploaded Reports"}
                </b>
              </TableCell>
              <TableCell sx={muiStyles.tableHeaderCell}>
                <b>
                  {language === "Hindi"
                    ? "अपलोड तिथि और समय"
                    : "Upload Date and Time"}
                </b>
              </TableCell>
              <TableCell sx={muiStyles.tableHeaderCell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {prevReports
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={
                      index % 2 === 0
                        ? muiStyles.tableCell
                        : muiStyles.tableCellGrey
                    }
                  >
                    {prevReports.indexOf(item) + 1}
                  </TableCell>
                  <TableCell
                    sx={
                      index % 2 === 0
                        ? muiStyles.tableCell
                        : muiStyles.tableCellGrey
                    }
                  >
                    {item?.original_filename}
                  </TableCell>
                  <TableCell
                    sx={
                      index % 2 === 0
                        ? muiStyles.tableCell
                        : muiStyles.tableCellGrey
                    }
                  >
                    {parse_date(item?.date_created)}
                    &nbsp;&nbsp;&nbsp;
                    {parse_time(item?.date_created)}
                  </TableCell>
                  <TableCell
                    sx={
                      index % 2 === 0
                        ? muiStyles.tableCell
                        : muiStyles.tableCellGrey
                    }
                  >
                    <Button
                      onClick={() => {
                        setViewReportItem(item);
                      }}
                      variant="outlined"
                      size="small"
                      sx={muiStyles.tableButton}
                    >
                      {language === "Hindi" ? "रिपोर्ट देखें" : "View Report"}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Mobile View */}
      <h6 className="table-title">
        {language === "Hindi" ? "अपलोड की गई रिपोर्टें" : "Uploaded Reports"}
      </h6>

      {/* Mobile View Report list */}
      {prevReports.length > 0 && (
        <div className="mobile-table-reports">
          {prevReports
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, index) => (
              <div
                key={index}
                className={
                  index % 2 === 0 ? "report-box white-bg" : "report-box grey-bg"
                }
              >
                <div>
                  <h6>{item?.original_filename}</h6>
                  <p>{parse_date(item?.date_created)}</p>
                </div>
                <button
                  onClick={() => {
                    setViewReportItem(item);
                  }}
                >
                  {language === "Hindi" ? "रिपोर्ट देखें" : "View"}
                </button>
              </div>
            ))}
        </div>
      )}

      {/* Pagination */}
      {prevReports.length > 0 ? (
        <Typography sx={{ textAlign: "right", fontSize: "16px", mt: "10px" }}>
          {/* Button to move to the previous page of the table */}
          <IconButton
            sx={{
              color: "#6E326E",
            }}
            disabled={page + 1 === 1}
            onClick={() => {
              page && setPage(page - 1);
            }}
          >
            <ArrowBackIos
              sx={{
                fontSize: {
                  xs: "18px",
                  sm: "20px",
                },
              }}
            />
          </IconButton>
          {language === "Hindi"
            ? `पृष्ठ ${page + 1} में से `
            : `Page ${page + 1} of `}
          {totalPages}
          {/* IconButton to move to the next page of the table */}
          <IconButton
            sx={{
              color: "#6E326E",
            }}
            disabled={page + 1 === totalPages}
            onClick={() => {
              setPage(page + 1);
            }}
          >
            <ArrowForwardIos
              sx={{
                fontSize: {
                  xs: "18px",
                  sm: "20px",
                },
              }}
            />
          </IconButton>
        </Typography>
      ) : (
        <Typography variant="body1" sx={{ textAlign: "center", mt: "20px" }}>
          {language === "Hindi"
            ? "पहले अपलोड की गई कोई रिपोर्ट नहीं है"
            : "No Previously Uploaded Reports"}
        </Typography>
      )}

      {/* View Report Modal */}
      <ViewReportModal
        viewReportItem={viewReportItem}
        setViewReportItem={setViewReportItem}
      />
    </div>
  ) : (
    <div className="GIF-container">
      <img src={namasteImage} alt="uploaded-reports" id="namaste-image" />
      <p>
        {language === "Hindi"
          ? "आप यहां कभी भी अपनी अपलोड की गई रिपोर्ट देख सकते हैं। वर्तमान में कोई फ़ाइल अपलोड नहीं की गई है।"
          : "You can always check your uploaded reports here. Currently no files are uploaded."}
      </p>
    </div>
  );
};

export default UploadedReports;
