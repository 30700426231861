import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import LeftBar from "./leftBar/LeftBar";
import Opinions from "./opinions/Opinions";
import ViewOpinion from "./opinions/viewOpinion";
import {
  logout,
  getSessionStorage,
  getPreviouslySubmittedReports,
} from "../../Services/patients";
import TopNavbar from "./topNavbar/topNavbar";
import MyHealthFiles from "./myHealthFiles/myHealthFiles";
import UploadedReports from "./uploadedReports/UploadedReports";
import Profile from "./profile/profile";
import "./styles.css";
import Welcome from "./Welcome Page/Welcome";
import oncoLogo from "../../Assets/oncoLogo.png";
import hamburger from "../../Assets/navbar-menu.svg";
import SliderNav from "./slider navbar/SliderNav";
import UserGuide from "./userGuide/UserGuide";

const Dashboard = ({ entity, viewOpinion }) => {
  const params = useParams();
  const navigate = useNavigate();

  // to set the screen besides left bar
  const [mainMenuOption, setMainMenuOption] = useState(params.screen);

  /**
   * Function to get previously uploaded reports
   */
  const loadPreviouslySubmitedReports = async () => {
    try {
      let userData = JSON.parse(sessionStorage.getItem("userData"));
      let resDraftReports = await getPreviouslySubmittedReports(
        userData?.token
      );
      let temp = await resDraftReports?.data?.data;
      let temp2 = [];
      for (let i in temp) {
        let date_created = temp[i]?.date_created;
        for (let j in temp[i]?.patient_raw_reports) {
          temp[i].patient_raw_reports[j]["date_created"] = date_created;
          temp2.push(temp[i]?.patient_raw_reports[j]);
        }
      }
      //If no reports are added before, its a new user - redirect to welcome page
      //By default a user comes to my health files
      if (temp2.length === 0) {
        setMainMenuOption("welcome");
      }
    } catch (error) {
      // console.log(error);
    }
  };

  /**
   * Function to logout of the patient portal
   */
  const onLogout = async (e) => {
    // e.preventDefault();
    try {
      let userData = JSON.parse(sessionStorage.getItem("userData"));
      //sessionStorage.removeItem("userData");
      let resData = await logout(userData?.token, "PATIENT");
      return navigate("/patient/login");
    } catch (error) {
      // console.log(error);
      window.scrollTo(0, 0);
    }
  };

  /**
   * Funtion to RENDER MAIN MENU OPTIONS
   * @returns - component to be rendered
   */
  const renderMainMenuOptions = () => {
    switch (mainMenuOption) {
      case "myHealthFiles":
        // My Health Files Screen
        return <MyHealthFiles language={language} entity={entity} />;
      case "opinions":
        // Opinions Screen
        return <Opinions language={language} />;
      case "opinions/view":
        // View Particular Opinion
        return <ViewOpinion language={language} />;
      case "uploadedReports":
        // Uploaded Reports Screen
        return <UploadedReports language={language} />;
      case "profile":
        // Profile Screen
        return <Profile language={language} />;
      case "user-guide":
        //User-Guide
        return <UserGuide language={language} />;
      case "welcome":
        // Welcome Screen
        return <Welcome language={language} />;
      // Welcome Screen
      default:
        return <Welcome language={language} />;
    }
  };

  const language = getSessionStorage("language");

  /**
   * Function to add or remove classes for mobile slider navbar
   */
  const handleSliderNav = () => {
    //when slider opens, "active" class is added to classlist
    const sliderClass =
      document.getElementsByClassName("slider-nav-mobile")[0].classList;
    if (sliderClass.contains("active")) {
      sliderClass.remove("active");
    } else {
      sliderClass.add("active");
    }
  };

  useEffect(() => {
    if (params.screen) setMainMenuOption(params.screen);
    else if (params.queryID) {
      setMainMenuOption("opinions/view");
    }
  }, [params.screen]);

  useEffect(() => {
    loadPreviouslySubmitedReports();
  }, []);

  return (
    <div className="dashboard">
      <div className="dashboard-left-section">
        <LeftBar
          mainMenuOption={mainMenuOption}
          setMainMenuOption={setMainMenuOption}
          logout={onLogout}
          language={language}
        />
      </div>
      <div className="dashboard-right-section">
        <div className="top-navbar-mobile">
          <img src={oncoLogo} id="logo" alt="logo" />
          <img
            src={hamburger}
            id="hamburger"
            alt="hamburger-icon"
            onClick={handleSliderNav}
          />
        </div>
        <div className="slider-nav-mobile">
          <SliderNav
            logout={onLogout}
            mainMenuOption={mainMenuOption}
            setMainMenuOption={setMainMenuOption}
            language={language}
          />
        </div>
        <TopNavbar />
        {!viewOpinion ? renderMainMenuOptions() : <ViewOpinion />}
      </div>
    </div>
  );
};

export default Dashboard;
