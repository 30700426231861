import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import {
  getSessionStorage,
  listDoctors,
  parse_date,
  sendQuery,
  shareReports,
  useSessionStorage,
} from "../../Services/patients";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import {
  ArrowBackIos,
  ArrowCircleLeftOutlined,
  ArrowForwardIos,
  CancelOutlined,
  Share,
} from "@mui/icons-material";
import { muiStyles } from "../../Styles/muiStyles";
import "./styles.css";

const ViewHealthFile = (props) => {
  const Navigate = useNavigate();
  const { reportDetails, openViewReports, setOpenViewReports, language } =
    props;

  const [draftReportLink, setDraftReportLink] = useState("");
  const [showShareReport, setShowShareReport] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const [queryText, setQueryText] = useState("");
  const [loader, setLoader] = useState({
    loader1: false,
    loader2: false,
  });

  const changeLoader = (name, value) => {
    setLoader({ ...loader, [name]: value });
  };

  const handleClose = () => setOpenViewReports(false);
  const userData = useSessionStorage("userData");
  const all_reports = useSessionStorage("all_reports");

  const getDoctorsList = async () => {
    try {
      const doctorList = await listDoctors();
      setDoctorList(doctorList?.data?.data);
    } catch {}
  };

  const handleAskQuery = async () => {
    changeLoader("loader2", true);
    try {
      const resQuery = await sendQuery({
        doctorID: userData.primary_doctor,
        patientQuery: queryText,
        reportID: [all_reports[reportDetails.index - 1]],
        specificQueries: [],
      });
      if (resQuery?.data?.error) throw resQuery;
      if (resQuery?.data?.data?.query) {
        const resShare = await shareReports(
          {
            assignedDoctorID: userData.primary_doctor,
            patientID: userData?._id,
            shared_reports: [all_reports[reportDetails.index - 1]],
          },
          "PATIENT"
        );
        if (resShare?.data?.error) throw resShare;
        else {
          swal({
            icon: "success",
            text:
              language === "Hindi"
                ? `प्राथमिक डॉक्टर के साथ सफलतापूर्वक साझा कर दी गई|`
                : `Shared ${
                    all_reports[reportDetails.index - 1].saved_filename
                  } to your Primary Doctor Successfully.`,
            timer: 1500,
          });
        }
      }
    } catch (error) {
      // console.log(error);
      swal({
        icon: "error",
        text:
          language === "Hindi"
            ? "रिपोर्ट्स साझा नहीं हुई|"
            : "Could not share reports.",
        timer: 1500,
      });
      changeLoader("loader2", false);
    }
    changeLoader("loader2", false);
    setQueryText("");
    setShowShareReport(false);
  };

  useEffect(() => {
    // sendTabValue(1);
    getDraftReport();
    getDoctorsList();
  }, [reportDetails.reportKey]);

  const getDraftReport = async () => {
    changeLoader("loader1", true);
    try {
      const getLink = await fetch(
        "https://0t38n05kd3.execute-api.ap-south-1.amazonaws.com/default/presigned_url",
        {
          method: "POST",
          body: JSON.stringify({
            bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            report_key_array: [
              `${reportDetails.patientID}/${reportDetails.RawReports}/${reportDetails.reportKey}`,
            ],
          }),
        }
      );
      const link = await getLink.json();
      setDraftReportLink(link?.presigned_url_array[0]);
    } catch {}
    changeLoader("loader1", false);
  };

  const handleCloseWindow = () => {
    return Navigate("/patient/dashboard/1");
  };

  const getNextReport = () => {
    let nextIndex = reportDetails.index % all_reports.length;
    const nextReport = all_reports[nextIndex];
    return Navigate(`/patient/viewReport/${nextIndex + 1}/${nextReport.key}`);
  };

  const getPrevReport = () => {
    const total_reports = all_reports.length;
    let prevIndex = (reportDetails.index - 2 + total_reports) % total_reports;
    const prevReport = all_reports[prevIndex];
    return Navigate(`/patient/viewReport/${prevIndex + 1}/${prevReport.key}`);
  };

  const handleBoxOpen = () => {
    document.getElementById("box").className = "open-box";
    document.getElementById("share-btn").className = "show-no-button";
  };

  return (
    <Modal open={openViewReports} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: "10px",
          px: "25px",
          py: "20px",
          width: {
            xs: "90vw",
            sm: "88vw",
            md: "80vw",
            lg: "70vw",
          },
          display: "flex",
          flexDirection: "row",
          flexWrap: {
            xs: "wrap-reverse",
            sm: "wrap-reverse",
            md: "nowrap",
          },
          maxHeight: "85vh",
          overflow: "auto",
        }}
      >
        <div className="view-report-frame">
          <div
            style={{
              height: "80vh",
              position: "relative",
            }}
          >
            {loader.loader1 && (
              <CircularProgress
                sx={{
                  opacity: "1",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  right: "0",
                  bottom: "0",
                  width: "100%",
                  zIndex: "2",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              />
            )}
            <iframe
              style={{ borderRadius: "10px", overflow: "hidden" }}
              src={draftReportLink}
              height="100%"
              width="100%"
              title="report"
            />
          </div>
        </div>
        <div className="view-report-details">
          <div className="report-title">
            <h5>{all_reports[reportDetails.index - 1]?.saved_filename}</h5>

            <h6>
              {parse_date(all_reports[reportDetails.index - 1]?.reportDate)}
            </h6>

            <CancelOutlined onClick={handleClose} sx={{ cursor: "pointer" }} />
          </div>

          <h6>{language === "Hindi" ? "इम्प्रेशन" : "Impressions"}</h6>
          <div className="impression-box">
            <p>{all_reports[reportDetails.index - 1]?.impression}</p>
          </div>

          <button id="share-btn" onClick={handleBoxOpen}>
            Share Report
          </button>

          <div className="box" id="box">
            <h6>
              {language === "Hindi"
                ? "अपने डॉक्टर को प्रश्न भेजें"
                : "Send Query to your Doctor"}
            </h6>
            <TextField
              InputProps={{ disableUnderline: true }}
              className={"text_field_multiline_2"}
              placeholder={
                language === "Hindi"
                  ? "अपना प्रश्न यहाँ दर्ज करें"
                  : "Enter your query here"
              }
              variant="standard"
              multiline
              maxRows={4}
              style={{ position: "static" }}
              value={queryText}
              onChange={(e) => {
                setQueryText(e.target.value);
              }}
            />
            <Button
              className="search-options"
              variant="contained"
              sx={{
                top: "10px",
                height: "34px",
                borderRadius: "6px",
                backgroundColor: "#6E326E",
                "&:hover": {
                  backgroundColor: "#6E326E",
                },
                textTransform: "capitalize",
                width: {
                  xs: "100px",
                  sm: "150px",
                },
                marginBottom: "20px",
              }}
              disabled={queryText === ""}
              onClick={handleAskQuery}
            >
              {loader?.loader2 && (
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ marginRight: "5px", marginLeft: "5px" }}
                />
              )}
              {language === "Hindi" ? "भेजें" : "Submit"}
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ViewHealthFile;
