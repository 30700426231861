import { Clear, InfoOutlined } from "@mui/icons-material";
import React from "react";
import "./styles.css";
import messsage from "../../../Assets/white message.png";
import home from "../../../Assets/home.png";
import upload from "../../../Assets/white upload.png";
import profile from "../../../Assets/white profile 1.png";
import logoutIcon from "../../../Assets/white log-out.png";
import { useNavigate } from "react-router-dom";

const SliderNav = ({ logout, mainMenuOption, language, setMainMenuOption }) => {
  const navigate = useNavigate();

  /**
   * Function to add or remove classes for mobile slider navbar
   */
  const handleSliderNav = () => {
    const sliderClass =
      document.getElementsByClassName("slider-nav-mobile")[0].classList;
    if (sliderClass.contains("active")) {
      sliderClass.remove("active");
    } else {
      sliderClass.add("active");
    }
  };

  /**
   * Function to change screens on click of sidebar options
   * @param {*} option - screen
   */
  const changeOption = (option) => {
    setMainMenuOption(option);
    navigate(`/patient/dashboard/${option}`);
    handleSliderNav();
  };

  return (
    <div className="menu-container">
      <Clear
        onClick={handleSliderNav}
        sx={{ cursor: "pointer", color: "#FFFFFF", ml: "6px" }}
      />
      <div className="menu">
        <div
          className={
            mainMenuOption === "myHealthFiles"
              ? `navitem-mobile itemactive-mobile`
              : `navitem-mobile`
          }
          onClick={() => changeOption("myHealthFiles")}
        >
          <img src={home} alt="layers" className="nav-icon-mobile" />
          <p>
            {language === "Hindi"
              ? "मेरी स्वास्थ्य फ़ाइलें"
              : "My Health Files"}
          </p>
        </div>
        <div
          className={
            mainMenuOption === "opinions" || mainMenuOption === "opinions/view"
              ? `navitem-mobile itemactive-mobile`
              : `navitem-mobile`
          }
          onClick={() => changeOption("opinions")}
        >
          <img src={messsage} alt="layers" className="nav-icon-mobile" />
          <p> {language === "Hindi" ? "राय" : "Opinions"}</p>
        </div>
        <div
          className={
            mainMenuOption === "uploadedReports"
              ? `navitem-mobile itemactive-mobile`
              : `navitem-mobile`
          }
          onClick={() => changeOption("uploadedReports")}
        >
          <img src={upload} alt="layers" className="nav-icon-mobile" />
          <p>
            {" "}
            {language === "Hindi"
              ? "अपलोड की गई रिपोर्टें"
              : "Uploaded Reports"}
          </p>
        </div>
        <div
          className={
            mainMenuOption === "profile"
              ? `navitem-mobile itemactive-mobile`
              : `navitem-mobile`
          }
          onClick={() => changeOption("profile")}
        >
          <img src={profile} style={{ width: "18px" }} alt="layers" />
          <p> {language === "Hindi" ? "प्रोफ़ाइल" : "Profile"}</p>
        </div>
        <div
          className={
            mainMenuOption === "user-guide"
              ? `navitem-mobile itemactive-mobile`
              : `navitem-mobile`
          }
          onClick={() => changeOption("user-guide")}
        >
          <p>
            <InfoOutlined />{" "}
            {language === "Hindi" ? "उपयोगकर्ता गाइड" : "User Guide"}
          </p>
        </div>
        <div className="navitem-mobile">
          <img src={logoutIcon} style={{ width: "18px" }} alt="layers" />
          <button onClick={() => logout()}>
            {language === "Hindi" ? "लॉग आउट" : "Logout"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SliderNav;
