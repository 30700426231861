import { Button, Typography } from "@mui/material";
import React from "react";
import { muiStyles } from "../../Styles/muiStyles";
import "../../Styles/styles.css";

const AboutUs = ({ language }) => {
  return (
    <div id="about-us--container" className="container--flex bg--secondary">
      <div>
        <Typography
          sx={{
            fontSize: { xs: "22px", sm: "26px" },
            ...muiStyles.secondaryText,
          }}
        >
          {language === "Hindi" ? "हमारे बारे में" : "About Us"}
        </Typography>
      </div>
      <div>
        <Typography
          sx={{
            fontSize: "16px",
            ...muiStyles.secondaryText1,
            lineHeight: "2.5",
          }}
        >
          {language === "Hindi"
            ? "80% कैंसर रोगी और उनकी देखभाल करने वाले स्वास्थ्य रिकॉर्ड के सैकड़ों पृष्ठों की भौतिक प्रतियों के प्रबंधन में काफी समय खर्च करते हैं। हमारा मानना है कि आपकी कैंसर उपचार यात्रा पर नियंत्रण रखने की दिशा में पहला कदम डिजीटल, व्यवस्थित स्वास्थ्य रिकॉर्ड रखने से शुरू होता है। हम आपके स्वास्थ्य रिकॉर्ड का ध्यान रखते हैं ताकि आप इस बात का ध्यान रख सकें कि सबसे महत्वपूर्ण क्या है, अपना और अपने प्रियजनों का ख्याल रखना"
            : "80% of cancer patients and their caregivers spend consideration amounts of time in managing physical copies of hundreds of pages of health records.We believe that the first step towards taking control of your cancer treatment journey begins with having digitized, organized health records. We take care of your health records so that you can take care of what matters the most, looking after yourself and your loved ones"}
        </Typography>
      </div>
      <div id="getting-started"></div>
    </div>
  );
};

export default AboutUs;
