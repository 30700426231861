import { Box, Button, Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import { muiStyles } from "../../../Styles/muiStyles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const Filters = (props) => {
  const {
    language,
    selectedFilters,
    handleFilterCheckbox,
    isDurationMonth,
    setIsDurationMonth,
    setFilterDates,
    filterDates,
    handleFilterBox,
    changeAppliedFilters,
  } = props;
  return (
    <>
      <div className="opinion-filter-container">
        <div>
          <h6>{language === "Hindi" ? "स्थिति चुनें" : "Choose Status"}</h6>
          <div className="answer-filter">
            <FormControlLabel
              label={language === "Hindi" ? "अनुत्तरित" : "Unanswered"}
              control={
                <Checkbox
                  size="small"
                  sx={{
                    "&.Mui-checked": {
                      color: "#30F26A",
                    },
                  }}
                  checked={selectedFilters.includes("Opinion Requested")}
                  onClick={(e) => handleFilterCheckbox(e, "Opinion Requested")}
                />
              }
              sx={{ color: "#707070" }}
            />
            <FormControlLabel
              label={language === "Hindi" ? "उत्तरित" : "Answered"}
              control={
                <Checkbox
                  size="small"
                  sx={{
                    "&.Mui-checked": {
                      color: "#30F26A",
                    },
                  }}
                  checked={selectedFilters.includes("Resolved")}
                  onClick={(e) => handleFilterCheckbox(e, "Resolved")}
                />
              }
              sx={{ color: "#707070" }}
            />
          </div>
        </div>
        <div>
          <h6 style={{ marginTop: "15px" }}>{language === "Hindi" ? "अवधि चुनें" : "Select Duration"}</h6>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "wrap",
              mt: "20px",
            }}
          >
            {/* Selected Button is shown with a border based on the condition */}
            {!isDurationMonth ? (
              <p
                style={{
                  cursor: "pointer",
                  marginRight: "10px",
                }}
                onClick={() => setIsDurationMonth(true)}
              >
                {language === "Hindi" ? "महीना" : "Month"}
              </p>
            ) : (
              <Button
                variant="text"
                sx={{
                  ...muiStyles.SelectedButton,
                  mr: "10px",
                }}
                onClick={() => setIsDurationMonth(true)}
              >
                {language === "Hindi" ? "महीना" : "Month"}
              </Button>
            )}

            {/* Selected Button is shown with a border based on the condition */}
            {!isDurationMonth ? (
              <Button
                variant="text"
                sx={{
                  ...muiStyles.SelectedButton,
                }}
                onClick={() => setIsDurationMonth(false)}
              >
                {language === "Hindi" ? "साल" : "Year"}
              </Button>
            ) : (
              <p
                style={{ cursor: "pointer" }}
                onClick={() => setIsDurationMonth(false)}
              >
                {language === "Hindi" ? "साल" : "Year"}
              </p>
            )}
            <div
              className={"gray-div inline-block"}
              style={{
                margin: "0 0 0 10px",
                padding: "0",
                display: "inline-block",
              }}
            >
              <Button
                variant="text"
                sx={{ textTransform: "capitalize", color: "#6E326E" }}
                onClick={() => {
                  setFilterDates({
                    startMonth: "",
                    endMonth: "",
                    startYear: "",
                    endYear: "",
                  });
                }}
              >
                {language === "Hindi" ? "साफ़ करें" : "Clear"}
              </Button>
            </div>
          </Box>

          {!isDurationMonth ? (
            <div className="opinion-filters">
              <div className="filter-opinion-container">
                <label className="filter-input-label">From</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="filter-input"
                    value={filterDates.startYear}
                    onChange={(value) => {
                      setFilterDates({
                        ...filterDates,
                        ["startYear"]: value,
                      });
                    }}
                    sx={{ width: "5rem" }}
                    views={["year"]}
                    slotProps={{
                      textField: { size: "small" },
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div className="filter-opinion-container">
                <label className="filter-input-label">To</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="filter-input"
                    value={filterDates.endYear}
                    onChange={(value) => {
                      setFilterDates({
                        ...filterDates,
                        ["endYear"]: value,
                      });
                    }}
                    sx={{ width: "5rem" }}
                    views={["year"]}
                    slotProps={{
                      textField: { size: "small" },
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
          ) : (
            <div className="opinion-filters">
              <div className="filter-opinion-container">
                <label className="filter-input-label">From</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="filter-input"
                    value={filterDates.startMonth}
                    onChange={(value) => {
                      setFilterDates({
                        ...filterDates,
                        ["startMonth"]: value,
                      });
                    }}
                    sx={{ width: "11rem" }}
                    views={["month", "year"]}
                    slotProps={{
                      textField: { size: "small" },
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div className="filter-opinion-container">
                <label className="filter-input-label">To</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="filter-input"
                    value={filterDates.endMonth}
                    onChange={(value) => {
                      setFilterDates({
                        ...filterDates,
                        ["endMonth"]: value,
                      });
                    }}
                    sx={{ width: "11rem" }}
                    views={["month", "year"]}
                    slotProps={{
                      textField: { size: "small" },
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="filter-button-group white-bg">
        <button id="apply-button" onClick={() => changeAppliedFilters()}>
          {language === "Hindi" ? "फ़िल्टर लागू करें" : "Apply Filters"}
        </button>
        <button id="cancel-button" onClick={handleFilterBox}>
          {language === "Hindi" ? "रद्द करें" : "Cancel"}
        </button>
      </div>
    </>
  );
};

export default Filters;
