// Table Styles
const tableContainer = {
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  boxShadow: "0px 1px 15px #00000029",
  borderRadius: "10px",
  mt: "30px",
  overflowX: "auto",
};

const tableHeader = {
  backgroundColor: "#6e326e",
  fontSize: "16px",
  fontWeight: "600",
  borderBottom: "none",
  whiteSpace: "nowrap",
};

const tableRow = {
  borderBottom: "none",
  py: "5px",
  px: "18px",
  whiteSpace: "nowrap",
};

const tableCell = {
  backgroundColor: "#FFFFFF",
  textAlign: "left",
  fontSize: "14px",
  borderBottom: "none",
  padding: "5px 0 5px 20px",
};

const tableCellGrey = {
  backgroundColor: "#F5F5F5",
  textAlign: "left",
  fontSize: "14px",
  borderBottom: "none",
  padding: "5px 0 5px 20px",
};

const tableHeaderCell = {
  color: "#FFFFFF",
};

const tableButton = {
  border: "2px solid #6e326e",
  color: "#000000",
  textTransform: "capitalize",
  px: "20px",
  borderRadius: "8px",
  "&:hover": {
    border: "2px solid #6e326e",
    color: "#000000",
  },
};

const outlinedButton = {
  color: "#6e326e",
  border: "1px solid #6e326e",
  textTransform: "capitalize",
  "&:hover": {
    color: "#6e326e",
    border: "1px solid #6e326e",
  },
};

const tableSortArrow = {
  color: "#FFFFFF",
  fontSize: "18px",
};

export const muiStyles = {
  tableContainer,
  tableHeader,
  tableHeaderCell,
  tableRow,
  tableCell,
  tableCellGrey,
  tableButton,
  outlinedButton,
  tableSortArrow,
};
