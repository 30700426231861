import React, { useEffect, useState } from "react";
import { cancerReportType } from "../../../Services/patients";
import "./styles.css";
import { Checkbox, FormControlLabel } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const Filters = (props) => {
  const {
    language,
    handleFilterBox,
    selectedFilters,
    handleFilterCheckbox,
    filterDates,
    setFilterDates,
  } = props;
  const [rawInvestigationImageFilters, setRawInvestigationImageFilters] =
    useState([]);
  const [rawInvestigationReportFilters, setRawInvestigationReportFilters] =
    useState([]);
  const [rawTreatmentFilters, setRawTreatmentFilters] = useState([]);
  const [isInvestigationFilter, setIsInvestigationFilter] = useState(true);

  const investigationImageFilters = [];
  const investigationReportFilters = [];
  const treatmentFilters = [];
  rawInvestigationImageFilters.forEach((item) =>
    investigationImageFilters.push(item.value)
  );
  rawInvestigationReportFilters.forEach((item) =>
    investigationReportFilters.push(item.value)
  );
  rawTreatmentFilters.forEach((item) => treatmentFilters.push(item.value));

  const handleTreatmentButtonChange = () => {
    setIsInvestigationFilter(false);
  };

  const handleInvestigationButtonChange = () => {
    setIsInvestigationFilter(true);
  };

  useEffect(() => {
    const getReportTypes = async () => {
      try {
        const res = await cancerReportType();
        setRawInvestigationImageFilters(res.data.data[0].children[0].children);
        setRawInvestigationReportFilters([
          ...res.data.data[0].children[1].children,
          ...res.data.data[0].children[2].children,
        ]);
        setRawTreatmentFilters(res.data.data[1].children);
      } catch (error) {
        // console.log(error);
      }
    };
    getReportTypes();
  }, []);

  return (
    <>
      {/* <FormControlLabel
        // className="filters"
        sx={{ ml: "1px" }}
        label={language === "Hindi" ? "सभी का चयन करें" : "Select All"}
        control={
          <Checkbox
            size="small"
            sx={{
              "&.Mui-checked": {
                color: "#30F26A",
                height: "50%",
              },
            }}
            checked={
              investigationImageFilters.every((v) =>
                selectedFilters.includes(v)
              ) &&
              investigationReportFilters.every((v) =>
                selectedFilters.includes(v)
              ) &&
              treatmentFilters.every((v) => selectedFilters.includes(v))
            }
          />
        }
        onClick={(e) => {
          selectAllSpecificFilters(e, [
            ...investigationImageFilters,
            ...investigationReportFilters,
            ...treatmentFilters,
          ]);
        }}
      /> */}

      <h6 style={{ margin: "0 0 10px 20px", fontWeight: "600" }}>
        {language === "Hindi" ? "अवधि चुनें" : "Select Duration"}
      </h6>

      <div className="mobile-filter-input-duration">
        {/* <label className="filter-input-label">From</label> */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className="filter-input"
            value={filterDates.startMonth}
            onChange={(value) => {
              // if (value.year() >= 0 && value.year() < 10000)
              setFilterDates({
                ...filterDates,
                ["startMonth"]: value,
              });
              // console.log(filterDates["endMonth"]);
            }}
            label="From"
            sx={{ width: "10rem" }}
            views={["month", "year"]}
            slotProps={{ textField: { size: "small" } }}
          />
        </LocalizationProvider>
        {/* </div>
              <div className="filter-input-container"> */}
        {/* <label className="filter-input-label">To</label> */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className="filter-input"
            value={filterDates.endMonth}
            onChange={(value) => {
              // if (value.year() >= 0 && value.year() < 10000)
              setFilterDates({
                ...filterDates,
                ["endMonth"]: value,
              });
              // console.log(filterDates["endMonth"]);
            }}
            label="To"
            sx={{ width: "10rem" }}
            views={["month", "year"]}
            slotProps={{ textField: { size: "small" } }}
          />
        </LocalizationProvider>

        <button
          style={{ background: "none", border: "none", color: "#6e326e" }}
          onClick={() => {
            setFilterDates({
              startMonth: "",
              endMonth: "",
              startYear: "",
              endYear: "",
            });
          }}
        >
          {language === "Hindi" ? "साफ़ करें" : "Clear"}
        </button>
      </div>
      <div className="filter-button-group">
        <button
          className={
            isInvestigationFilter ? "clicked-button" : "disabled-button"
          }
          onClick={handleInvestigationButtonChange}
        >
          Investigation
        </button>
        <button
          className={
            isInvestigationFilter ? "disabled-button" : "clicked-button"
          }
          onClick={handleTreatmentButtonChange}
        >
          Treatment
        </button>
      </div>
      {isInvestigationFilter ? (
        <div className="filter-mobile-container">
          <h6>Images</h6>
          {investigationImageFilters?.map((item, index) => (
            <FormControlLabel
              label={item}
              key={index}
              sx={{ height: "50%" }}
              control={
                <Checkbox
                  size="small"
                  sx={{
                    "&.Mui-checked": {
                      color: "#30F26A",
                      height: "50%",
                    },
                  }}
                  checked={selectedFilters.includes(item)}
                />
              }
              onClick={(e) => handleFilterCheckbox(e, item)}
            />
          ))}
          <h6 style={{ marginTop: "20px" }}>Reports</h6>
          {investigationReportFilters?.map((item, index) => (
            <FormControlLabel
              label={item}
              key={index}
              control={
                <Checkbox
                  size="small"
                  sx={{
                    "&.Mui-checked": {
                      color: "#30F26A",
                    },
                  }}
                  checked={selectedFilters.includes(item)}
                />
              }
              onClick={(e) => handleFilterCheckbox(e, item)}
            />
          ))}
        </div>
      ) : (
        <div className="filter-mobile-container">
          <h6>Images</h6>
          {treatmentFilters?.map((item, index) => (
            <FormControlLabel
              label={item}
              key={index}
              control={
                <Checkbox
                  size="small"
                  sx={{
                    "&.Mui-checked": {
                      color: "#30F26A",
                    },
                  }}
                  checked={selectedFilters.includes(item)}
                />
              }
              onClick={(e) => handleFilterCheckbox(e, item)}
            />
          ))}
        </div>
      )}
      <div className="filter-button-group white-bg">
        {/* <button id="apply-button" onClick={() => changeAppliedFilters()}>
          {language === "Hindi" ? "फ़िल्टर लागू करें" : "Apply Filters"}
        </button> */}
        <button id="cancel-button" onClick={handleFilterBox}>
          {language === "Hindi" ? "बंद करें" : "Close"}
        </button>
      </div>
    </>
  );
};

export default Filters;
