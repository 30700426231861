import React, { useEffect, useState} from 'react';

import './index.css';

 const Flash = ({flash_message="",flass_type="",parentCallback}) => {
    let [visibility, setVisibility] = useState(true);
    let [message, setMessage] = useState('');
    let [type, setType] = useState('');

    useEffect(() => {
        
        setMessage(flash_message);
        setType(flass_type);
        setVisibility(true);
        // setTimeout(() => {
        //     setVisibility(false);
        // }, 4000);    
        
    }, [flash_message,flass_type]);
    
    const onClose =(e) =>{
        e.preventDefault();
        parentCallback(null);
    }
    return (
        visibility && <div className={`alert alert-${type}`}>
                <span className="close" onClick={(e)=>onClose(e)}><strong>X</strong></span>
                {message}
            </div>
    )
}
export default Flash;