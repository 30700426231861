import { Button, Typography } from "@mui/material";
import React from "react";
import upload from "../../Assets/upload.png";
import { muiStyles } from "../../Styles/muiStyles";
import "../../Styles/styles.css";
import "../../Styles/styles2.css";

const GettingStarted = ({ language }) => {
  return (
    <div className="container--flex">
      <div
        className="container--box1 container--flex"
        style={{ alignItems: "center" }}
      >
        <img src={upload} style={{ width: "350px", height: "335px" }} />
        <div
          className="container--flex container--col"
          style={{ textAlign: "left" }}
        >
          <Typography
            sx={{
              fontSize: { xs: "22px", sm: "26px" },
              ...muiStyles.secondaryText,
            }}
          >
            {language === "Hindi" ? "शुरू करना" : "Getting Started"}
          </Typography>
          <div>
            <Typography
              sx={{
                fontSize: { xs: "15px", sm: "18px" },
                ...muiStyles.primaryText1,
              }}
            >
              {language === "Hindi" ? "स्टेप 1" : "Step 1"}
            </Typography>
            <Typography sx={{ ...muiStyles.secondaryText1, mt: "10px" }}>
              {language === "Hindi"
                ? "शीर्ष दाएं कोने पर साइनअप बटन पर क्लिक करें।"
                : "Click the Signup button on top right corner."}
            </Typography>
            <Typography sx={{ ...muiStyles.secondaryText1, mt: "6px" }}>
              {language === "Hindi"
                ? "अपना खाता बनाने के लिए अपना विवरण और एक्सेस कोड भरें।"
                : "Fill in your details and access code to create your account."}
            </Typography>
          </div>
          <div>
            <Typography
              sx={{
                fontSize: { xs: "15px", sm: "18px" },
                ...muiStyles.primaryText1,
              }}
            >
              {language === "Hindi" ? "स्टेप 2" : "Step 2"}
            </Typography>
            <Typography sx={{ ...muiStyles.secondaryText1, mt: "10px" }}>
              {language === "Hindi"
                ? "आपको एक लिंक के साथ एक सत्यापन ईमेल प्राप्त होगा।"
                : "You will receive a verification email with a link."}
            </Typography>
            <Typography sx={{ ...muiStyles.secondaryText1, mt: "6px" }}>
              {language === "Hindi"
                ? "लिंक पर क्लिक करें और अपना खाता सत्यापित करें।"
                : "Click on the link and verify your account."}
            </Typography>
          </div>
          <div>
            <Typography
              sx={{
                fontSize: { xs: "15px", sm: "18px" },
                ...muiStyles.primaryText1,
              }}
            >
              {language === "Hindi" ? "स्टेप 3" : "Step 3"}
            </Typography>
            <Typography sx={{ ...muiStyles.secondaryText1, mt: "10px" }}>
              {language === "Hindi"
                ? "अपने यूज़रनेम और पासवर्ड के साथ लॉग इन करें।"
                : "Login with your username and password."}
            </Typography>
          </div>
          <div>
            <Typography
              sx={{
                fontSize: { xs: "15px", sm: "18px" },
                ...muiStyles.primaryText1,
              }}
            >
              {language === "Hindi" ? "स्टेप 4" : "Step 4"}
            </Typography>
            <Typography sx={{ ...muiStyles.secondaryText1, mt: "10px" }}>
              {language === "Hindi"
                ? "'फ़ाइलें चुनें' पर क्लिक करें और अपनी स्वास्थ्य रिपोर्ट चुनें।"
                : "Click 'Choose Files' and select your health reports."}
            </Typography>
            <Typography sx={{ ...muiStyles.secondaryText1, mt: "6px" }}>
              {language === "Hindi"
                ? "'फ़ाइलें जोड़ें' पर क्लिक करके अपनी स्वास्थ्य रिपोर्ट जोड़ें।"
                : "Add your health reports by clicking 'Add Files'."}
            </Typography>
            <Typography sx={{ ...muiStyles.secondaryText1, mt: "6px" }}>
              {language === "Hindi"
                ? "फिर अपनी स्वास्थ्य रिपोर्ट अपलोड करने के लिए 'अपलोड' पर क्लिक करें।"
                : "Then click 'Upload' to upload your health reports."}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GettingStarted;
