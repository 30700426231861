import { Box, Typography, Button, Input } from "@mui/material";
import OTPInput from "react-otp-input";
import React, { useState } from "react";
import { muiStyles } from "../Styles/muiStyles";
import { Col } from "react-bootstrap";
import { verifyOTP, resetPassword } from "../Services/patients";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const CreateNewPassword = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const email = JSON.parse(localStorage.getItem("email"));

  const handleVerifyOtp = async () => {
    try {
      if (!otp) alert("Please enter the OTP");
      const res = await verifyOTP(email, otp);
      if (res?.data?.error === false) {
        swal({ text: "OTP Verified!", icon: "success", timer: 1500 });
        setIsOtpVerified(true);
      }
    } catch (error) {
      swal({
        text: error?.response?.data?.message,
        icon: "error",
        timer: 1500,
      });
    }
  };

  const handleResetPassword = async () => {
    try {
      if (!password || !cPassword) alert("Please enter the necessary fields");
      else if (password !== cPassword) alert("The passwords don't match!");
      else {
        const res = await resetPassword(email, password, cPassword);
        if (res?.data?.error === false) {
          navigate("/patient/login");
          swal({
            text: "Password changed successfully!",
            icon: "success",
            timer: 2000,
          });
        }
      }
    } catch (error) {
      swal({
        text: error?.response?.data?.message,
        icon: "error",
        timer: 1500,
      });
    }
  };

  return (
    <div className="forgot-password-panel">
      <Col>
        <Typography
          component="h5"
          variant="h5"
          sx={{
            textAlign: "center",
          }}
        >
          RESET PASSWORD
        </Typography>
      </Col>
      <Col style={{ paddingRight: 0, paddingLeft: 0 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!isOtpVerified ? (
            <div className="forgot-password-container">
              <Typography variant="h6">Verify Code</Typography>
              <Typography variant="body2" sx={{ textAlign: "center" }}>
                A mail will be sent with verification code on your registered
                mail Id. Please enter the code here.
              </Typography>

              {/* To get the Input of OTP in seperate blocks */}
              <OTPInput
                inputStyle="otp-input"
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderInput={(props) => <input type="number" {...props} />}
              />
              <Button
                disabled={!otp}
                variant="contained"
                sx={muiStyles.primaryContainedButton}
                onClick={() => handleVerifyOtp()}
              >
                Submit
              </Button>
            </div>
          ) : (
            <div className="forgot-password-container">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  mb: "10px",
                }}
              >
                <Typography variant="body1" sx={{ mr: "27px" }}>
                  Create Password*
                </Typography>
                <Input
                  type="password"
                  disableUnderline={true}
                  sx={{
                    backgroundColor: "#f5f5f5",
                    borderRadius: "20px",
                    padding: "5px 10px",
                    width: "300px",
                  }}
                  placeholder="Enter New Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  mb: "10px",
                }}
              >
                <Typography variant="body1" sx={{ mr: "25px" }}>
                  Confirm Password*
                </Typography>
                <Input
                  type="password"
                  disableUnderline={true}
                  sx={{
                    backgroundColor: "#f5f5f5",
                    borderRadius: "20px",
                    padding: "3px 10px",
                    width: "300px",
                  }}
                  placeholder="Confirm New Password"
                  value={cPassword}
                  onChange={(e) => setCPassword(e.target.value)}
                />
              </Box>
              <Button
                variant="contained"
                sx={muiStyles.primaryContainedButton}
                onClick={() => handleResetPassword()}
              >
                Reset Password
              </Button>
            </div>
          )}
        </Box>
      </Col>
    </div>
  );
};

export default CreateNewPassword;
