import { Button, Typography } from "@mui/material";
import React from "react";
import { muiStyles } from "../../Styles/muiStyles";
import "../../Styles/styles.css";
import accessible from "../../Assets/accessible.png";
import digital from "../../Assets/digital.png";
import onePlace from "../../Assets/one_place.png";
import downloadable from "../../Assets/downloadable.png";
import timeline from "../../Assets/timeline.png";

const WhyOncoPHR = ({ language }) => {
  return (
    <div
      className="container--flex container--col bg--secondary"
    >
      <div>
        <Typography
          sx={{
            ...muiStyles.secondaryText,
            fontSize: {
              xs: "22px",
              sm: "26px",
            },
            mb: "20px"
          }}
        >
          {language === "Hindi" ? "ऑन्कोपीएचआर क्यों?" : "Why OncoPHR?"}
        </Typography>
      </div>
      <div>
        <div className="signUpFeatures">
          <div className="icon-text">
            <div className="icon-circle">
              <img
                src={accessible}
                style={{
                  padding: "25px",
                }}
              />
            </div>
            <p>
              {language === "Hindi"
                ? "आसान पहुंच के लिए व्यवस्थित किया गया"
                : "Organised for easy access"}
            </p>
          </div>
          <div className="icon-text">
            <div className="icon-circle">
              <img
                src={digital}
                style={{
                  padding: "25px",
                }}
              />
            </div>
            <p>
              {language === "Hindi"
                ? "सुरक्षित रूप से संग्रहीत"
                : "Stored securely"}
            </p>
          </div>

          <div className="icon-text">
            <div className="icon-circle">
              <img
                src={downloadable}
                style={{
                  padding: "25px",
                }}
              />
            </div>
            <p>
              {language === "Hindi"
                ? "आसानी से डाउनलोड करने योग्य"
                : "Easily Downloadable"}
            </p>
          </div>
          <div className="icon-text">
            <div className="icon-circle">
              <img
                src={onePlace}
                style={{
                  padding: "25px",
                }}
              />
            </div>
            <p>
              {language === "Hindi"
                ? "सभी रिकॉर्ड एक ही स्थान पर"
                : "All records in one place"}
            </p>
          </div>
        </div>
        <Typography
          sx={{
            fontSize: { xs: "20px", sm: "24px" },
            mt: "20px",
            ...muiStyles.primaryText1,
          }}
        >
          {language === "Hindi"
            ? "हम आपकी सहमति के बिना कभी भी आपकी व्यक्तिगत स्वास्थ्य रिपोर्ट किसी के साथ साझा नहीं करेंगे*"
            : "We will NEVER share your personal health reports with anyone without your consent*"}
        </Typography>
      </div>
      <div id="faq"></div>
    </div>
  );
};

export default WhyOncoPHR;
