import React from "react";
import { useNavigate } from "react-router-dom";
const Consent = () => {
  let navigate = useNavigate();
  // const [consent, setConsent]= useState(false);
  // const [consentError, setconsentError]= useState(false);
  // const handleChange = e => {
  //     let isChecked = e.target.checked;
  //     if (isChecked){
  //         setConsent(true);
  //         setconsentError(false);
  //         console.log("checked consent")
  //     } else {
  //         setConsent(false);
  //         setConsent(false)
  //         console.log("un checked consent")
  //     }
  // }
  // useLayoutEffect(() => {
  //     document.body.classList.add('pre_login');
  //     let userData= sessionStorage.getItem("userData");
  //     if(!userData){
  //         return navigate("/patient/login");
  //     }
  // }, []);

  const onGoback = (e) => {
    e.preventDefault();
    // console.log(`user data ${JSON.parse(sessionStorage.getItem("userData"))}`)
    // sessionStorage.removeItem("userData")
    // return navigate("/");
    return navigate("/patient/register");
  };
  // const onProcessed=async (e)=>{
  //     e.preventDefault();
  //     if(consent===true){
  //          try{
  //             await updateConsent();
  //             return navigate("/patient/dashboard");
  //          }catch(err){
  //             console.log(err);
  //             //alert(err.response.data.message)
  //          }
  //         //let userConsent={}
  //         //userConsent[JSON.parse(sessionStorage.getItem("userData"))._id]=true
  //         //sessionStorage.setItem("consentData", JSON.stringify({...consentData, ...userConsent }));

  //     }else{
  //         setconsentError(true);
  //     }

  // }

  return (
    <div
      className="consent-sec"
      style={{ minHeight: "100vh", margin: "0", padding: "5vw" }}
    >
      <div className="align-self-center consent-para">
        <h4>CONSENT AGREEMENT</h4>
        <p>
          I understand that my personal data may include, but not limited to the
          name, address, email, phone number, medical history, test results, and
          treatment information and other information provided by me.
        </p>
        <p>
          I, hereby give my consent to OncoPHR, its parent company, i3 Digital
          Health Pvt Ltd, its staff and affiliates and third-party service
          providers to collect, store, use, and process the personal data
          provided by me for the following purposes:
          <ol type="a">
            <li>
              Communication to enable effective delivery of products and
              services
            </li>
            <li>
              Personalization to optimize content and services offered to me,
              such as recommending products or services based on specific needs
              and requirements.
            </li>
            <li>
              Compliance with legal and regulatory requirements, such as to
              maintain detailed records of the services provided by OncoPHR.
            </li>
            <li>
              Keeping Medical Records to enable quick and easy retrieval of
              information of the patients.
            </li>
            <li>
              Analyzing the Medical Records to determine the most appropriate
              and optimum treatment options, specialists and services for the
              patient.
            </li>
            <li>
              Enhancing patient awareness and enabling decision making for the
              patient and caregivers.
            </li>
            <li>
              Supporting interoperability and continuity of care by
              independently and securely maintaining an archive of the Users'
              records.
            </li>
          </ol>
        </p>
        <p>
          I fully understand the risks, benefits, and alternatives involved in
          using OncoPHR's services and consent to it. I understand that my
          personal data and medical records will be kept confidential and
          protected in accordance with applicable laws and regulations in India
          and however, no data security measures can be guaranteed to be
          completely effective.
        </p>
        <p>
          I give my consent to receive one-time passwords (OTP) or other
          security codes to my registered mobile number for verification and
          authentication purposes. I understand that providing my mobile number
          is necessary to access the service, and that failure to do so may
          result in disruption of the service.
        </p>
        <p>
          I confirm that all the data I have provided, including personal
          details and medical information is accurate and complete, and I
          understand that any false or inaccurate information may affect the
          quality of the services or communications provided to me by OncoPHR.
        </p>
        <p>
          I understand that I have the right to receive a copy of this consent
          form and that I may revoke this consent at any time by sending an
          email to <a href="mailto:support@oncophr.com">support@oncophr.com</a>{" "}
          stating my intention to revoke the consent provided. I understand that
          withdrawing or revoking my consent may affect my ability to receive
          certain services or communications from OncoPHR.
        </p>
        <p>
          I have read and understood the{" "}
          <a href="/patient/terms-and-conditions" target={"_blank"}>
            {" "}
            Terms and Conditions
          </a>{" "}
          of using and availing services from OncoPHR and the{" "}
          <a href="/patient/privacy-policy" target={"_blank"}>
            {" "}
            Privacy Policy
          </a>{" "}
          of OncoPHR
        </p>

        {/* <div>
                    <form action="/action_page.php">
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="consent" name="consent" value="" onChange={e => handleChange(e)} />
                            <label className="form-check-label" htmlFor="check1">Yes, I provide my consent. {consentError}</label> <br/>
                            {consentError ===true && (
                                    <small className="text-danger">Please provide your consent</small>
                            )}
                        </div>
                    </form>
                </div> */}
        {/* <div className="view-term">
                    <p><a href="#">View terms and conditions</a></p>
                </div> */}
        {/* <div className="row">
          <div
            className="col-sm-12 text-center"
            style={{ textAlign: "center", display: "block" }}
          >
            <button className="doc-back" onClick={(e) => onGoback(e)}>
              Go Back
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default Consent;
