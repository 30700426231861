import { React } from "react";
import oncoLogo from "../../../Assets/oncoLogoWhite.png";
import messsage from "../../../Assets/white message.png";
import home from "../../../Assets/home.png";
import upload from "../../../Assets/white upload.png";
import profile from "../../../Assets/white profile 1.png";
import logoutIcon from "../../../Assets/white log-out.png";
import phoneIcon from "../../../Assets/purple_phone_icon.png";
import letterIcon from "../../../Assets/letter_icon.png";
import "./styles.css";
import { useNavigate } from "react-router-dom";

const LeftBar = (props) => {
  const { mainMenuOption, setMainMenuOption, logout, language } = props;
  const navigate = useNavigate();

  /**
   * Function to change screens on click of sidebar options
   * @param {*} option - screen
   */
  const changeOption = (option) => {
    setMainMenuOption(option);
    navigate(`/patient/dashboard/${option}`);
  };

  /**
   * Function to add or remove classes for contact-us-message-box
   */
  const showContactUsHandler = () => {
    //when box opens, "active" class is added to classlist
    const buttonClass =
      document.getElementsByClassName("contact-us-message")[0].classList;

    if (buttonClass.contains("active")) {
      buttonClass.remove("active");
    } else {
      buttonClass.add("active");
    }
  };

  return (
    <div className="left-bar">
      <div>
        {/* SIDEBAR HEADING */}
        <div className="logostyles">
          <img src={oncoLogo} alt="onco-phr-logo" className="nav-logo" />
        </div>

        {/* SIDE MENU ITEMS */}
        <div onClick={() => changeOption("myHealthFiles")}>
          <div
            className={
              mainMenuOption === "myHealthFiles"
                ? `navitem itemactive`
                : `navitem`
            }
          >
            <img src={home} alt="layers" className="nav-icon" />
            <p>
              {language === "Hindi"
                ? "मेरी स्वास्थ्य फ़ाइलें"
                : "My Health Files"}
            </p>
          </div>
        </div>

        <div onClick={() => changeOption("opinions")}>
          <div
            className={
              mainMenuOption === "opinions" ||
              mainMenuOption === "opinions/view"
                ? `navitem itemactive`
                : `navitem`
            }
          >
            <img src={messsage} alt="layers" className="nav-icon" />
            <p>{language === "Hindi" ? "राय" : "Opinions"}</p>
          </div>
        </div>

        <div onClick={() => changeOption("uploadedReports")}>
          <div
            className={
              mainMenuOption === "uploadedReports"
                ? `navitem itemactive`
                : `navitem`
            }
          >
            <img src={upload} alt="layers" className="nav-icon" />
            <p>
              {language === "Hindi"
                ? "अपलोड की गई रिपोर्टें"
                : "Uploaded Reports"}
            </p>
          </div>
        </div>
      </div>

      <div>
        <div onClick={() => changeOption("profile")}>
          <div
            className={
              mainMenuOption === "profile" ? `navitem itemactive` : `navitem`
            }
          >
            <img src={profile} style={{ width: "18px" }} alt="layers" />
            <p>{language === "Hindi" ? "प्रोफ़ाइल" : "Profile"}</p>
          </div>
        </div>
        <div onClick={() => logout()} className="navitem">
          <img src={logoutIcon} style={{ width: "18px" }} alt="layers" />
          <p>{language === "Hindi" ? "लॉग आउट" : "Logout"}</p>
        </div>

        {/* Bottom Footer on Left Bar */}
        <div className="sidebar-footer">
          <a href="/patient/terms-and-conditions" target="_blank">
            {language === "Hindi" ? "नियम एवं शर्तें" : "T&C"}
          </a>
          <a href="/patient/privacy-policy" target="_blank">
            {language === "Hindi" ? "गोपनीयता नीति" : "Privacy Policy"}
          </a>
          <div className="contact-us-container">
            <p onClick={showContactUsHandler}>
              {language === "Hindi" ? "संपर्क करें" : "Contact Us"}
            </p>
            <div className="contact-us-message">
              <div className="contact-flex-row">
                <img src={phoneIcon} alt="purple-phone-icon" />
                <p>+91 7483890478</p>
              </div>
              <div className="contact-flex-row">
                <img src={letterIcon} alt="purple-phone-icon" />
                <p>support@oncophr.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftBar;
