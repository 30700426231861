import { InfoOutlined } from "@mui/icons-material";
import React from "react";
import "./styles.css";
import { FormControl, MenuItem, Select } from "@mui/material";
import {
  getSessionStorage,
  setSessionStorage,
  useSessionStorage,
} from "../../../Services/patients";
import { useNavigate } from "react-router-dom";

const TopNavbar = () => {
  const navigate = useNavigate();
  const userData = useSessionStorage("userData");
  const language = getSessionStorage("language") || "English";

  /**
   * Function to change language
   * @param {*} event 
   */
  const handleLanguageChange = (event) => {
    event.preventDefault();
    setSessionStorage("language", event.target.value);

    window.location.reload();
  };

  return (
    <div className="top-navbar">
      <div className="welcome-text">
        <h5>
          {language === "Hindi" ? "स्वागत है, " : "Welcome, "}{" "}
          {userData?.first_name}
        </h5>
        <p>
          {" "}
          {language === "Hindi"
            ? "हम आपकी कैसे मदद कर सकते हैं?"
            : "How can we help you?"}
        </p>
      </div>

      {/* Language Selector */}
      <div className="navbar-options">
        <FormControl
          variant="standard"
          sx={{
            border: "none",
            mr: {
              xs: "5px",
              sm: "10px",
              md: "20px",
            },
          }}
        >
          <Select
            value={language}
            onChange={handleLanguageChange}
            className="select-lang"
            sx={{
              minWidth: {
                xs: "5px",
                sm: "10px",
              },
              fontFamily: "Proza Display",
              fontSize: "17px",
              px: {
                xs: "5px",
                sm: "15px",
              },
              "& input": {
                border: "none",
              },
            }}
          >
            <MenuItem
              value={"Hindi"}
              sx={{ fontFamily: "Proza Display", fontSize: "17px" }}
            >
              हिंदी
            </MenuItem>
            <MenuItem
              value={"English"}
              sx={{ fontFamily: "Proza Display", fontSize: "17px" }}
            >
              English
            </MenuItem>
          </Select>
        </FormControl>
        <button
          onClick={() => {
            navigate("/patient/dashboard/user-guide");
          }}
        >
          <InfoOutlined />{" "}
          {language === "Hindi" ? "उपयोगकर्ता गाइड" : "User Guide"}
        </button>
      </div>
    </div>
  );
};

export default TopNavbar;
