import { CancelOutlined, Delete, Close } from "@mui/icons-material";
import { Box, CircularProgress, IconButton, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./styles.css";
import swal from "sweetalert";
import {
  deleteReports,
  draftReoprts,
  reportsUpload,
  submitReports,
} from "../../Services/patients";
import pdfIcon from "../../Assets/pdf-icon.png";
import pngIcon from "../../Assets/png-icon.png";
import jpgIcon from "../../Assets/jpg-icon.png";

const iconForFileType = {
  "image/png": pngIcon,
  "image/jpeg": jpgIcon,
  "image/jpg": jpgIcon,
  "application/pdf": pdfIcon,
  png: pngIcon,
  jpeg: jpgIcon,
  jpg: jpgIcon,
  pdf: pdfIcon,
};

const AddReportsModal = (props) => {
  const { openAddReportsModal, setOpenAddReportsModal, language } = props;
  const [patientReports, setPatientReports] = useState({ reports: [] });
  const [loading, setLoading] = useState(false);
  const [loadingDraft, setLoadingDraft] = useState(false);
  const [draftPCR, setdraftPCR] = useState([]);
  const [phr_id, setPHRID] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(null);

  const handleClose = () => setOpenAddReportsModal(false);

  const loadDraftReports = async () => {
    setLoadingDraft(true);
    try {
      let userData = JSON.parse(sessionStorage.getItem("userData"));
      let resDraftReports = await draftReoprts(userData.token);
      // console.log(resDraftReports, "draft reports");
      setdraftPCR(resDraftReports?.data?.data?.patient_raw_reports.reverse());
      setPHRID(resDraftReports.data.data.id);
      setLoadingDraft(false);
    } catch (error) {
      // console.log(error, "loadDraftReports");
      swal({ text: error.response.data.message, icon: "error", timer: 1500 });
      // navigate("/");
      window.scrollTo(0, 0);
      setLoadingDraft(false);
    }
    setLoadingDraft(false);
  };

  const onInputChange = async (e) => {
    e.preventDefault();
    let temp = [...e.target.files];
    // console.log(patientReports, "patientReports");
    for (var i = 0; i < temp.length; i++) {
      if (
        temp[i].type !== "application/pdf" &&
        temp[i].type !== "image/png" &&
        temp[i].type !== "image/jpg" &&
        temp[i].type !== "image/jpeg"
      ) {
        swal({
          text:
            language === "Hindi"
              ? "केवल pdf/jpg/jpeg/png फाइलों की अनुमति है|"
              : "Only pdf/jpg/jpeg/png files are allowed",
          icon: "error",
          timer: 3000,
        });
        return;
      }
    }
    // console.log("here");
    setPatientReports({
      reports: [...patientReports?.reports, ...temp],
    });
    // console.log("here1");
    let reportsToUpload = {
      reports: [...patientReports?.reports, ...temp],
    };
    // console.log(reportsToUpload, "reportsToUpload");

    // e.preventDefault();
    // console.log(document.getElementById("reports").value, "get reports by id");
    // if (patientReports?.reports?.length === 0) {
    if (reportsToUpload?.reports?.length === 0) {
      swal({
        text:
          language === "Hindi"
            ? "कृपया एक फ़ाइल चुनें!"
            : "Please select a file!",
        icon: "error",
        timer: 1500,
      });
      window.scrollTo(0, 0);
      return null;
    }
    let fileError = false;
    let allowFileType = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "application/pdf",
    ];
    var formData = new FormData();
    // for (const key of Object.keys(patientReports.reports)) {
    //   if (!allowFileType.includes(patientReports.reports[key]["type"])) {
    for (const key of Object.keys(reportsToUpload?.reports)) {
      if (!allowFileType.includes(reportsToUpload?.reports[key]["type"])) {
        fileError = true;
      }
      // formData.append("reports", patientReports?.reports[key]);
      formData.append("reports", reportsToUpload?.reports[key]);
    }
    if (fileError) {
      swal({
        text:
          language === "Hindi" ? "केवल pdf स्वीकार्य है" : "Only pdf allowed",
        icon: "error",
        timer: 1500,
      });
      // reset the input
      document.getElementById("reports").value = "";
      window.scrollTo(0, 0);
      return null;
    }
    setLoading(true);
    try {
      let userData = JSON.parse(sessionStorage.getItem("userData"));
      // console.log(formData?.reports, "formdata", userData?.token, "token");
      let resData = await reportsUpload(formData, userData?.token);
      // console.log(resData, "reportsUpload data");
      const result = resData?.data?.data?.new_reports?.length;
      if (result) {
        await loadDraftReports();
        // console.log("success upload");
        setLoading(false);
      }
      setPatientReports({ reports: [] });
    } catch (error) {
      // console.log(error, "error");
      swal({
        text: error?.response?.data?.message,
        icon: "error",
        timer: 1500,
      });
      setLoading(false);
      window.scrollTo(0, 0);
      setPatientReports({ reports: [] });
    }
  };

  const getTypeOfFile = (item) => {
    const name = item.original_filename?.split(".");
    let type = "";
    if (name?.length) type = name[name?.length - 1];
    // console.log(iconForFileType[type], "item");
    return type;
  };

  const onDelete = async (e, file_id, file_key, file_name) => {
    e.preventDefault();
    // console.log(patientReports, "deletepatientreports");
    setLoadingDelete(file_id);
    let tempLoader = {};
    tempLoader[file_id] = true;
    setLoader({ ...loader, ...tempLoader });
    try {
      let userData = JSON.parse(sessionStorage.getItem("userData"));
      let resData = await deleteReports(file_id, file_key, userData.token);

      if (resData?.data.error) throw resData.data;
      // removing file from patientReports too
      setPatientReports({
        reports: patientReports?.reports?.filter(
          (report) => report.name !== file_name
        ),
      });

      loadDraftReports();
    } catch (error) {
      // console.log(error);
      tempLoader[file_id] = false;
      setLoader({ ...loader, ...tempLoader });
      swal({ message: error.message, icon: "error", timer: 1500 });
      window.scrollTo(0, 0);
    }
    setLoadingDelete(null);
  };

  const handleFinalSubmit = async (e) => {
    if (draftPCR?.length === 0) {
      swal({
        text:
          language === "Hindi"
            ? "कृपया एक फ़ाइल चुनें!"
            : "Please select a file!",
        icon: "error",
        timer: 1500,
      });
      window.scrollTo(0, 0);
      return null;
    }
    try {
      let userData = JSON.parse(sessionStorage.getItem("userData"));
      let resData = await submitReports(userData.token);
      // console.log(resData, "finalsubmite data");
      swal({
        text:
          language === "Hindi"
            ? "फ़ाइलें सफलतापूर्वक सबमिट की गईं|"
            : "Files Submitted successfully",
        icon: "success",
        timer: 1500,
      });
      setdraftPCR([]);
      setPatientReports({ reports: [] });
    } catch (error) {
      // console.log(error);
      swal({ text: error.response.data.message, icon: "error", timer: 1500 });
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    // let userData = sessionStorage.getItem("userData");
    loadDraftReports();
    // if (!userData) {
    //   return navigate("/");
    // }
  }, []);

  return (
    <Modal open={openAddReportsModal} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: "10px",
          px: "25px",
          py: "20px",
          width: {
            md: "40vw",
          },
        }}
      >
        <div className="report-title">
          <h5>Upload Reports</h5>
          <CancelOutlined onClick={handleClose} sx={{ cursor: "pointer" }} />
        </div>
        <div className="drag-box">
          {/* {patientReports?.reports?.length === 0 && ( */}
          {/* <p style={{ marginTop: "10px", color: "#ABABAB", fontSize: "14px" }}>
            {language === "Hindi"
              ? "अधिकतम फ़ाइल आकार: 1Mb"
              : "Maximum File Size: 1Mb"}
          </p> */}
          {/* )} */}
        </div>
        <div className="upload-button-box">
          <label
            for="file-upload"
            class="custom-file-upload"
            // onClick={() => setLoading(true)}
          >
            Browse
          </label>
          <input
            id="file-upload"
            type="file"
            multiple
            onChange={(e) => {
              onInputChange(e);
              // setLoading(false);
            }}
          />
          {loading ? (
            <button id="upload-button" disabled>
              Upload
            </button>
          ) : (
            <button id="upload-button" onClick={handleFinalSubmit}>
              Upload
            </button>
          )}
        </div>
        <div style={{ padding: "10px 0" }}>
          {loading ? (
            <div>
              {patientReports?.reports?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex-row"
                    style={{ paddingBottom: "10px" }}
                  >
                    <div style={{ display: "flex", gap: "5px" }}>
                      <img
                        src={iconForFileType[item.type]}
                        style={{ width: "30px", height: "30px" }}
                      />
                      <p style={{ fontSize: "14px" }}>{item?.name}</p>
                    </div>
                    {loading && (
                      <CircularProgress size="16px" color="inherit" />
                    )}
                  </div>
                );
              })}
              {draftPCR?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex-row"
                    style={{ paddingBottom: "10px" }}
                  >
                    <div style={{ display: "flex", gap: "5px" }}>
                      <img
                        src={iconForFileType[getTypeOfFile(item)]}
                        style={{ width: "30px", height: "30px" }}
                      />
                      <p style={{ fontSize: "14px" }}>
                        {item.original_filename}
                      </p>
                    </div>
                  </div>
                );
              })}{" "}
            </div>
          ) : (
            draftPCR?.map((item, index) => {
              return (
                <div key={index} className="flex-row">
                  <div style={{ display: "flex", gap: "5px" }}>
                    <img
                      src={iconForFileType[getTypeOfFile(item)]}
                      style={{ width: "30px", height: "30px" }}
                    />
                    <p style={{ fontSize: "14px" }}>{item.original_filename}</p>
                  </div>
                  {loadingDelete === item?._id ? (
                    <IconButton aria-label="delete">
                      <CircularProgress size="16px" color="inherit" />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="delete"
                      onClick={(e) =>
                        onDelete(
                          e,
                          item?._id,
                          item?.key,
                          item.original_filename
                        )
                      }
                    >
                      <Close />
                    </IconButton>
                  )}
                </div>
              );
            })
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default AddReportsModal;
