import axios from "axios";
import { useState, useEffect } from "react";

let base_url = process.env.REACT_APP_BACKEND_URL;

const userData = JSON.parse(sessionStorage.getItem("userData"));
const consentData = sessionStorage.getItem("consentData")
  ? JSON.parse(sessionStorage.getItem("consentData"))
  : "";

const register = async (patient) => {
  return await axios.post(`${base_url}/patients/signUp`, patient);
};
const loginPassword = async (patient) => {
  return await axios.post(`${base_url}/patients/loginPassword`, patient);
};
const loginOTP = async (patient) => {
  return await axios.post(`${base_url}/patients/loginOTP`, patient);
};
const forgotPassword = async (email) => {
  return await axios.patch(`${base_url}/patients/forgotPassword`, {
    email,
    role: "PATIENT",
  });
};
const verifyOTP = async (email, otp) => {
  return await axios.patch(`${base_url}/patients/verifyOtp`, {
    email,
    role: "PATIENT",
    otp,
  });
};
const resetPassword = async (email, password, cpassword) => {
  return await axios.patch(`${base_url}/patients/createNewPasswordPatient`, {
    role: "PATIENT",
    password,
    cpassword,
    email,
  });
};
const changePassword = async (
  email,
  oldPassword,
  newPassword,
  newCPassword,
  userToken
) => {
  return await axios.patch(
    `${base_url}/patients/changePassword`,
    { role: "PATIENT", email, oldPassword, newPassword, newCPassword },
    { headers: { Authorization: `Bearer ${userToken}` } }
  );
};
const otpSend = async (patient) => {
  return await axios.post(`${base_url}/patients/sendOTP`, patient);
};
const listDoctors = async () => {
  return await axios.get(`${base_url}/doctors/list`);
};
const listCancers = async () => {
  return await axios.get(`${base_url}/cancer_record/list`);
};
const logout = async (token, role) => {
  return await axios.get(`${base_url}/patients/logout/${role}`, {
    headers: { Authorization: `Bearer ${token ? token : null}` },
  });
};
const reportsUpload = async (data, token) => {
  return await axios.post(`${base_url}/patients/reportsUpload`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const getUploadedReports = async () => {
  return await axios.get(
    `${base_url}/patients/getPatientRawReports/${userData._id}/PATIENT`,
    { headers: { Authorization: `Bearer ${userData.token}` } }
  );
};

const viewOpinion = async (queryID, userToken) => {
  return await axios.get(
    `${base_url}/patients/getPatientQueries?_id=${queryID}`,
    { headers: { Authorization: `Bearer ${userToken}` } }
  );
};

const draftReoprts = async (token) => {
  return await axios.get(`${base_url}/patients/draftReports`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const getPreviouslySubmittedReports = async (token) => {
  return await axios.get(`${base_url}/patients/getPreviouslySubmittedReports`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const getFlaggedReports = async (token) => {
  return await axios.get(`${base_url}/patients/getFlaggedReports`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// https://0t38n05kd3.execute-api.ap-south-1.amazonaws.com/default/presigned_url

const getReportPages = async (token) => {
  return await axios.get(`${base_url}/patients/getFlaggedReports`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const submitReports = async (token) => {
  return await axios.put(
    `${base_url}/patients/submitReports`,
    { patient_comment: "" },
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

const deleteReports = async (file_id, file_key, token) => {
  return await axios.delete(
    `${base_url}/patients/deleteReports?file_id=${file_id}&file_key=${file_key}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

const accountDelete = async () => {
  return await axios.patch(
    `${base_url}/patients/delete`,
    {},
    { headers: { Authorization: `Bearer ${userData.token}` } }
  );
};
const updateReports = async (token, data) => {
  return await axios.put(`${base_url}/patients/updateReoprts`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const verifyEmail = async (id, role = "PATIENT") => {
  return await axios.get(`${base_url}/patients/verifyEmail/${id}/${role}`);
};

const resendVerificationLink = async (id, role = "PATIENT") => {
  return await axios.get(
    `${base_url}/patients/resendVerificationLink/${id}/${role}`
  );
};

const cancerReportType = async () => {
  ///let resPatient= await axios.get(`${base_url}/doctors/patientProfile?patinet_id=${patient_id}`,{ headers: {"Authorization" : `Bearer ${userData.token}`}} );
  const cancer_type = await JSON.parse(sessionStorage.getItem("userData"))
    .cancer_type;
  return await axios.get(
    `${base_url}/cancer_record/reportTypes?id=${cancer_type}`
  );
};
const curatedReoprtsList = async (filter) => {
  return await axios.post(
    `${base_url}/patient_reports/list/${userData._id}/PATIENT`,
    filter,
    { headers: { Authorization: `Bearer ${userData.token}` } }
  );
};

const sharedReportList = async (token) => {
  return await axios.get(
    `${base_url}/patients/patientToDocSharedReports/PATIENT`,
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

const updateConsent = async () => {
  const token = await JSON.parse(sessionStorage.getItem("userData")).token;
  return await axios.get(`${base_url}/patients/updateConsent`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
const shareReports = async (data, entity) => {
  const token = await JSON.parse(sessionStorage.getItem("userData")).token;
  return await axios.post(
    `${base_url}/patient_reports/share_reports/${entity}`,
    data,
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

const shareReportsToFamily = async (data) => {
  const token = await JSON.parse(sessionStorage.getItem("userData")).token;
  return await axios.post(
    `${base_url}/patient_reports/shareReportsFamilyMembers`,
    data,
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

const sendQuery = async (data) => {
  const token = await JSON.parse(sessionStorage.getItem("userData")).token;

  return await axios.post(`${base_url}/patients/askQuery`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// const sendDoctorQuery = async(data)=>{
//   const token = await JSON.parse(sessionStorage.getItem("userData")).token;
//   return await axios.post(`${base_url}/doctors/askQuery`,data,{ headers: {"Authorization" : `Bearer ${token}`}} );
// }

const getOpinions = async () => {
  const ret = await axios.get(`${base_url}/patients/opinions`, {
    headers: { Authorization: `Bearer ${userData.token}` },
  });
  return ret;
};

const changePrimaryDoctor = async (data, token) => {
  return await axios.put(
    `${base_url}/patients/revokeAccessAndChangePrimaryDoctor`,
    data,
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
const customDateFormat = (dateString, format = "dd/mm/yyyy") => {
  var formatedDate = "";
  var givenDate = new Date(dateString);

  switch (format) {
    case "dd/mm/yyyy":
      formatedDate = `${givenDate.getDate()}/0${givenDate.getMonth()}/${givenDate.getFullYear()}`;
      break;
    case "mm/dd/yyyy":
      formatedDate = `${givenDate.getMonth()}/0${givenDate.getDate()}/${givenDate.getFullYear()}`;
      break;
    default:
  }

  return formatedDate;
};

const parse_date = (dateItem) => {
  var dateObj = new Date(dateItem);
  var month = dateObj.getUTCMonth() + 1; //months from 1-12
  var day = dateObj.getUTCDate();
  var year = dateObj.getUTCFullYear();
  var l = `${month}`.length;
  var newdate = day + "/" + `${l < 2 ? "0" + month : month}` + "/" + year;
  return newdate;
};

const parse_time = (dateItem) => {
  let end = "am";
  let hours = new Date(dateItem).getHours().toString();
  let mins = new Date(dateItem).getMinutes().toString();
  // return `  ${hours.length<2 ? "0":""}${hours}:${mins.length===1 ? "0":""}${mins}`
  if (hours > 12) {
    hours = (hours - 12).toString();
    end = "pm";
  }
  return `  ${hours.length < 2 ? "0" : ""}${hours}:${
    mins.length === 1 ? "0" : ""
  }${mins}${end}`;
};

const max = (a, b) => {
  return a > b ? a : b;
};

const useSessionStorage = (name) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(JSON.parse(sessionStorage.getItem(name)));
  }, []);

  return value;
};

const getSessionStorage = (name) => {
  return JSON.parse(sessionStorage.getItem(name));
};

const setSessionStorage = (name, value) => {
  sessionStorage.setItem(name, JSON.stringify(value));
};

const delSessionStorage = (name) => {
  sessionStorage.removeItem(name);
};

const investigationImageFilters = [
  "X Ray",
  "Ultrasonography",
  "Mammogram",
  "CT",
  "PET CT",
];

/**
 * Report Based Investigation Filters
 */
const investigationReportFilters = [
  "Biopsy",
  "Histopathology",
  "Cytology",
  "Immunochemistry",
  "Blood Test Reports", // "Blood Test Report" changed to "Blood Test Reports"
  "Genomic Test",
  "Genetic Test",
  "2D Echo",
  "HLA typing", // HLA typing manually added by Ritapravo for filter testing purpose
  "Other Reports",
];

/**
 * Image based Treatment Filters
 */
const treatmentFilters = [
  "Surgical",
  "Medical",
  "Radiation",
  "Prescriptions",
  "Notes",
];

export {
  register,
  loginPassword,
  loginOTP,
  otpSend,
  listDoctors,
  listCancers,
  userData,
  logout,
  consentData,
  reportsUpload,
  draftReoprts,
  getPreviouslySubmittedReports,
  getFlaggedReports,
  submitReports,
  updateReports,
  deleteReports,
  getAge,
  customDateFormat,
  verifyEmail,
  resendVerificationLink,
  cancerReportType,
  curatedReoprtsList,
  sharedReportList,
  updateConsent,
  shareReports,
  base_url,
  getOpinions,
  shareReportsToFamily,
  sendQuery,
  parse_date,
  parse_time,
  getUploadedReports,
  max,
  setSessionStorage,
  useSessionStorage,
  getSessionStorage,
  delSessionStorage,
  accountDelete,
  forgotPassword,
  verifyOTP,
  resetPassword,
  changePrimaryDoctor,
  changePassword,
  investigationImageFilters,
  investigationReportFilters,
  treatmentFilters,
  viewOpinion,
};
