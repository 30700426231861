import { Box, Typography, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Image } from "react-bootstrap";
import { muiStyles } from "../Styles/muiStyles";
import fullArrow from "../Assets/full-arrow.png";
import { customDateFormat } from "../Services/doctors";
import { getSessionStorage, viewOpinion } from "../Services/patients";
import ViewReportModal from "./Modals/ViewReportModal";
import PatientNavbar from "./Navbar";
import "../Styles/styles.css";

const ViewOpinion = () => {
  const [opinion, setOpinion] = useState({});

  const Navigate = useNavigate();
  const params = useParams();
  const [viewReportItem, setViewReportItem] = useState("");

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const language = getSessionStorage("language");

  const { queryID } = params;

  const handleViewReport = (item, index) => {
    setViewReportItem(item);
  };

  const truncateOpinionReportName = (report_name) => {
    if (report_name.length <= 20) return report_name;
    return report_name.slice(0, 16) + "...";
  };

  useEffect(() => {
    const getOpinion = async () => {
      const res = await viewOpinion(queryID, userData.token);
      setOpinion(res?.data?.data[0]);
    };
    getOpinion();
  }, [queryID, userData.token]);

  /**
   * Function to open the previous queries screen
   */
  const handleReplyScreenChange = () => {
    //openPreviousReplies(false);
    return Navigate("/patient/dashboard/2");
  };

  return (
    <Container fluid style={{ padding: "0" }}>
      <PatientNavbar />
      <ViewReportModal
        viewReportItem={viewReportItem}
        setViewReportItem={setViewReportItem}
      />
      {/* Navbar */}
      <Box
        sx={{
          display: "flex",
          // flexDirection: "row",
          flexDirection: "column",
          mt: "10px",
          mx: "20px",
          // alignItems: "center",
          alignItems: "flex-start",
        }}
      >
        <Button variant="text" onClick={handleReplyScreenChange}>
          <Image className="arrow-image" src={fullArrow} />
        </Button>
        <Typography sx={{ ml: "15px", fontSize: "1.25rem" }}>
          <b>{language === "Hindi" ? "पिछले प्रश्न" : "Previous Queries"}</b>
        </Typography>
      </Box>

      {/* Patient Query */}
      {opinion && (
        <>
          <Box
            sx={{
              backgroundColor: "#78787840",
              borderRadius: "20px",
              p: "15px",
              my: "15px",
              mx: "30px",
            }}
          >
            <div className="query-header">
              <Typography sx={{ fontSize: "16px", color: "#6E326E" }}>
                <b>{customDateFormat(opinion?.dateOfQuery, "dd/mm/yyyy")}</b>
              </Typography>
              <div className="query-details">
                {opinion?.reportID?.map((report, index) => (
                  <Button
                    variant="outlined"
                    sx={{
                      py: "3px",
                      backgroundColor: "#ffffff",
                      borderColor: "#6E326E",
                      color: "#000000",
                      borderRadius: "6px",
                      "&:hover": {
                        borderColor: "#6E326E",
                      },
                      fontSize: "13px",
                      textTransform: "capitalize",
                      minWidth: "150px",
                      marginRight: "5px",
                    }}
                    onClick={() => handleViewReport(report, index)}
                  >
                    {truncateOpinionReportName(report?.saved_filename)}
                  </Button>
                ))}
              </div>
            </div>
            <Typography sx={{ textAlign: "left" }}>
              {opinion?.patientQuery}
            </Typography>
            <div className="opinion-section">
              <Typography
                sx={{ fontSize: "16px", color: "#6E326E", textAlign: "left" }}
              >
                <b>
                  {language === "Hindi"
                    ? "डॉक्टर की राय "
                    : "Doctor's Opinion "}{" "}
                  {`(${
                    opinion?.assignedDoctorID?.first_name +
                    " " +
                    opinion?.assignedDoctorID?.last_name
                  })`}
                </b>
              </Typography>
              <Typography sx={{ textAlign: "left" }}>
                {opinion?.DoctorOpinion}
              </Typography>
            </div>
          </Box>
          {opinion?.specificQueries?.length > 0 && (
            <Box
              sx={{
                backgroundColor: "#78787840",
                borderRadius: "20px",
                p: "15px",
                my: "15px",
                ml: {
                  xs: "50px",
                  sm: "60px",
                  md: "100px"
                },
                mr: "30px",
              }}
            >
              {opinion?.specificQueries
                ?.reverse()
                ?.map((specificQuery, index) => (
                  <>
                    <div className="query-header">
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "#6E326E",
                          textAlign: "left",
                        }}
                      >
                        <b>{specificQuery?.reportID?.saved_filename}</b>
                      </Typography>
                      <div className="query-details">
                        <Button
                          variant="contained"
                          sx={{
                            py: "3px",
                            ...muiStyles.primaryContainedButton,
                          }}
                          onClick={() =>
                            handleViewReport(specificQuery?.reportID, index)
                          }
                          style={{ marginRight: "5px" }}
                        >
                          {language === "Hindi"
                            ? "रिपोर्ट देखें"
                            : "View Report"}
                        </Button>
                      </div>
                    </div>
                    <Typography sx={{ textAlign: "left" }}>
                      {specificQuery?.specificQuery}
                    </Typography>
                    <div className="opinion-section">
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "#6E326E",
                          textAlign: "left",
                        }}
                      >
                        <b>
                          {language === "Hindi"
                            ? "डॉक्टर की राय "
                            : "Doctor's Opinion "}
                        </b>
                      </Typography>
                      <Typography sx={{ textAlign: "left" }}>
                        {specificQuery?.specificOpinion}
                      </Typography>
                    </div>
                    <hr />
                  </>
                ))}
            </Box>
          )}
        </>
      )}
    </Container>
  );
};

export default ViewOpinion;
