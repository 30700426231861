import { Box, Button, Input, MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import {
  parse_date,
  useSessionStorage,
  listDoctors,
  changePassword,
} from "../../../Services/patients";
import { editProfile } from "../../../Services/doctors";
// import "../../../Styles/styles2.css";
import swal from "sweetalert";
import "./styles.css";
import phoneIcon from "../../../Assets/phone-call-black.png";
import profileIcon from "../../../Assets/profile-black.png";
import calenderIcon from "../../../Assets/calendar.png";
import mailIcon from "../../../Assets/mail.png";
import maleProfileIcon from "../../../Assets/male.png";
import addUserIcon from "../../../Assets/add-user.png";
import femaleProfileIcon from "../../../Assets/female.png";

const Profile = (props) => {
  const { language } = props;
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [editUserForm, setEditUserForm] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [currentPrimaryDoctor, setCurrentPrimaryDoctor] = useState({
    first_name: "",
    last_name: "",
    _id: "",
  });
  const [doctorList, setDoctorList] = useState([]);

  const userData = useSessionStorage("userData");

  /**
   * FUnction to get the list of doctors
   */
  const getDoctorsList = async () => {
    try {
      const doctorList = await listDoctors();
      setDoctorList(doctorList?.data?.data);
    } catch {}
  };

  /**
   * Function to edit profile fields
   */
  const handleEditProfile = async () => {
    try {
      const res = await editProfile(editUserForm, "PATIENT", userData.token);
      if (res?.data?.error === false) {
        sessionStorage.setItem("userData", JSON.stringify(editUserForm));
        swal({
          text:
            language === "Hindi"
              ? "प्रोफ़ाइल सफलतापूर्वक अपडेट की गई!"
              : "Profile Successfully Updated!",
          icon: "success",
          timer: 1500,
        });
        setShowEditProfile(false);
        setCurrentUser({ ...editUserForm });
      } else {
        swal({ text: res?.data?.message, icon: "error", timer: 1500 });
      }
    } catch (error) {
      if (error?.response?.data?.message?.code) {
        // if there is a mongo db error
        const duplicateFieldObject = Object.keys(
          error?.response?.data?.message?.keyPattern
        )[0]?.split("_");

        let duplicateField = "";
        duplicateFieldObject.map((item) => (duplicateField += `${item} `));

        swal({
          text: `${duplicateField}already exists.`,
          icon: "error",
          timer: 1500,
        });
      } else {
        swal({
          text: error?.response?.data?.message,
          icon: "error",
          timer: 1500,
        });
      }
    }
  };

  /**
   * Function to change Password
   */
  const handleChangePassword = async () => {
    try {
      if (!oldPassword || !newPassword || !confirmNewPassword) {
        swal({
          text:
            language === "Hindi"
              ? "सभी फ़ील्ड आवश्यक हैं!"
              : "All fields are required!",
          icon: "error",
          timer: 1500,
        });
      } else if (newPassword !== confirmNewPassword)
        swal({
          text:
            language === "Hindi"
              ? "पासवर्ड मेल नहीं खाते!"
              : "Passwords don't match!",
          icon: "error",
          timer: 1500,
        });
      else {
        const res = await changePassword(
          userData.email,
          oldPassword,
          newPassword,
          confirmNewPassword,
          userData.token
        );
        if (res?.data?.error) {
          throw res?.data;
        }
        if (res?.data?.error === false) {
          swal({
            text:
              language === "Hindi"
                ? "नया पासवर्ड सफलतापूर्वक बना दिया गया है!"
                : "New Password has successfully been created!",
            icon: "success",
            timer: 1500,
          });
        } else {
          swal({ text: res?.data?.message, icon: "error", timer: 1500 });
        }
        setIsChangePassword(false);
        setOldPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
      }
    } catch (error) {
      swal({
        text:
          language === "Hindi"
            ? "पुराना पासवर्ड सही नहीं हैं।"
            : "Old Password is not correct.",
        icon: "error",
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    if (userData && doctorList.length !== 0) {
      let temp_item = doctorList?.find(
        (item) => item._id === userData?.primary_doctor
      );
      setCurrentPrimaryDoctor({ ...temp_item });
      setCurrentUser({ ...userData });
      setEditUserForm({ ...userData });
    }
  }, [userData, doctorList]);

  useEffect(() => {
    getDoctorsList();
  }, []);

  return (
    <Box role="presentation" className="patient-details">
      <div className="flex-row padding-20-30 top-buttons">
        <div className="title1">
          {isChangePassword ? (
            <h5>
              {language === "Hindi" ? "पासवर्ड बदलें" : "Change Password"}
            </h5>
          ) : (
            <h5>{language === "Hindi" ? "प्रोफ़ाइल" : "Profile"}</h5>
          )}
        </div>

        {/* For Larger Screens */}
        {!isChangePassword ? (
          !showEditProfile ? (
            <div className="flex-row">
              <Button
                variant="contained"
                size="small"
                style={{
                  textTransform: "none",
                  backgroundColor: "#ffffff",
                  border: "1px solid #6E326E",
                  color: "#6E326E",
                  padding: "5px 25px",
                }}
                onClick={() => setIsChangePassword(true)}
              >
                <div className="text" style={{ fontSize: "15px" }}>
                  {language === "Hindi" ? "पासवर्ड बदलें" : "Change Password"}
                </div>
              </Button>
              <Button
                variant="contained"
                size="small"
                style={{
                  textTransform: "none",
                  backgroundColor: "#6E326E",
                  padding: "5px 25px",
                }}
                color="secondary"
                onClick={() => {
                  setShowEditProfile(true);
                }}
              >
                <div className="text1" style={{ fontSize: "15px" }}>
                  {language === "Hindi" ? "प्रोफ़ाइल संपादित करें" : "Edit"}
                </div>
              </Button>
            </div>
          ) : (
            <div className="flex-row" style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                size="small"
                style={{
                  textTransform: "none",
                  backgroundColor: "#6E326E",
                  padding: "5px 25px",
                }}
                color="secondary"
                onClick={() => {
                  handleEditProfile();
                }}
              >
                <div className="text1" style={{ fontSize: "15px" }}>
                  {language === "Hindi" ? "लागू करें" : "Save"}
                </div>
              </Button>
              <Button
                variant="contained"
                size="small"
                style={{
                  textTransform: "none",
                  backgroundColor: "#ffffff",
                  border: "1px solid #6E326E",
                  color: "#6E326E",
                  padding: "5px 25px",
                }}
                onClick={() => {
                  setShowEditProfile(false);
                  setEditUserForm({ ...currentUser });
                }}
              >
                <div className="text" style={{ fontSize: "15px" }}>
                  {language === "Hindi" ? "रद्द करें" : "Cancel"}
                </div>
              </Button>
            </div>
          )
        ) : (
          <></>
        )}
      </div>
      {/* If edit profile button is clicked, text fields will show up besides labels
        or the saved information will be displayed */}
      {!isChangePassword ? (
        !showEditProfile ? (
          <div>
            <div className="profile-info-container">
              {currentUser?.gender === "Female" ? (
                <img
                  src={femaleProfileIcon}
                  className="profile-image"
                  alt="female-avatar"
                />
              ) : (
                <img
                  src={maleProfileIcon}
                  className="profile-image"
                  alt="male-avatar"
                />
              )}
              <div className="flex-col padding-20-30">
                <h4>{`${currentUser?.first_name} ${currentUser?.last_name}`}</h4>
                <div className="flex-row1">
                  <div className="flex-col">
                    <p>PatientID</p>
                    <p className="greybox">{currentUser?.uniqueID}</p>
                  </div>
                  <div className="flex-col">
                    <p>ABHA No</p>
                    <p className="greybox">{currentUser?.abha}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-row1 padding-20-30 mobile-view">
              <div className="profile-info-box">
                <img
                  src={calenderIcon}
                  alt="date of birth"
                  className="profile-icon"
                />
                <div className="flex-col mobile-column">
                  <p className="title1">
                    {language === "Hindi" ? "जन्म तिथि" : "Date of Birth"}
                  </p>
                  <p className="info-text">
                    {currentUser?.date_of_birth
                      ? parse_date(currentUser?.date_of_birth)
                      : ""}
                  </p>
                </div>
              </div>
              <div className="profile-info-box">
                <img src={profileIcon} alt="gender" className="profile-icon" />
                <div className="flex-col mobile-column">
                  <p className="title1">
                    {language === "Hindi" ? "लिंग" : "Gender"}
                  </p>
                  <p className="info-text">{currentUser?.gender}</p>
                </div>
              </div>
            </div>
            <div className="flex-row1 padding-20-30 mobile-view">
              <div className="profile-info-box">
                <img
                  src={addUserIcon}
                  alt="primary doctor"
                  className="profile-icon"
                />
                <div className="flex-col mobile-column">
                  <p className="title1">
                    {language === "Hindi"
                      ? "प्राथमिक डॉक्टर"
                      : "Primary Doctor"}
                  </p>
                  <p className="info-text">
                    {currentPrimaryDoctor.first_name +
                      " " +
                      currentPrimaryDoctor.last_name}
                  </p>
                </div>
              </div>
              <div className="profile-info-box">
                <img
                  src={mailIcon}
                  alt="date of birth"
                  className="profile-icon"
                />
                <div className="flex-col mobile-column">
                  <p className="title1">
                    {language === "Hindi" ? "ईमेल आईडी" : "Email ID"}
                  </p>
                  <p className="info-text">{currentUser?.email}</p>
                </div>
              </div>
            </div>
            <div className="flex-row1 padding-20-30 mobile-view">
              <div className="profile-info-box">
                <img
                  src={phoneIcon}
                  alt="phone call"
                  className="profile-icon"
                />
                <div className="flex-col mobile-column">
                  <p className="title1">
                    {language === "Hindi" ? "फ़ोन नंबर" : "Phone Number"}
                  </p>
                  <p className="info-text">{currentUser?.phone_number}</p>
                </div>
              </div>
            </div>
            <div className="mobile-flex-row">
              <Button
                variant="contained"
                size="small"
                style={{
                  textTransform: "none",
                  backgroundColor: "#ffffff",
                  border: "1px solid #6E326E",
                  color: "#6E326E",
                  padding: "5px 25px",
                }}
                onClick={() => setIsChangePassword(true)}
              >
                <div className="text" style={{ fontSize: "15px" }}>
                  {language === "Hindi" ? "पासवर्ड बदलें" : "Change Password"}
                </div>
              </Button>
              <Button
                variant="contained"
                size="small"
                style={{
                  textTransform: "none",
                  backgroundColor: "#6E326E",
                  padding: "5px 25px",
                }}
                color="secondary"
                onClick={() => {
                  setShowEditProfile(true);
                }}
              >
                <div className="text1" style={{ fontSize: "15px" }}>
                  {language === "Hindi" ? "प्रोफ़ाइल संपादित करें" : "Edit"}
                </div>
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div className="flex-row1 padding-20-30 mobile-view">
              <div className="flex-col mobile-column">
                <label className="title1">
                  {language === "Hindi" ? "पहला नाम" : "First Name"}
                </label>
                <TextField
                  style={{ width: "100%", height: "30px" }}
                  InputProps={{ disableUnderline: true }}
                  className={"text_field_2"}
                  placeholder={""}
                  variant="standard"
                  value={editUserForm.first_name}
                  onChange={(e) => {
                    setEditUserForm({
                      ...editUserForm,
                      ["first_name"]: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="flex-col mobile-column">
                <label className="title1">
                  {language === "Hindi" ? "अंतिम नाम" : "Last Name"}
                </label>
                <TextField
                  style={{ width: "100%", height: "30px" }}
                  InputProps={{ disableUnderline: true }}
                  className={"text_field_2"}
                  placeholder={""}
                  variant="standard"
                  value={editUserForm.last_name}
                  onChange={(e) => {
                    setEditUserForm({
                      ...editUserForm,
                      ["last_name"]: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="flex-row1 padding-20-30 mobile-view">
              <div className="flex-col mobile-column">
                <label className="title1">
                  {language === "Hindi" ? "जन्म तिथि" : "Date of Birth"}
                </label>
                <TextField
                  style={{ width: "100%", height: "30px" }}
                  type="date"
                  InputProps={{ disableUnderline: true }}
                  className={"text_field_2"}
                  placeholder={""}
                  variant="standard"
                  value={editUserForm?.date_of_birth?.slice(0, 10)}
                  onChange={(e) => {
                    setEditUserForm({
                      ...editUserForm,
                      ["date_of_birth"]: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="flex-col mobile-column mobile-view">
                <label className="title1">
                  {language === "Hindi" ? "लिंग" : "Gender"}
                </label>
                <TextField
                  select
                  style={{ width: "100%", height: "30px" }}
                  InputProps={{ disableUnderline: true }}
                  className={"text_field_2"}
                  placeholder={""}
                  variant="standard"
                  value={editUserForm.gender}
                  onChange={(e) => {
                    setEditUserForm({
                      ...editUserForm,
                      ["gender"]: e.target.value,
                    });
                  }}
                >
                  {["Male", "Female"].map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
            <div className="flex-row1 padding-20-30 mobile-view">
              <div className="flex-col mobile-column">
                <label className="title1">
                  {language === "Hindi" ? "फ़ोन नंबर" : "Phone Number"}
                </label>
                <TextField
                  style={{ width: "100%", height: "30px" }}
                  InputProps={{ disableUnderline: true }}
                  className={"text_field_2"}
                  placeholder={""}
                  variant="standard"
                  value={editUserForm.phone_number}
                  onChange={(e) => {
                    setEditUserForm({
                      ...editUserForm,
                      ["phone_number"]: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="flex-col mobile-column">
                <label className="title1">
                  {language === "Hindi" ? "ABHA नंबर" : "ABHA No."}
                </label>
                <TextField
                  style={{ width: "100%", height: "30px" }}
                  InputProps={{ disableUnderline: true }}
                  className={"text_field_2"}
                  placeholder={""}
                  variant="standard"
                  value={editUserForm?.abha}
                  onChange={(e) => {
                    setEditUserForm({
                      ...editUserForm,
                      ["abha"]: e.target.value,
                    });
                  }}
                  // {...(errors.abha && {
                  //   error: true,
                  //   helperText: errors.abha,
                  // })}
                />
              </div>
            </div>

            <div className="mobile-flex-row" style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                size="small"
                style={{
                  textTransform: "none",
                  backgroundColor: "#6E326E",
                  padding: "5px 30px",
                }}
                color="secondary"
                onClick={() => {
                  handleEditProfile();
                }}
              >
                <div className="text1" style={{ fontSize: "15px" }}>
                  {language === "Hindi" ? "लागू करें" : "Save"}
                </div>
              </Button>
              <Button
                variant="contained"
                size="small"
                style={{
                  textTransform: "none",
                  backgroundColor: "#ffffff",
                  border: "1px solid #6E326E",
                  color: "#6E326E",
                  padding: "5px 25px",
                }}
                onClick={() => {
                  setShowEditProfile(false);
                  setEditUserForm({ ...currentUser });
                }}
              >
                <div className="text" style={{ fontSize: "15px" }}>
                  {language === "Hindi" ? "रद्द करें" : "Cancel"}
                </div>
              </Button>
            </div>
          </div>
        )
      ) : (
        <div className="flex-col padding-20-30">
          <div className="flex-col mobile-column" style={{ gap: "3px" }}>
            <p className="title1">
              {language === "Hindi" ? "पुराना पासवर्ड*" : "Old Password*"}
            </p>
            <Input
              type="password"
              disableUnderline={true}
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "10px",
                padding: "2px 10px",
                width: "320px",
              }}
              placeholder={
                language === "Hindi"
                  ? "पुराना पासवर्ड दर्ज करें"
                  : "Enter Old Password"
              }
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </div>
          <div className="flex-col mobile-column" style={{ gap: "3px" }}>
            <p className="title1">
              {language === "Hindi" ? "नया पासवर्ड*" : "New Password*"}
            </p>
            <Input
              type="password"
              disableUnderline={true}
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "10px",
                padding: "2px 10px",
                width: "320px",
              }}
              placeholder={
                language === "Hindi"
                  ? "नया पासवर्ड दर्ज करें"
                  : "Enter New Password"
              }
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="flex-col mobile-column" style={{ gap: "3px" }}>
            <p className="title1">
              {language === "Hindi"
                ? "पासवर्ड की पुष्टि करें*"
                : "Confirm Password*"}
            </p>
            <Input
              type="password"
              disableUnderline={true}
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "10px",
                padding: "2px 10px",
                width: "320px",
              }}
              placeholder={
                language === "Hindi"
                  ? "नए पासवर्ड की पुष्टि करें"
                  : "Confirm New Password"
              }
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
          </div>
          <div
            className="flex-row"
            style={{ textAlign: "center", margin: "10px 0" }}
          >
            <Button
              //   variant="contained"
              //   sx={{ mx: "20px", ...muiStyles.primaryContainedButton }}
              variant="contained"
              size="small"
              style={{
                textTransform: "none",
                backgroundColor: "#6E326E",
                padding: "5px 25px",
              }}
              color="secondary"
              onClick={handleChangePassword}
            >
              <div className="text1" style={{ fontSize: "15px" }}>
                {language === "Hindi" ? "पासवर्ड बदलें" : "Change Password"}
              </div>
            </Button>
            <Button
              //   variant="contained"
              //   sx={{ mx: "20px", ...muiStyles.secondaryContainedButton }}
              variant="contained"
              size="small"
              style={{
                textTransform: "none",
                backgroundColor: "#ffffff",
                border: "1px solid #6E326E",
                color: "#6E326E",
                padding: "5px 25px",
              }}
              onClick={() => setIsChangePassword(false)}
            >
              <div className="text" style={{ fontSize: "15px" }}>
                {language === "Hindi" ? "रद्द करें" : "Cancel"}
              </div>
            </Button>
          </div>
        </div>
      )}
    </Box>
  );
};

export default Profile;
