import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import { parse_date } from "../../../Services/patients";
import { AddCircleOutline } from "@mui/icons-material";
import Filters from "./Filters";
import filterIcon from "../../../Assets/filter-icon.png";

const MobileView = (props) => {
  const {
    language,
    reportList,
    setReportList,
    page,
    rowsPerPage,
    checkedItems,
    handleCheckbox,
    handleViewReport,
    setOpenAddReportsModal,
    setSelectedFilters,
    selectedFilters,
    setAppliedFilters,
    tempReportList,
    handleMonthFilter,
    selectAllSpecificFilters,
    filterDates,
    setFilterDates,
  } = props;

  /**
   * To smoothly open filter box
   */
  const handleFilterBox = () => {
    const filterClass =
      document.getElementsByClassName("mobile-filters")[0].classList;
    if (filterClass.contains("active")) {
      filterClass.remove("active");
    } else {
      filterClass.add("active");
    }
  };

  /**
   * Function to select and delect filters
   * @param {*} item - item selected
   */
  const handleFilterCheckbox = (e, item) => {
    if (e.target.checked === true) {
      setSelectedFilters([...selectedFilters, item]);
    } else {
      setSelectedFilters(selectedFilters.filter((i) => i !== item));
    }
  };

  /**
   * Function to apply filters
   */
  const changeAppliedFilters = () => {
    setAppliedFilters([...selectedFilters]);
    let temp = tempReportList.filter((i) => {
      return !selectedFilters.length
        ? true
        : selectedFilters.includes(i.report_type);
    });

    setReportList(handleMonthFilter(temp));
  };

  return (
    <div style={{ width: "100%" }}>
      {/* For Mobile View */}

      <h6 className="table-title">
        {language === "Hindi" ? "मेरी स्वास्थ्य फ़ाइलें" : "My Health Files"}
      </h6>

      <div className="filter-button-box">
        <button id="filter-button" onClick={handleFilterBox}>
          <img className="filter-icon" src={filterIcon} alt="filter-icon" />
          {language === "Hindi" ? "फ़िल्टर" : "Filters"}
        </button>
        <button
          id="mobile-add-button"
          onClick={() => setOpenAddReportsModal(true)}
        >
          <AddCircleOutline sx={{ mr: "5px" }} />
          {language === "Hindi" ? "रिपोर्ट जोड़ें" : "Add Reports"}
        </button>
        <div className="mobile-filters">
          <Filters
            language={language}
            handleFilterBox={handleFilterBox}
            selectedFilters={selectedFilters}
            handleFilterCheckbox={handleFilterCheckbox}
            changeAppliedFilters={changeAppliedFilters}
            selectAllSpecificFilters={selectAllSpecificFilters}
            filterDates={filterDates}
            setFilterDates={setFilterDates}
          />
        </div>
      </div>

      {reportList.length > 0 && (
        <div className="mobile-table">
          {reportList
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map((report, index) => (
              <div
                key={index}
                className={
                  index % 2 === 0
                    ? "report-list white-bg"
                    : "report-list grey-bg"
                }
              >
                <div className="report-flex">
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        sx={{
                          "&.Mui-checked": {
                            color: "#6e326e",
                          },
                        }}
                      />
                    }
                    checked={checkedItems?.includes(report)}
                    onClick={(e) => handleCheckbox(e, report)}
                    sx={{ m: 0 }}
                  />
                  <div className="mobile-report-details">
                    <h6>{report?.saved_filename}</h6>
                    <div className="bottom-report-text">
                      <p>{parse_date(report?.reportDate)}</p>
                      <p>{report?.report_type}</p>
                    </div>
                  </div>
                </div>

                <button onClick={() => handleViewReport(report, index + 1)}>
                  {language === "Hindi" ? "रिपोर्ट देखें" : "View"}
                </button>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default MobileView;
