import React from 'react'


const containerStyle = {
    padding: "1vw 2vw",
    height: "90vh",
}

const Container = (props) => {
  return (
    <div style={containerStyle}>
        {props.children}
    </div>
  )
}

export  {Container};