import React from "react";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  let navigate = useNavigate();

  //   useLayoutEffect(() => {
  //     document.body.classList.add("pre_login");
  //     let userData = sessionStorage.getItem("userData");
  //     if (!userData) {
  //       return navigate("/patient/login");
  //     }
  //   }, []);

  const onGoback = (e) => {
    e.preventDefault();
    return navigate("/patient/register");
  };

  return (
    <div
      className="consent-sec"
      style={{ minHeight: "100vh", margin: "0", padding: "5vw" }}
    >
      <div className="align-self-center consent-para">
        <h4>Privacy Policy</h4>
        <p>
          This privacy policy (“Policy”) describes our collection of personal
          information from users of our Website ("Website"), as well as all
          related applications, widgets, software, tools, and other services
          provided by us and on which a link to this Policy is displayed
          (collectively, together with the Website, our "Service"). This Privacy
          Policy outlines the information that OncoPHR collects from users of
          this website for the purpose of providing Services, how that
          information is used and disclosed, and the safety measures put in
          place by OncoPHR to secure and safeguard such information.
        </p>
        <p>
          The terms 'you' or 'your' or 'user', and all its grammatical
          variations, refer to the user or viewer of our Website.
        </p>
        <p>
          OncoPHR is dedicated to preserving the privacy of the users of its
          Website and is committed to protecting their Personal Data. It is
          important to carefully read and understand the purpose of collecting
          your sensitive, personal, and other information, as well as how that
          information will be used, disclosed, and shared. By requesting and
          consenting to the Services offered by OncoPHR, you also agree to allow
          OncoPHR and its affiliates to use, transfer, store and analyze the
          information for the purpose of providing the Services.
        </p>
        <p>
          Terms not defined herein will have the meaning ascribed to them in
          OncoPHR’s Terms and Conditions of Use for the Website. This Policy is
          subject to changes as and when required and you are required to read
          and understand the changes to the policy every time you avail services
          from the website.
        </p>

        <p>
          <b>1. Purpose</b>
        </p>
        <p>
          This policy is intended to make you aware of the following:
          <ol>
            <li>The details we may gather about you through this Website.</li>
            <li>
              The functions and purpose of cookies and related technologies on
              our Website.
            </li>
            <li>
              Our methods for protecting and utilizing the information we
              collect from and about you.
            </li>
            <li>
              The situations in which we may reveal your information to others.
            </li>
            <li>The times we may use your information to reach out to you.</li>
            <li>
              The steps you can take to ensure the accuracy and currency of the
              information we possess about you.
            </li>
          </ol>
        </p>

        <p>
          <b>2. Details Collected</b>
        </p>
        <p>
          <ol>
            <li>
              Information that we collect from you at registration or at any
              other time will vary depending upon the services you seek to
              engage in, and in this respect, if you choose to sign up for our
              Services, we shall collect your Personal Data and Sensitive
              Personal Data.
            </li>
            <li>
              In addition, we may collect demographic information such as
              information about your computer, hardware, software, platform,
              media, Internet Protocol (IP) address and connection, information
              about online activity such as feature usage and click paths, and
              other data that you may provide in surveys or while
              completing/updating your account information. We may combine
              demographic information with Personal Data to tailor our offerings
              or web pages to your preferences or interests.
            </li>
          </ol>
        </p>
        <p>
          <b>3. Personal Data and its use</b>
        </p>
        <p>
          <ol>
            <li>
              Personal Data refers to any information that can be used to
              identify you, but is not publicly accessible, including your name,
              age, address, email, phone number, or other contact details.
            </li>
            <li>
              We may gather your Personal Data through registration for our
              Services or when you voluntarily provide it or involuntarily by
              third party service providers. We may use your email or other
              contact information to communicate with you regarding your use of
              the Services.
            </li>
            <li>
              We may use Personal Data and other information received and
              collected from you to provide the Services you have requested, to
              enable you to access and post comments on the Website, or any part
              thereof, and to contact you upon your request and when otherwise
              necessary.
            </li>
            <li>
              We may also use Personal Data for auditing, research and analysis,
              and to operate and improve the Services.
            </li>
            <li>
              We may share aggregated non-Personal Data with third parties. With
              your consent, we may also share Personal Data with third parties
              in limited circumstances, including, but not limited to complying
              with legal process, preventing fraud or imminent harm, and
              ensuring the security of our network and services.
            </li>
          </ol>
        </p>
        <p>
          <b>4. Sensitive Personal Data</b>
        </p>
        <p>
          <ol>
            <li>
              Sensitive Personal Data (SPD) refers to personal information such
              as passwords, health conditions, medical records, biometric
              information, sexual orientation, and financial information like
              bank account details or credit card numbers. SPD collected by
              OncoPHR will not be shared with third parties without your
              explicit consent, unless otherwise specified in this Privacy
              Policy, the Terms of Use, a separate agreement between OncoPHR and
              you, or as required by law.
            </li>
            <li>
              You acknowledge that during the provision of Services, OncoPHR may
              receive SPD, either when you choose to avail the Services from us
              or upload your medical records to the Website. You consent to
              allowing OncoPHR and/or its service providers to store this SPD on
              the Website and disclose it according to this Privacy Policy. If
              the SPD you provide pertains to another person, you confirm that
              you have obtained their consent for the disclosure and transfer of
              SPD as outlined in this Policy, the Terms of Use, or any other
              agreement between us.
            </li>
            <li>
              It is important to note that the decision to share SPD with
              OncoPHR is entirely yours.
            </li>
            <li>
              As a part of providing Services, SPD along with the Personal Data
              may be shared with affiliates, third party service providers,
              doctors, health care providers and experts in the field and you
              agree to this transfer of SPD. Reasonable measures will be taken
              to inform these affiliates, third party service providers,
              doctors, health care providers and experts in the field to
              maintain confidentiality and security of the data transferred.
              However, no data security measures can be guaranteed to be
              completely effective. Consequently, we cannot ensure or warrant
              the security of any personal information or other information. You
              transmit information to us at your own risk.
            </li>
          </ol>
        </p>

        <p>
          <b>5. Cookies</b>
        </p>
        <p>
          <ol>
            <li>
              Cookies enable websites to retrieve this information when users
              revisit them to tailor the page content for each user based on
              data related to prior browsing experiences, habits, and
              preferences.
            </li>
            <li>
              We may partner with selected third-party vendors, such as Google
              Analytics, AWS etc to allow tracking technologies and remarketing
              services on the Website through the use of first-party cookies and
              third-party cookies to, among other things, analyze and track
              users' use of the Website, determine the popularity of certain
              content, and better understand online activity. By accessing the
              Site, you consent to the collection and use of this information by
              these third-party vendors. You are encouraged to review their
              privacy policy and contact them directly for responses to your
              questions. However, if you do not want any information to be
              collected, you can write to us at{" "}
              <a href="mailto:support@oncophr.com">support@oncophr.com</a>.
            </li>
          </ol>
        </p>

        <p>
          <b>6. Confidentiality and Security</b>
        </p>
        <p>
          <ol>
            <li>
              We use industry standard practices to protect your Personal Data
              and SPD both during transmission and storage. Our databases,
              stored on servers behind a firewall, are secured and access is
              limited to only those who need it for the provision of Services.
              Despite our efforts, no method of electronic storage is completely
              secure.
            </li>
            <li>
              Only experts, service providers, employees and anyone who assists
              us in providing services, will have access to your Personal Data
              and SPD.
            </li>
            <li>
              Third-party service providers help us deliver products,
              information, and services. We may provide them with information,
              including Personal Data, for that purpose. We do not intend to
              sell your Personally Identifiable Data (the data when used alone
              or with other relevant data, can identify an individual) to third
              parties without your consent. We take reasonable steps to work
              with third-party service providers having their own privacy policy
              for protection of your data. However, no data security measures
              can be guaranteed to be completely effective. Consequently, we
              cannot ensure or warrant the security of any personal information
              or other information. You transmit information to us at your own
              risk.
            </li>
            <li>
              If you choose to share your Personal Data and SPD with us, it may
              be transferred from your location to other countries solely for
              the purpose of providing you expert opinion service and as a
              result, the data may be stored on servers located in these
              countries.
            </li>
          </ol>
        </p>

        <p>
          <b>7. Accessing Third Party Websites</b>
        </p>
        <p>
          If you leave the Website by clicking on a link to a third-party site,
          you will be directed to a website not managed by us. Since we have no
          control over the actions of third parties, we cannot be held
          responsible for their use of your Personal Data and cannot ensure they
          will follow the same privacy and security practices as us. The privacy
          policies of these websites will govern the use of your Personal Data.
          So, if you visit a third-party website linked to our Website, review
          that website's privacy policy before providing any Personal Data.
        </p>

        <p>
          <b>8. Reaching out to you</b>
        </p>
        <p>
          We may reach out to you using the Personal Data you have given us:
          <ol>
            <li>To effectively deliver the services you have signed up for.</li>
            <li>
              For providing any information you have sought for and for further
              communication.
            </li>
            <li>
              To ask for your participation (optional) in surveys, opinion
              polls, etc. related to our services
            </li>
            <li>
              To inform you about new products and services if you have given
              your consent to receive such information.
            </li>
          </ol>
        </p>

        <p>
          <b>9. Intimating the change in control</b>
        </p>
        <p>
          If there's a change in ownership or control of OncoPHR, your Personal
          Data and SPD might be transferred. If this transfer leads to a
          significant change in the way your Personal Data and SPD is used,
          OncoPHR will notify you of the changes and the options available to
          you.
        </p>

        <p>
          <b>10. Accuracy of Data</b>
        </p>
        <p>
          <ol>
            <li>
              OncoPHR and its affiliates, including licensors, partners,
              consultants, suppliers, employees, and agents, do not guarantee
              the accuracy, reliability, usefulness, completeness, or timeliness
              of the information provided to you through the website.
            </li>
            <li>
              OncoPHR and its affiliates are not responsible for the accuracy,
              reliability, usefulness, completeness and timeliness of the data
              provided by you to avail our services. Consequently, you are
              advised not to rely solely on the information or any other
              services provided to you based on your data.
            </li>
            <li>
              If you provide us with your Personal Data and SPD, we strive to
              keep it up-to-date and accurate. If you need to make any changes
              to the SPD and Personal Data you have given us through our
              website, please reach out to us on our email{" "}
              <a href="mailto:support@oncophr.com">support@oncophr.com</a> and
              we will do our best to update it in a timely manner.
            </li>
          </ol>
        </p>

        <p>
          <b>11. Termination of User Account</b>
        </p>
        <p>
          If you choose to terminate your user account with OncoPHR or if your
          account is terminated for reasons like violation of the Terms of Use,
          OncoPHR will promptly delete and eliminate all Personal Data and SPD
          stored in your account on the Website. You understand and agree that
          general, non-identifying information may still be kept and displayed
          by OncoPHR as part of any research, surveys, or data compiled on the
          Website.
        </p>

        <p>
          <b>12. Consent to the Policy</b>
        </p>
        <p>
          By accessing this Website, you agree to the terms of this Privacy
          Policy and the way we collect, use, and manage your Personal Data and
          SPD as described. If there are any changes made to this Policy, we
          will take reasonable measures to make you aware of them by prominently
          displaying the changes on our Website for a reasonable time frame and
          your continued use of the website requires adherence to such
          amendments to the policy. Your use of the Website and any
          privacy-related disputes are governed by this Policy.
        </p>

        <p>
          <b>13. Applicable Law</b>
        </p>
        <p>
          This Policy and information contained herein, shall be governed by the
          laws of the Republic of India and the courts of Bengaluru, Karnataka,
          India shall retain exclusive jurisdiction to entertain any proceedings
          in relation to any disputes arising out of the same.
        </p>

        <p>
          <b>13. Reaching out to us</b>
        </p>
        <p>
          If you have any questions, comments, or concerns about this privacy
          policy or your personal information, please contact us at{" "}
          <a href="mailto:support@oncophr.com">support@oncophr.com</a>.
        </p>

        {/* <div className="row">
          <div
            className="col-sm-12 text-center"
            style={{ textAlign: "center", display: "block" }}
          >
            <button className="doc-back" onClick={(e) => onGoback(e)}>
              Go Back
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default PrivacyPolicy;
