import axios from "axios";

let base_url=process.env.REACT_APP_BACKEND_URL;

const  userData= JSON.parse(sessionStorage.getItem("userData"));
const  consentData= sessionStorage.getItem("consentData")? JSON.parse(sessionStorage.getItem("consentData")):'';

const register = async doctor => {
  doctor.date_of_birth=new Date(doctor.date_of_birth);
  return await axios.post(`${base_url}/doctors/signUp`, doctor);
};
const loginPassword = async doctor => {
  return await axios.post(`${base_url}/doctors/loginPassword`, doctor);
};
const loginOTP = async doctor => {
  return await axios.post(`${base_url}/doctors/loginOTP`,doctor);
};
const forgotPassword = async (email) => {
  return await axios.patch(`${base_url}/doctors/forgotPassword`, {email, role: 'DOCTOR'})
};
const verifyOTP = async (email, otp) => {
  return await axios.patch(`${base_url}/doctors/verifyOtp`, {email, role: 'DOCTOR', otp});
};
const resetPassword = async (email, password) => {
  return await axios.patch(`${base_url}/doctors/createNewPasswordDoctor`, {role: 'DOCTOR', password, email});
};
const changePassword = async (email, oldPassword, newPassword, userToken) => {
  return await axios.patch(`${base_url}/doctors/changePassword`, {role: 'DOCTOR', email, oldPassword, newPassword}, { headers: {"Authorization" : `Bearer ${userToken}`} });
}
const otpSend = async doctor => {
  return await axios.post(`${base_url}/doctors/sendOTP`,doctor);
};

const editProfile = async (profile, role, userToken) => {
  return await axios.patch(`${base_url}/doctors/editProfile/${role}`, profile, { headers: {"Authorization" : `Bearer ${userToken}`} })
}

const editPatientProfile = async (profile, role, patientID) => {
  return await axios.patch(`${base_url}/doctors/editPatientProfile/${patientID}/${role}`, profile, { headers: {"Authorization" : `Bearer ${userData.token}`} })
}

const logout = async (token, role) => {
  return await axios.get(`${base_url}/doctors/logout/${role}`,{ headers: {"Authorization" : `Bearer ${token}`} });
};
const sharedReportList =async(token)=>{
  return await axios.get(`${base_url}/doctors/docDashboard`,{ headers: {"Authorization" : `Bearer ${token}`} })
}

const patientReportList =async(patientID)=>{
  const token = await JSON.parse(sessionStorage.getItem("userData")).token;
  return await axios.get(`${base_url}/doctors/sharedReportList/${patientID}`,{ headers: {"Authorization" : `Bearer ${token}`} })
}

const sharingHistory =async(patientID, role)=>{
  return await axios.get(`${base_url}/doctors/docToDocSharedReports/${patientID}/${role}`,{ headers: {"Authorization" : `Bearer ${userData?.token? userData?.token : null }`} })
}

const verifyEmail= async(id)=>{
  return await axios.get(`${base_url}/doctors/verifyEmail/${id}`);
}

const updateConsent= async()=>{
  return await axios.get(`${base_url}/doctors/updateConsent`,{ headers: {"Authorization" : `Bearer ${userData.token}`}} );
}
const getPatient = async(patient_id) => {
  let userData = await JSON.parse(sessionStorage.getItem("userData"));
  return await axios.get(`${base_url}/doctors/patientProfile?patient_id=${patient_id}`,{ headers: {"Authorization" : `Bearer ${userData.token}`}} );
}

const shareReportsWithDoctor= async(data)=>{
  const token = await JSON.parse(sessionStorage.getItem("userData")).token;
  return await axios.post(`${base_url}/patient_reports/shareReportsDoctor`,data,{ headers: {"Authorization" : `Bearer ${token}`}} );
}

const cancerReportType= async(patient_id)=>{
  let resPatient= await getPatient(patient_id);
  return await axios.get(`${base_url}/cancer_record/reportTypes?id=${resPatient.data.data.cancer_type}`);

}
const curatedReoprtsList= async(formData)=>{
  let userData = await JSON.parse(sessionStorage.getItem("userData"));
  return await axios.post(`${base_url}/doctors/sharedReportsDoc`,formData,{ headers: {"Authorization" : `Bearer ${userData.token}`} });
}
const getOpinions= async(patient_id)=>{
  let userData = await JSON.parse(sessionStorage.getItem("userData"));
  return await axios.get(`${base_url}/doctors/getPatientQueries?askedBy=Patient&patientID=${patient_id}&assignedDoctorID=${userData._id}&status=Resolved`,{ headers: {"Authorization" : `Bearer ${userData.token}`} });
}

const getActiveOpinions= async(patient_id)=>{
  let userData = await JSON.parse(sessionStorage.getItem("userData"));
  return await axios.get(`${base_url}/doctors/getPatientQueries?patientID=${patient_id}&assignedDoctorID=${userData._id}&status=${"Opinion Requested"}`,{ headers: {"Authorization" : `Bearer ${userData.token}`} });
}

const postOpinion= async(formData)=>{
  let userData = await JSON.parse(sessionStorage.getItem("userData"));
  return await axios.post(`${base_url}/doctors/addOpinion`,formData,{ headers: {"Authorization" : `Bearer ${userData.token}`} });
}

const saveOpinion= async(formData)=>{
  let userData = await JSON.parse(sessionStorage.getItem("userData"));
  return await axios.post(`${base_url}/doctors/saveOpinion`,formData,{ headers: {"Authorization" : `Bearer ${userData.token}`} });
}

const getActiveQueries = async(patient_id, userId, askedBy, status, userToken) => {
  return await axios.get(`${base_url}/doctors/getPatientQueries?patientID=${patient_id}&assignedDoctorID=${userId}&askedBy=${askedBy}&status=${status}`,{ headers: {"Authorization" : `Bearer ${userToken}`} });
}

const getActiveQueriesforOpinionTab = async(patient_id, askedBy, status) => {
  return await axios.get(`${base_url}/doctors/getPatientQueries?patientID=${patient_id}&assignedDoctorID=${userData._id}&askedBy=${askedBy}&status=${status}`,{ headers: {"Authorization" : `Bearer ${userData.token}`} });
}

const getDoctorResolvedQueries = async(patient_id) => {
  return await axios.get(`${base_url}/doctors/getPatientQueries?patientID=${patient_id}&doctorID=${userData._id}&askedBy=Doctor&status=Resolved`,{ headers: {"Authorization" : `Bearer ${userData.token}`} });
}

const getQuery= async(shr_id)=>{
  return await axios.get(`${base_url}/patient_reports/getPatientQuery/${shr_id}`,{ headers: {"Authorization" : `Bearer ${userData.token}`} });
}

const sendDoctorQuery = async(data)=>{
  const token = await JSON.parse(sessionStorage.getItem("userData")).token;
  return await axios.post(`${base_url}/doctors/askQuery`,data,{ headers: {"Authorization" : `Bearer ${token}`}} );
}

const addOpinion= async(data)=>{
  return await axios.post(`${base_url}/doctors/addOpinion`,data, { headers: {"Authorization" : `Bearer ${userData.token}`} });
}

const accountDelete =async()=>{
  return await axios.patch(`${base_url}/doctors/delete`, {}, { headers: {"Authorization" : `Bearer ${userData.token}`} })
}

const getNotes= async(patientID, role)=>{
  return await axios.get(`${base_url}/doctors/getUserNotes/${patientID}/${role}`,{ headers: {"Authorization" : `Bearer ${userData.token}`} });
}
const addNotes= async(patientID, note, role)=>{
  return await axios.post(`${base_url}/doctors/createUserNotes/${patientID}/${role}`,{note}, { headers: {"Authorization" : `Bearer ${userData.token}`} });
}
const starPatient = async (patientID, isStarred, token) => {
  return await axios.patch(`${base_url}/doctors/starPatient`, {patientID, isStarred}, { headers: {"Authorization" : `Bearer ${token}`} })
}
const service_update_diagnosis= async(data)=>{
  return await axios.post(`${base_url}/doctors/updateDiagnosis`,data,{ headers: {"Authorization" : `Bearer ${userData.token}`}} );
}

const service_update_impression= async(data)=>{
  return await axios.post(`${base_url}/doctors/updateImpressions`,data,{ headers: {"Authorization" : `Bearer ${userData.token}`}} );
}

const getAge= (dateString)=> {
  var today = new Date();
  var birthDate = new Date(dateString);
  //alert(birthDate)
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }
  return age;
}
const customDateFormat= (dateString,format="dd/mm/yyyy")=> {
  var formatedDate=""
  var givenDate = new Date(dateString);

  switch(format) {
    case "dd/mm/yyyy":
      formatedDate= `${padTo2Digits(givenDate.getDate())}/${padTo2Digits(givenDate.getMonth()+1)}/${givenDate.getFullYear()}`;
      break;
    case "mm/dd/yyyy":
      formatedDate= `0${givenDate.getMonth()}/0${givenDate.getDate()+1}/${givenDate.getFullYear()}`;
      break;
    default:

  }



  return formatedDate;
}
function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export {
        register,loginPassword,loginOTP,otpSend,service_update_impression, service_update_diagnosis,
        userData,logout,consentData,sharedReportList,getNotes,addNotes,
        getAge,customDateFormat,verifyEmail,updateConsent,base_url,getPatient,cancerReportType,curatedReoprtsList,getOpinions,addOpinion, getQuery,
        sendDoctorQuery, getActiveOpinions, postOpinion, saveOpinion, editProfile, patientReportList, sharingHistory, getActiveQueries, starPatient,
        accountDelete, forgotPassword, verifyOTP, resetPassword, changePassword, shareReportsWithDoctor, getDoctorResolvedQueries,
        editPatientProfile,getActiveQueriesforOpinionTab
      }
