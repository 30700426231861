import {
  CancelOutlined,
  PersonOutlineOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Col, Image, Navbar, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { muiStyles } from "../Styles/muiStyles";
import {
  logout,
  accountDelete,
  parse_date,
  changePrimaryDoctor,
  setSessionStorage,
  useSessionStorage,
  listDoctors,
  changePassword,
  getSessionStorage,
} from "../Services/patients";
import navbarMenu from "../Assets/navbar-menu.svg";
import oncoLogo from "../Assets/oncoLogoWhite.png";
import { editProfile } from "../Services/doctors";
import "../Styles/styles2.css";
import swal from "sweetalert";

const personaliseNotificationItems = [
  "New Curated reports are added",
  "Reports are Flagged",
  "Pending Bills",
  "Opinions Resolved",
  "Password Reset",
  "Profile Edited",
  "Message from OncoPHR",
];

const PatientNavbar = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [tabsValue, setTabsValue] = useState(params.tab);
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const [settings, setSettings] = useState(false);
  const [profile, setProfile] = useState(false);
  const [notifications, setNotifications] = useState(false);
  const [state, setState] = useState({ right: false });
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [showPersonalisedNotification, setShowPersonalisedNotification] =
    useState(false);
  const [editUserForm, setEditUserForm] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [selectedPrimaryDoctor, setSelectedPrimaryDoctor] = useState({
    first_name: "",
    last_name: "",
    id: "",
  });
  const [currentPrimaryDoctor, setCurrentPrimaryDoctor] = useState({
    first_name: "",
    last_name: "",
    _id: "",
  });
  const [showChangePrimaryConsultant, setShowChangePrimaryConsultant] =
    useState(false);
  const [doctorList, setDoctorList] = useState([]);

  const [loader, setLoader] = useState({ loader1: false });
  const changeLoader = (name, value) => {
    setLoader({ ...loader, [name]: value });
  };

  // const language = getSessionStorage("language");
  const [language, setLanguage] = useState(
    getSessionStorage("language") || "English"
  );
  const handleLanguageChange = (event) => {
    // onClick={() => {
    setLanguage(event.target.value);
    setSessionStorage("language", event.target.value);
    window.location.reload();
    // }}
  };
  /**
   *
   * @param {String} anchor - position from where the drawer should slide in
   * @param {String} val - Options - Settings, Notifications and Profile
   * @param {Boolean} open
   * @returns {void}
   */
  const toggleDrawer = (anchor, val, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (val === "settings") {
      setSettings(true);
      setProfile(false);
      setNotifications(false);
      setIsChangePassword(false);
    } else if (val === "notifications") {
      setNotifications(true);
      setSettings(false);
      setProfile(false);
    } else {
      setProfile(true);
      setSettings(false);
      setNotifications(false);
      setShowEditProfile(false);
      setIsChangePassword(false);
    }
    setState({ ...state, [anchor]: open });
  };

  /**
   * Function to handle the Navbar Tabs within Patient Reports
   * @param {object} event
   * @param {string} newValue
   */
  const handleTabChange = (event, newValue) => {
    setTabsValue(newValue);
    props.sendTabValue(newValue);
    navigate(`/patient/dashboard/${newValue}`);
  };

  /**
   * Function to handle when click on MHF logo
   * @param {object} event
   */
  const handleLogoClick = (event) => {
    setTabsValue("0");
    props.sendTabValue("0");
    navigate(`/patient/dashboard/0`);
  };

  /**
   * Function to logout of the patient portal
   */
  const onLogout = async (e) => {
    e.preventDefault();
    try {
      let userData = JSON.parse(sessionStorage.getItem("userData"));
      //sessionStorage.removeItem("userData");
      let resData = await logout(userData?.token, "PATIENT");
      return navigate("/patient/login");
    } catch (error) {
      console.log(error);
      window.scrollTo(0, 0);
    }
  };

  /**
   * Function To change the Primary Doctor
   * @returns UI for changing the primary doctor
   */
  const handleSubmitChangePrimaryDoctor = async () => {
    if (selectedPrimaryDoctor._id === "")
      return swal({ text: "Primary doctor not selected", icon: "error" });
    changeLoader("loader1", true);
    const doctor_data = { doctorID: selectedPrimaryDoctor._id };
    try {
      const res = await changePrimaryDoctor(doctor_data, userData.token);

      swal({ icon: "success", text: await res?.data?.message });
      setSessionStorage("userData", {
        ...userData,
        ["primary_doctor"]: selectedPrimaryDoctor._id,
      });
      setCurrentPrimaryDoctor(selectedPrimaryDoctor);
      setShowChangePrimaryConsultant(false);
      setSelectedPrimaryDoctor({ first_name: "", last_name: "", _id: "" });
    } catch (error) {
      console.log(error);
      swal({ icon: "error", text: "Could not change primary doctor !!" });
    }
    changeLoader("loader1", false);
  };

  /**
   * FUnction to get the list of doctors
   */
  const getDoctorsList = async () => {
    try {
      const doctorList = await listDoctors();
      setDoctorList(doctorList?.data?.data);
    } catch {}
  };

  const userData = useSessionStorage("userData");

  const handleEditProfile = async () => {
    try {
      const res = await editProfile(editUserForm, "PATIENT", userData.token);
      if (res?.data?.error === false) {
        sessionStorage.setItem("userData", JSON.stringify(editUserForm));
        swal({
          text:
            language === "Hindi"
              ? "प्रोफ़ाइल सफलतापूर्वक अपडेट की गई!"
              : "Profile Successfully Updated!",
          icon: "success",
          timer: 1500,
        });
        setShowEditProfile(false);
        setCurrentUser({ ...editUserForm });
      } else {
        swal({ text: res?.data?.message, icon: "error", timer: 1500 });
      }
    } catch (error) {
      if (error?.response?.data?.message?.code) {
        // if there is a mongo db error
        const duplicateFieldObject = Object.keys(
          error?.response?.data?.message?.keyPattern
        )[0]?.split("_");

        let duplicateField = "";
        duplicateFieldObject.map((item) => (duplicateField += `${item} `));

        swal({
          text: `${duplicateField}already exists.`,
          icon: "error",
          timer: 1500,
        });
      } else {
        swal({
          text: error?.response?.data?.message,
          icon: "error",
          timer: 1500,
        });
      }
    }
  };

  const handleChangePassword = async () => {
    try {
      if (!oldPassword || !newPassword || !confirmNewPassword) {
        swal({
          text:
            language === "Hindi"
              ? "सभी फ़ील्ड आवश्यक हैं!"
              : "All fields are required!",
          icon: "error",
          timer: 1500,
        });
      } else if (newPassword !== confirmNewPassword)
        swal({
          text:
            language === "Hindi"
              ? "पासवर्ड मेल नहीं खाते!"
              : "Passwords don't match!",
          icon: "error",
          timer: 1500,
        });
      else {
        const res = await changePassword(
          userData.email,
          oldPassword,
          newPassword,
          confirmNewPassword,
          userData.token
        );
        if (res?.data?.error) {
          throw res?.data;
        }
        if (res?.data?.error === false) {
          swal({
            text:
              language === "Hindi"
                ? "नया पासवर्ड सफलतापूर्वक बना दिया गया है!"
                : "New Password has successfully been created!",
            icon: "success",
            timer: 1500,
          });
        } else {
          swal({ text: res?.data?.message, icon: "error", timer: 1500 });
        }
        setIsChangePassword(false);
        setOldPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
      }
    } catch (error) {
      swal({
        text:
          language === "Hindi"
            ? "पुराना पासवर्ड सही नहीं हैं।"
            : "Old Password is not correct.",
        icon: "error",
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    if (userData && doctorList.length !== 0) {
      let temp_item = doctorList?.find(
        (item) => item._id === userData?.primary_doctor
      );
      setCurrentPrimaryDoctor({ ...temp_item });
      setCurrentUser({ ...userData });
      setEditUserForm({ ...userData });
    }
  }, [userData, doctorList]);

  useEffect(() => {
    getDoctorsList();
  }, []);

  /**
   * Function that holds the Profile UI
   */
  const Profile = () => (
    <Box
      sx={{
        width:
          // "100%",
          {
            xs: "45vh",
            sm: "50vh",
          },
      }}
      role="presentation"
      className="patient-details"
    >
      <IconButton
        color="disabled"
        onClick={toggleDrawer("right", false)}
        style={{ float: "right", marginTop: "10px", marginRight: "10px" }}
      >
        <CancelOutlined />
      </IconButton>
      <div
        className="text"
        style={{ textAlign: "left", marginTop: "15px", marginLeft: "15px" }}
      >
        {isChangePassword ? (
          <b>{language === "Hindi" ? "पासवर्ड बदलें" : "Change Password"}</b>
        ) : (
          <b>{language === "Hindi" ? "प्रोफ़ाइल" : "Profile"}</b>
        )}
      </div>
      {/* <div
          style={{
            border: "2px solid #30f2a9",
            height: "100px",
            width: "100px",
            borderRadius: "50px",
            marginLeft: "37%",
          }}
        ></div> */}

      {/* If edit profile button is clicked, text fields will show up besides labels 
        or the saved information will be displayed */}
      {!isChangePassword ? (
        <div>
          <Row style={{ marginTop: "50px", marginLeft: "10px" }}>
            <Col xs={4}>
              <b>{language === "Hindi" ? "पहला नाम:" : "First Name:"}</b>
            </Col>
            <Col xs={8}>
              {/* {currentUser?.first_name} */}
              {showEditProfile ? (
                <TextField
                  style={{ width: "80%", height: "30px" }}
                  InputProps={{ disableUnderline: true }}
                  className={"text_field_2"}
                  placeholder={""}
                  variant="standard"
                  value={editUserForm.first_name}
                  onChange={(e) => {
                    setEditUserForm({
                      ...editUserForm,
                      ["first_name"]: e.target.value,
                    });
                  }}
                />
              ) : (
                <>{currentUser?.first_name}</>
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: "25px", marginLeft: "10px" }}>
            <Col xs={4}>
              <b>{language === "Hindi" ? "अंतिम नाम:" : "Last Name:"}</b>
            </Col>
            <Col xs={8}>
              {showEditProfile ? (
                <TextField
                  style={{ width: "80%", height: "30px" }}
                  InputProps={{ disableUnderline: true }}
                  className={"text_field_2"}
                  placeholder={""}
                  variant="standard"
                  value={editUserForm.last_name}
                  onChange={(e) => {
                    setEditUserForm({
                      ...editUserForm,
                      ["last_name"]: e.target.value,
                    });
                  }}
                />
              ) : (
                <>{currentUser?.last_name}</>
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: "25px", marginLeft: "10px" }}>
            <Col xs={4}>
              <b>{language === "Hindi" ? "रोगी आईडी:" : "Patient ID:"}</b>
            </Col>
            <Col xs={8}>{currentUser?.uniqueID}</Col>
          </Row>
          <Row style={{ marginTop: "25px", marginLeft: "10px" }}>
            <Col xs={4}>
              <b>{language === "Hindi" ? "ABHA नंबर:" : "ABHA No.:"}</b>
            </Col>
            <Col xs={8}>
              {showEditProfile ? (
                <TextField
                  style={{ width: "80%", height: "30px" }}
                  InputProps={{ disableUnderline: true }}
                  className={"text_field_2"}
                  placeholder={""}
                  variant="standard"
                  value={editUserForm?.abha}
                  onChange={(e) => {
                    setEditUserForm({
                      ...editUserForm,
                      ["abha"]: e.target.value,
                    });
                  }}
                  // {...(errors.abha && {
                  //   error: true,
                  //   helperText: errors.abha,
                  // })}
                />
              ) : (
                <>{currentUser?.abha ? currentUser?.abha : ""}</>
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: "25px", marginLeft: "10px" }}>
            <Col xs={4}>
              <b>{language === "Hindi" ? "जन्म तिथि:" : "DOB:"}</b>
            </Col>
            <Col xs={8}>
              {showEditProfile ? (
                <TextField
                  style={{ width: "80%", height: "30px" }}
                  type="date"
                  InputProps={{ disableUnderline: true }}
                  className={"text_field_2"}
                  placeholder={""}
                  variant="standard"
                  value={editUserForm?.date_of_birth?.slice(0, 10)}
                  onChange={(e) => {
                    setEditUserForm({
                      ...editUserForm,
                      ["date_of_birth"]: e.target.value,
                    });
                  }}
                />
              ) : (
                <>
                  {currentUser?.date_of_birth
                    ? parse_date(currentUser?.date_of_birth)
                    : ""}
                </>
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: "25px", marginLeft: "10px" }}>
            <Col xs={4}>
              <b>{language === "Hindi" ? "लिंग:" : "Gender:"}</b>
            </Col>
            <Col xs={8}>
              {showEditProfile ? (
                <TextField
                  select
                  style={{ width: "80%", height: "30px" }}
                  InputProps={{ disableUnderline: true }}
                  className={"text_field_2"}
                  placeholder={""}
                  variant="standard"
                  value={editUserForm.gender}
                  onChange={(e) => {
                    setEditUserForm({
                      ...editUserForm,
                      ["gender"]: e.target.value,
                    });
                  }}
                >
                  {["Male", "Female"].map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <>{currentUser?.gender}</>
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: "25px", marginLeft: "10px" }}>
            <Col xs={4}>
              <b>
                {language === "Hindi"
                  ? "प्राथमिक डॉक्टर:"
                  : "Primary Consultant:"}
              </b>
            </Col>
            <Col xs={8}>
              {currentPrimaryDoctor.first_name +
                " " +
                currentPrimaryDoctor.last_name}
            </Col>
          </Row>
          <Row style={{ marginTop: "25px", marginLeft: "10px" }}>
            <Col xs={4}>
              <b>{language === "Hindi" ? "ईमेल आईडी:" : "Email ID:"}</b>
            </Col>
            <Col xs={8}>
              {/* {showEditProfile ? (
            <TextField
              style={{ width: "80%", height: "30px" }}
              InputProps={{ disableUnderline: true }}
              className={"text_field_2"}
              placeholder={""}
              variant="standard"
              value={editUserForm.email}
              onChange={(e) => {
                setEditUserForm({ ...editUserForm, ["email"]: e.target.value });
              }}
            />
          ) : ( */}
              <>{currentUser?.email}</>
              {/* )} */}
            </Col>
          </Row>
          <Row style={{ marginTop: "25px", marginLeft: "10px" }}>
            <Col xs={4}>
              <b>{language === "Hindi" ? "फ़ोन नंबर" : "Phone Number:"}</b>
            </Col>
            <Col xs={8}>
              {showEditProfile ? (
                <TextField
                  style={{ width: "80%", height: "30px" }}
                  InputProps={{ disableUnderline: true }}
                  className={"text_field_2"}
                  placeholder={""}
                  variant="standard"
                  value={editUserForm.phone_number}
                  onChange={(e) => {
                    setEditUserForm({
                      ...editUserForm,
                      ["phone_number"]: e.target.value,
                    });
                  }}
                />
              ) : (
                <>{currentUser?.phone_number}</>
              )}
            </Col>
          </Row>

          {!showEditProfile ? (
            <div>
              <Button
                variant="contained"
                size="small"
                style={{
                  width: "50%",
                  textTransform: "none",
                  marginTop: "80px",
                  marginLeft: "30%",
                  marginBottom: "5%",
                  backgroundColor: "#6E326E",
                }}
                color="secondary"
                onClick={() => {
                  setShowEditProfile(true);
                }}
              >
                <div className="text1">
                  {language === "Hindi"
                    ? "प्रोफ़ाइल संपादित करें"
                    : "Edit Profile"}
                </div>
              </Button>
              <Button
                variant="contained"
                size="small"
                style={{
                  width: "50%",
                  textTransform: "none",
                  // marginTop: "80px",
                  marginLeft: "30%",
                  marginBottom: "5%",
                  backgroundColor: "#ffffff",
                  border: "1px solid #6E326E",
                  color: "#6E326E",
                }}
                // color="secondary"
                onClick={() => setIsChangePassword(true)}
              >
                <div className="text1" style={{ color: "#000000" }}>
                  {language === "Hindi" ? "पासवर्ड बदलें" : "Change Password"}
                </div>
              </Button>
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                size="small"
                style={{
                  width: "30%",
                  display: "inline-block",
                  textTransform: "none",
                  marginTop: "80px",
                  marginLeft: "2%",
                  marginRight: "2%",
                  marginBottom: "5%",
                  background: "#6E326E",
                }}
                onClick={() => {
                  handleEditProfile();
                }}
              >
                <div className="text1">
                  {language === "Hindi" ? "लागू करें" : "Apply"}
                </div>
              </Button>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  width: "30%",
                  display: "inline-block",
                  textTransform: "none",
                  borderColor: "#6E326E",
                  marginTop: "80px",
                  marginLeft: "2%",
                  marginRight: "2%",
                  marginBottom: "5%",
                }}
                onClick={() => {
                  setShowEditProfile(false);
                  setEditUserForm({ ...currentUser });
                }}
              >
                <div className="text">
                  {language === "Hindi" ? "रद्द करें" : "Cancel"}
                </div>
              </Button>
            </div>
          )}
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              mt: "20px",
            }}
          >
            <Typography>
              {" "}
              {language === "Hindi" ? "पुराना पासवर्ड*" : "Old Password*"}
            </Typography>
            <Input
              type="password"
              disableUnderline={true}
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "10px",
                padding: "2px 10px",
                width: "300px",
              }}
              placeholder={
                language === "Hindi"
                  ? "पुराना पासवर्ड दर्ज करें"
                  : "Enter Old Password"
              }
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </Box>
          <Box sx={{ mt: "20px" }}>
            <Typography>
              {language === "Hindi" ? "नया पासवर्ड*" : "New Password*"}
            </Typography>
            <Input
              type="password"
              disableUnderline={true}
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "10px",
                padding: "2px 10px",
                width: "300px",
              }}
              placeholder={
                language === "Hindi"
                  ? "नया पासवर्ड दर्ज करें"
                  : "Enter New Password"
              }
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              mt: "20px",
            }}
          >
            <Typography>
              {language === "Hindi"
                ? "पासवर्ड की पुष्टि करें*"
                : "Confirm Password*"}
            </Typography>
            <Input
              type="password"
              disableUnderline={true}
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "10px",
                padding: "2px 10px",
                width: "300px",
              }}
              placeholder={
                language === "Hindi"
                  ? "नए पासवर्ड की पुष्टि करें"
                  : "Confirm New Password"
              }
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mt: "40px",
            }}
          >
            <Button
              variant="contained"
              sx={{ mx: "20px", ...muiStyles.primaryContainedButton }}
              onClick={handleChangePassword}
            >
              {language === "Hindi" ? "पासवर्ड बदलें" : "Change Password"}
            </Button>
            <Button
              variant="contained"
              sx={{ mx: "20px", ...muiStyles.secondaryContainedButton }}
              onClick={() => setIsChangePassword(false)}
            >
              {language === "Hindi" ? "रद्द करें" : "Cancel"}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );

  /**
   * Function that holds the Notification UI
   */
  const Notifications = () => (
    <Box
      sx={{
        width:
          // "100%",
          {
            xs: "45vh",
            sm: "50vh",
          },
      }}
      role="presentation"
    >
      <IconButton
        color="disabled"
        onClick={toggleDrawer("right", false)}
        style={{ float: "right", marginTop: "10px", marginRight: "10px" }}
      >
        <CancelOutlined />
      </IconButton>
      <div
        className="text"
        style={{ textAlign: "left", marginTop: "15px", marginLeft: "15px" }}
      >
        <b>Notifications</b>
      </div>
      <div
        style={{
          height: "100px",
          width: "90%",
          borderRadius: "10px",
          background: "#F5F5F5 0% 0% no-repeat padding-box",
          marginTop: "25px",
          marginLeft: "5%",
          padding: "10px",
        }}
      >
        <div
          className="text"
          style={{ float: "right", fontSize: "12px", color: "gray" }}
        >
          10:30 am
        </div>
        <div className="text" style={{ textAlign: "left", fontSize: "14px" }}>
          Dear ABC
        </div>
        <div className="text" style={{ textAlign: "left", fontSize: "14px" }}>
          Dr. Harshal has replied to your query
        </div>
        <Button
          variant="contained"
          size="small"
          style={{ width: "25%", textTransform: "none", marginTop: "10px" }}
        >
          <div className="text">View</div>
        </Button>
      </div>
      <Button
        variant="outlined"
        size="small"
        style={{
          width: "30%",
          textTransform: "none",
          top: "90%",
          position: "absolute",
          marginLeft: "35%",
        }}
      >
        <div className="text">Delete All</div>
      </Button>
    </Box>
  );

  /**
   * Function that holds the Settings UI
   */
  const Settings = () => (
    <Box
      sx={{
        width:
          // "100%",
          {
            xs: "45vh",
            sm: "50vh",
          },
      }}
      role="presentation"
    >
      <IconButton
        color="disabled"
        onClick={toggleDrawer("right", false)}
        style={{ float: "right", marginTop: "10px", marginRight: "10px" }}
      >
        <CancelOutlined />
      </IconButton>
      <div
        className="text"
        style={{ textAlign: "left", marginTop: "15px", marginLeft: "15px" }}
      >
        <b>{language === "Hindi" ? "सेटिंग्स" : "Settings"}</b>
      </div>

      {/* To enter the changed Password */}
      {isChangePassword && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              mt: "20px",
            }}
          >
            <Typography>
              {" "}
              {language === "Hindi" ? "पुराना पासवर्ड*" : "Old Password*"}
            </Typography>
            <Input
              type="password"
              disableUnderline={true}
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "20px",
                padding: "2px 10px",
                width: "300px",
              }}
              placeholder={
                language === "Hindi"
                  ? "पुराना पासवर्ड दर्ज करें"
                  : "Enter Old Password"
              }
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </Box>
          <Box sx={{ mt: "20px" }}>
            <Typography>
              {language === "Hindi" ? "नया पासवर्ड*" : "New Password*"}
            </Typography>
            <Input
              type="password"
              disableUnderline={true}
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "20px",
                padding: "2px 10px",
                width: "300px",
              }}
              placeholder={
                language === "Hindi"
                  ? "नया पासवर्ड दर्ज करें"
                  : "Enter New Password"
              }
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              mt: "20px",
            }}
          >
            <Typography>
              {language === "Hindi"
                ? "पासवर्ड की पुष्टि करें*"
                : "Confirm Password*"}
            </Typography>
            <Input
              type="password"
              disableUnderline={true}
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "20px",
                padding: "2px 10px",
                width: "300px",
              }}
              placeholder={
                language === "Hindi"
                  ? "नए पासवर्ड की पुष्टि करें"
                  : "Confirm New Password"
              }
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mt: "40px",
            }}
          >
            <Button
              variant="contained"
              sx={{ mx: "20px", ...muiStyles.primaryContainedButton }}
              onClick={handleChangePassword}
            >
              {language === "Hindi" ? "पासवर्ड बदलें" : "Change Password"}
            </Button>
            <Button
              variant="contained"
              sx={{ mx: "20px", ...muiStyles.secondaryContainedButton }}
              onClick={() => setIsChangePassword(false)}
            >
              {language === "Hindi" ? "रद्द करें" : "Cancel"}
            </Button>
          </Box>
        </Box>
      )}

      {/* UI to choose the primary doctor */}
      {showChangePrimaryConsultant && (
        <div
          style={{
            height: "100%",
            width: "90%",
            borderRadius: "10px",
            background: "#F5F5F5 0% 0% no-repeat padding-box",
            marginTop: "15px",
            marginLeft: "5%",
            padding: "10px",
            cursor: "pointer",
          }}
        >
          <h5 style={{ marginTop: "30px" }}>
            Your Primary Doctor :{" "}
            {currentPrimaryDoctor.first_name +
              " " +
              currentPrimaryDoctor.last_name}
          </h5>
          <FormControlLabel
            control={<Checkbox checked={true} disabled />}
            label={"Revoke Access from earlier doctor"}
          />
          <h5 style={{ marginTop: "20px" }}>Chose your primary doctor</h5>

          <TextField
            id="outlined"
            select
            helperText="Select from these values"
            variant="standard"
            style={{ width: "80%" }}
          >
            {doctorList
              ?.filter((i) => i._id !== currentPrimaryDoctor?._id)
              .map((item) => (
                <MenuItem
                  key={item?._id}
                  value={item}
                  onClick={() => {
                    setSelectedPrimaryDoctor(item);
                  }}
                >
                  {item.first_name + " " + item.last_name}
                </MenuItem>
              ))}
          </TextField>
          <div style={{ marginTop: "100px" }}>
            <span>
              <Button
                variant="outlined"
                size="small"
                style={{
                  width: "30%",
                  textTransform: "none",
                  marginLeft: "17.5%",
                  marginTop: "15px",
                  background: "#6E326E",
                  border: "1px solid #6E326E",
                }}
              >
                <div
                  className="text1"
                  onClick={() => {
                    handleSubmitChangePrimaryDoctor();
                  }}
                >
                  Submit
                  {loader.loader1 && (
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: "5px", marginLeft: "5px" }}
                    />
                  )}
                </div>
              </Button>
            </span>
            <span>
              <Button
                variant="contained"
                size="small"
                style={{
                  width: "30%",
                  textTransform: "none",
                  marginLeft: "5%",
                  marginTop: "15px",
                  background: "white",
                }}
                onClick={() => setShowChangePrimaryConsultant(false)}
              >
                <div className="text">Cancel</div>
              </Button>
            </span>
          </div>
        </div>
      )}

      {/* By default this list of actions will be shown */}
      {!isChangePassword && !showChangePrimaryConsultant && (
        <>
          <div
            style={{
              height: "40px",
              width: "90%",
              borderRadius: "10px",
              background: "#F5F5F5 0% 0% no-repeat padding-box",
              marginTop: "25px",
              marginLeft: "5%",
              padding: "10px",
              cursor: "pointer",
            }}
          >
            <div
              className="text"
              style={{ textAlign: "left", fontSize: "14px" }}
              onClick={() => setIsChangePassword(true)}
            >
              {language === "Hindi" ? "पासवर्ड बदलें" : "Change Password"}
            </div>
          </div>

          {/* <div
              style={{
                height: "40px",
                width: "90%",
                borderRadius: "10px",
                background: "#F5F5F5 0% 0% no-repeat padding-box",
                marginTop: "15px",
                marginLeft: "5%",
                padding: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                setShowChangePrimaryConsultant(true);
                console.log("abcd");
              }}
            >
              <div
                className="text"
                style={{ textAlign: "left", fontSize: "14px" }}
              >
                Change Primary Consultant
              </div>
            </div> */}

          {/* <div
              style={{
                height: "40px",
                width: "90%",
                borderRadius: "10px",
                background: "#F5F5F5 0% 0% no-repeat padding-box",
                marginTop: "15px",
                marginLeft: "5%",
                padding: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                setShowPersonalisedNotification(true);
              }}
            >
              <div
                className="text"
                style={{ textAlign: "left", fontSize: "14px" }}
              >
                Personalise Notifications
              </div>
            </div> */}

          {showPersonalisedNotification && (
            <div
              style={{
                height: "100%",
                width: "90%",
                borderRadius: "10px",
                background: "#F5F5F5 0% 0% no-repeat padding-box",
                marginTop: "15px",
                marginLeft: "5%",
                padding: "10px",
                cursor: "pointer",
              }}
            >
              <h5 style={{ marginTop: "20px", marginBottom: "20px" }}>
                Personalize Notifications
              </h5>

              <Row style={{ marginBottom: "20px", fontWeight: "bold" }}>
                <Col xs={7}>
                  <h6>Notifications</h6>
                </Col>
                <Col md={2} xs={2}>
                  <h6>Email</h6>
                </Col>
                <Col md={2} xs={2}>
                  <h6>SMS</h6>
                </Col>
              </Row>

              {personaliseNotificationItems.map((item, index) => (
                <Row>
                  <Col xs={7}>
                    <h6>{item}</h6>
                  </Col>
                  <Col md={2} xs={2}>
                    <FormControlLabel control={<Checkbox />} />
                  </Col>
                  <Col md={2} xs={2}>
                    <FormControlLabel control={<Checkbox />} />
                  </Col>
                </Row>
              ))}

              <div style={{ marginTop: "100px" }}>
                <span>
                  <Button
                    variant="outlined"
                    size="small"
                    style={{
                      width: "30%",
                      textTransform: "none",
                      marginLeft: "17.5%",
                      marginTop: "15px",
                      background: "#30F2A9",
                    }}
                  >
                    <div className="text">Save</div>
                  </Button>
                </span>
                <span>
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      width: "30%",
                      textTransform: "none",
                      marginLeft: "5%",
                      marginTop: "15px",
                      background: "white",
                    }}
                    onClick={() => setShowPersonalisedNotification(false)}
                  >
                    <div className="text">Cancel</div>
                  </Button>
                </span>
              </div>
            </div>
          )}

          {/* <div
              style={{
                height: "40px",
                width: "90%",
                borderRadius: "10px",
                background: "#F5F5F5 0% 0% no-repeat padding-box",
                marginTop: "15px",
                marginLeft: "5%",
                padding: "10px",
                cursor: "pointer",
              }}
              onClick={() => setDeleteAccount(true)}
            >
              <div
                className="text"
                style={{ textAlign: "left", fontSize: "14px" }}
              >
                Delete Account
              </div>
            </div> */}
          {deleteAccount === true ? (
            <div
              style={{
                // height: "100px",
                width: "90%",
                borderRadius: "10px",
                background: "#F5F5F5 0% 0% no-repeat padding-box",
                marginTop: "15px",
                marginLeft: "5%",
                padding: "10px",
                cursor: "pointer",
              }}
            >
              <div className="text" style={{ fontSize: "14px" }}>
                <div>Are you sure you want to delete your account?</div>
                <div>All your data will be deleted</div>
              </div>
              <span>
                <Button
                  variant="outlined"
                  size="small"
                  style={{
                    width: "30%",
                    textTransform: "none",
                    marginLeft: "17.5%",
                    marginTop: "15px",
                    border: "1px solid #6E326E",
                  }}
                >
                  <div className="text" onClick={() => accountDelete()}>
                    Delete
                  </div>
                </Button>
              </span>
              <span>
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    width: "30%",
                    textTransform: "none",
                    marginLeft: "5%",
                    marginTop: "15px",
                    background: "#6E326E",
                  }}
                  onClick={() => setDeleteAccount(false)}
                >
                  <div className="text1">Cancel</div>
                </Button>
              </span>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#6e326e",
        boxShadow: "0px 1px 20px #78787840",
        height: "9vh",
        pr: "20px",
      }}
    >
      {/* Logo */}
      <div className="logo-container">
        {/* OncoPHR logo */}
        <div>
          {/* <Link to="/patient/dashboard/0" onClick={handleLogoClick}> */}
          <img
            src={oncoLogo}
            alt="onco-phr-logo"
            className="nav-logo"
            onClick={handleLogoClick}
            href="/patient/dashboard/0"
          />
          {/* </Link> */}
        </div>
        {/* Middle Section of the Header */}

        <div className="middle-header-section">
          {/* To navigate using the tabs */}
          {props.entity === "PATIENT" && (
            <div className="tabs-bar">
              <Navbar collapseOnSelect expand="sm">
                <Box
                  sx={{
                    borderRadius: "10px",
                    // backgroundColor: "#6e326e",
                  }}
                >
                  <Tabs
                    value={tabsValue}
                    onChange={handleTabChange}
                    textColor="white"
                    TabIndicatorProps={{
                      sx: {
                        background: "#FFFFFF",
                        borderColor: "#FFFFFF",
                        borderRadius: "10px",
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    <Tab
                      value={"0"}
                      label={
                        language === "Hindi"
                          ? "दस्तावेज़ अपलोड"
                          : "Upload Reports"
                      }
                      sx={{
                        color: "#FFFFFF",
                        textTransform: "capitalize",
                        fontSize: "15px",
                      }}
                    />
                    <Tab
                      value={"1"}
                      label={
                        language === "Hindi"
                          ? "दस्तावेज़ देखें"
                          : "Access Reports"
                      }
                      sx={{
                        color: "#FFFFFF",
                        textTransform: "capitalize",
                        fontSize: "15px",
                      }}
                    />
                    <Tab
                      value={"2"}
                      label={
                        language === "Hindi" ? "राय देखें" : "View Opinion"
                      }
                      sx={{
                        color: "#FFFFFF",
                        textTransform: "capitalize",
                        fontSize: "15px",
                      }}
                    />
                  </Tabs>
                </Box>
              </Navbar>
            </div>
          )}
        </div>
      </div>

      {/* Right-most 4 icons */}
      <div className="navbar-action-buttons">
        {/* Language option */}
        <FormControl
          variant="standard"
          sx={{
            border: "none",
            padding: "6px",
          }}
        >
          <Select
            value={language}
            onChange={handleLanguageChange}
            className="select-lang"
            sx={{
              minWidth: "10px",
              fontFamily: "Open Sans",
              fontSize: "17px",
              color: "#FFFFFF",
            }}
          >
            <MenuItem
              value={"Hindi"}
              sx={{
                fontFamily: "Open Sans",
                fontSize: "17px",
              }}
            >
              हिंदी
            </MenuItem>
            <MenuItem
              value={"English"}
              sx={{
                fontFamily: "Open Sans",
                fontSize: "17px",
              }}
            >
              English
            </MenuItem>
          </Select>
        </FormControl>
        {/* Account Button */}
        <Button
          variant="outlined"
          sx={{ mx: "10px", ...muiStyles.outlinedIcon }}
          onClick={toggleDrawer("right", "profile", true)}
        >
          <PersonOutlineOutlined />
        </Button>

        {/* Notification Button */}
        {/* <Button
            variant="outlined"
            sx={{ mx: "10px", ...muiStyles.outlinedIcon }}
            onClick={toggleDrawer("right", "notifications", true)}
          >
            <NotificationsNoneOutlined />
          </Button> */}

        {/* Settings Button */}
        {/* <Button
          variant="outlined"
          sx={{ mx: "10px", ...muiStyles.outlinedIcon }}
          onClick={toggleDrawer("right", "settings", true)}
        >
          <SettingsOutlined />
        </Button> */}

        {/* Logout Button */}
        <Button
          variant="outlined"
          sx={{
            ...muiStyles.outlinedIcon,
            textTransform: "capitalize",
            px: "20px",
          }}
          onClick={(e) => onLogout(e)}
        >
          <b>{language === "Hindi" ? "लॉगआउट" : "Logout"}</b>
        </Button>
      </div>
      {!isSideNavOpen && (
        <div className="navbar-menu">
          <Image
            className="hamburger"
            src={navbarMenu}
            onClick={() => setIsSideNavOpen(true)}
          />
        </div>
      )}
      {isSideNavOpen && (
        <nav className="slider-nav">
          <Image
            className="hamburger"
            src={navbarMenu}
            onClick={() => setIsSideNavOpen(false)}
          />
          <div className="navbar-menu-action-buttons">
            {/* Language option */}
            <FormControl
              variant="standard"
              sx={{
                // border: "none",
                padding: "6px",
                "& fieldset": {
                  textColor: "secondary",
                },
              }}
            >
              <Select
                value={language}
                onChange={handleLanguageChange}
                className="select-lang"
                sx={{
                  minWidth: "10px",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  color: "#9c27b0",
                }}
              >
                <MenuItem
                  value={"Hindi"}
                  sx={{
                    fontFamily: "Open Sans",
                    fontSize: "17px",
                  }}
                >
                  हिंदी
                </MenuItem>
                <MenuItem
                  value={"English"}
                  sx={{
                    fontFamily: "Open Sans",
                    fontSize: "17px",
                  }}
                >
                  English
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          {props.entity === "PATIENT" && (
            <div className="slider-nav-tabs">
              <Tabs
                orientation="vertical"
                value={tabsValue}
                onChange={handleTabChange}
                textColor="secondary"
                TabIndicatorProps={{
                  sx: {
                    color: "#ff0000",
                    backgroundColor: "#6E326E",
                    borderColor: "divider",
                    borderRight: 1,
                  },
                }}
              >
                <Tab
                  value={"0"}
                  label={
                    language === "Hindi" ? "दस्तावेज़ अपलोड" : "Upload Reports"
                  }
                  sx={{ color: "#000000", textTransform: "capitalize" }}
                  onClick={() => setIsSideNavOpen(false)}
                />
                <Tab
                  value={"1"}
                  label={
                    language === "Hindi" ? "दस्तावेज़ देखें" : "Access Reports"
                  }
                  sx={{ color: "#000000", textTransform: "capitalize" }}
                  onClick={() => setIsSideNavOpen(false)}
                />
                <Tab
                  value={"2"}
                  label={language === "Hindi" ? "राय देखें" : "View Opinion"}
                  sx={{ color: "#000000", textTransform: "capitalize" }}
                  onClick={() => setIsSideNavOpen(false)}
                />
              </Tabs>
            </div>
          )}
          <div className="navbar-menu-action-buttons">
            {/* Account Button */}
            <Button
              variant="text"
              onClick={toggleDrawer("right", "profile", true)}
              color="secondary"
            >
              <PersonOutlineOutlined style={{ marginRight: "10px" }} />
              {language === "Hindi" ? "प्रोफ़ाइल" : "Profile"}
            </Button>

            {/* Notification Button */}
            {/* <Button
                variant="text"
                onClick={toggleDrawer("right", "notifications", true)}
              >
                <NotificationsNoneOutlined /> Notifications
              </Button> */}

            {/* Settings Button */}
            {/* <Button
              variant="text"
              onClick={toggleDrawer("right", "settings", true)}
              color="secondary"
            >
              <SettingsOutlined style={{ marginRight: "10px" }} />
              {language === "Hindi" ? "सेटिंग्स" : "Settings"}
            </Button> */}

            {/* Logout Button */}
            <Button
              variant="text"
              onClick={(e) => {
                onLogout(e);
                setIsSideNavOpen(false);
              }}
              color="secondary"
            >
              <LogoutIcon style={{ marginRight: "10px" }} />
              {language === "Hindi" ? "लॉगआउट" : "Logout"}
            </Button>
          </div>
        </nav>
      )}
      <Drawer
        anchor="right"
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        {settings === true ? (
          Settings()
        ) : notifications ? (
          Notifications()
        ) : profile ? (
          Profile()
        ) : (
          <></>
        )}
      </Drawer>
    </Box>
  );
};

export default PatientNavbar;
