import { Box, Typography, Button, Input } from "@mui/material";
import { useState } from "react";
import { muiStyles } from "../Styles/muiStyles";
import { useNavigate } from "react-router-dom";
import { forgotPassword, getSessionStorage } from "../Services/patients";
import swal from "sweetalert";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const language = getSessionStorage("language");

  const sendForgotPasswordOtp = async () => {
    try {
      const res = await forgotPassword(email);
      if (res?.data?.error === false) {
        swal({ text: res?.data?.message, icon: "success", timer: 1500 });
        localStorage.setItem("email", JSON.stringify(email));
        navigate("/patient/reset-password");
      } else swal({ text: res?.data?.message, icon: "error", timer: 1500 });
    } catch (error) {
      swal({
        text: error?.response?.data?.message,
        icon: "error",
        timer: 1500,
      });
    }
  };

  //regular expression to check the email format
  //   const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  return (
    <div className="forgot-password-panel">
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
          }}
        >
          {language === "Hindi"
            ? "कृपया अपना दर्ज कराई ईमेल दर्ज करें"
            : "Please Enter Your Registered Email"}
        </Typography>

        {/* Input to enter registered email */}
        <div className="forgot-password-container">
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
              alignItems: {
                xs: "flex-start",
                sm: "center",
              },
              mb: "10px",
              mx: {
                xs: "5px",
                sm: "0",
              },
            }}
          >
            <Typography
              variant="body1"
              sx={{
                mr: "27px",
                mb: {
                  xs: "10px",
                  sm: "0",
                },
              }}
            >
              {language === "Hindi" ? "ईमेल*" : "Email*"}
            </Typography>
            <Input
              disableUnderline={true}
              value={email}
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "20px",
                padding: {
                  xs: "2px 5px",
                  sm: "5px 10px",
                },
                width: {
                  xs: "250px",
                  sm: "300px",
                },
              }}
              placeholder={
                language === "Hindi" ? "ईमेल दर्ज करें" : "Enter Email"
              }
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Box>
          <Button
            disabled={!email}
            variant="contained"
            sx={{ mt: "10px", ...muiStyles.primaryContainedButton }}
            onClick={() => sendForgotPasswordOtp()}
          >
            {language === "Hindi" ? "OTP प्राप्त करें" : "Get OTP"}
          </Button>
        </div>
        {/* {regex.test(email) && (
          <Typography>Please enter a valid Email Id</Typography>
        )} */}
      </Box>
    </div>
  );
};

export default ForgotPassword;
