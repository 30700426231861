import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "./Components/Dashboard";
import ForgotPassword from "./Components/ForgotPassword";
import Login from "./Components/Login";
import Register from "./Components/Register";
import Verify from "./Components/Verify";
import CreateNewPassword from "./Components/CreateNewPassword";
import Consent from "./Components/Consent";
import ViewReport from "./Components/ViewReport";
import ViewOpinion from "./Components/ViewOpinion";
import PreLogin from "./Components/layouts/PreLogin";
import TermsAndConditions from "./Components/TermsAndConditions";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import Dashboard2 from "./Components/Dashboard/index";

let userData = sessionStorage.getItem("userData");

function App() {
  const loadingStyle = {
    textAlign: "center",
    marginTop: "45vh",
    font: "normal normal normal 4vh Poppins",
  };
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<PreLogin />}>
          <Route index element={<Login />} />
          <Route path="patient/login" element={<Login />} />
          <Route path="/patient/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/patient/reset-password"
            element={<CreateNewPassword />}
          />
          <Route path="/patient/verifyEmail/:id" element={<Verify />} />
          {/* <Route path="/patient/register" element={<Register />} /> */}
          <Route
            path="/patient/consent"
            element={<Consent />}
            // render={() => (!userData ? <Navigate to="/" replace /> : null)}
          ></Route>

          <Route
            path="/patient/terms-and-conditions"
            element={<TermsAndConditions />}
            // render={() => (!userData ? <Navigate to="/" replace /> : null)}
          ></Route>
          <Route
            path="/patient/privacy-policy"
            element={<PrivacyPolicy />}
            // render={() => (!userData ? <Navigate to="/" replace /> : null)}
          ></Route>

          <Route
            path="/patient/dashboard/:screen"
            // element={<Dashboard entity={"PATIENT"} />}
            element={<Dashboard2 entity={"PATIENT"} />}
          />
          <Route
            exact
            path="/patient/viewReport/:index/:patientID/:RawReports/:reportKey"
            element={<ViewReport entity={"PATIENT"} />}
          />

          {/* <Route
            exact
            path="/patient/viewFlaggedReport/:id"
            element={<ViewFlaggedReport entity={"PATIENT"} />}
          /> */}

          <Route
            exact
            path="/patient/viewOpinion/:queryID"
            element={<ViewOpinion entity={"PATIENT"} />}
          />
          {/* view opinion V2 */}
          <Route
            exact
            path="/patient/dashboard/opinions/view/:queryID"
            element={<Dashboard2 entity={"PATIENT"} viewOpinion={true} />}
          />

          <Route
            path="*"
            element={<div style={loadingStyle}>Page not found</div>}
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
